import * as React from "react"
import { SVGProps } from "react"

const RadioSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg 
    width={24} 
    height={24}
    fill= "#F37F26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path d="M11 -0.000976562C16.524 -0.000976562 21.002 4.47702 21.002 10.001C21.002 15.524 16.524 20.002 11 20.002C5.47605 20.002 0.998047 15.524 0.998047 10.001C0.998047 4.47702 5.47605 -0.000976562 11 -0.000976562ZM11 1.49902C9.87586 1.48683 8.76041 1.69772 7.71827 2.1195C6.67612 2.54127 5.72798 3.16554 4.92871 3.95619C4.12944 4.74684 3.49492 5.68816 3.06187 6.72566C2.62882 7.76317 2.40584 8.87627 2.40584 10.0005C2.40584 11.1248 2.62882 12.2379 3.06187 13.2754C3.49492 14.3129 4.12944 15.2542 4.92871 16.0449C5.72798 16.8355 6.67612 17.4598 7.71827 17.8816C8.76041 18.3033 9.87586 18.5142 11 18.502C13.2321 18.4678 15.3611 17.5571 16.9274 15.9666C18.4937 14.376 19.3717 12.2333 19.3717 10.001C19.3717 7.76874 18.4937 5.626 16.9274 4.03548C15.3611 2.44496 13.2321 1.53426 11 1.50002V1.49902ZM10.997 3.99902C12.5878 3.99902 14.1134 4.63095 15.2383 5.7558C16.3631 6.88064 16.995 8.40626 16.995 9.99702C16.995 11.5878 16.3631 13.1134 15.2383 14.2382C14.1134 15.3631 12.5878 15.995 10.997 15.995C9.40628 15.995 7.88066 15.3631 6.75582 14.2382C5.63098 13.1134 4.99905 11.5878 4.99905 9.99702C4.99905 8.40626 5.63098 6.88064 6.75582 5.7558C7.88066 4.63095 9.40628 3.99902 10.997 3.99902V3.99902Z"/>
    </svg>
)

export default RadioSelectIcon