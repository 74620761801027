import * as React from "react"
import { SVGProps } from "react"
 
const ForgotChoosePhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.599976 1.39998C0.599976 0.958148 0.958148 0.599976 1.39998 0.599976H3.12227C3.51335 0.599976 3.8471 0.882706 3.91139 1.26846L4.50284 4.81716C4.56058 5.16358 4.38562 5.50715 4.07149 5.66422L2.83298 6.28347C3.72602 8.50263 5.49732 10.2739 7.71648 11.167L8.33573 9.92846C8.4928 9.61433 8.83637 9.43937 9.1828 9.49711L12.7315 10.0886C13.1172 10.1529 13.4 10.4866 13.4 10.8777V12.6C13.4 13.0418 13.0418 13.4 12.6 13.4H11C5.25621 13.4 0.599976 8.74374 0.599976 2.99998V1.39998Z"/>
  </svg>
 
)
 
export default ForgotChoosePhoneIcon