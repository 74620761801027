import PageConstUtil from "../util/page-constants";

const initialState = {
    whichPage: PageConstUtil().FORGOT_CHOOSE_EMAIL,
    authFrom: ""
};

export type AuthUtilState = Readonly<typeof initialState>;

export default (state: AuthUtilState= initialState): AuthUtilState => {
    return state;
}