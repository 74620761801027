import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import PageConstUtil from 'app/shared/util/page-constants';
import React from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import SuccessIllustration from 'app/component/success-illustration';
import FailedIllustration from 'app/component/failed-illustration';
import DarkPurpleButton from 'app/shared/layout/my-button/dark-purple-button';
import { Link } from 'react-router-dom';
import NavBackIcon from 'app/component/nav-back-icon';
import LANG from 'app/shared/util/lang-constant';
import { useFormik } from 'formik';
import './../form.scss';
import { Storage } from 'react-jhipster';
import Axios, { AxiosRequestConfig } from 'axios';
import EndPoint from 'app/shared/util/api-constant';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import MyEncrypt from 'app/shared/util/security-encrypt';
import MySecKey from 'app/shared/util/security-key';
import ForgotChooseEmailIcon from 'app/component/forgot-choose-email-icon';
import ForgotChoosePhoneIcon from 'app/component/forgot-choose-phone-icon';
import GeneralSettingsConstant from 'app/shared/util/general-settings-constant';
import { useTimer } from 'reactjs-countdown-hook';
import OrangeButton from 'app/shared/layout/my-button/orange-button';
import InfoOrangeIcon from 'app/component/info-orange-icon';
import moment from 'moment';
import WarningIllustration from 'app/component/warning-illustration';
import Select from 'react-select';
import OtpInput from 'react-otp-input';
import useWindowDimensions from 'app/shared/util/screen-dimension';
import decryptJson from 'app/shared/util/security-decrypt';
import { toast } from 'react-toastify';
import TaxInformation from './tax-information';
import ModalPhoneLayout from './modal-phone-layout';
import TaxReportDownload from './tax-report-download';
import ModalTaxMain from './modal-tax-main';
import ModalConfirmation from './modal-confirmation';
import ModalChooseTax from './modal-choose-tax';

export const TaxReport = () => {
  const Page = PageConstUtil();
  const [whichPage, setPage] = useState(Page.LOGIN_PIN);
  const [getBlobFile, setBlobFile] = useState(null);

  const { height, width } = useWindowDimensions();

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const MYURL = EndPoint();
  const SEND_OTP_FROM = {
    EMAIL: 0,
    PHONE: 1,
  };

  const [getPhoneBackgroundColor, setPhoneBackgroundColor] = useState('#F37F26');
  const [getPhoneIconColor, setPhoneIconColor] = useState('#FFFFFF');
  const [getPhoneTextColor, setPhoneTextColor] = useState('#FFFFFF');

  const [getModalTitleMessage, setModalTitleMessage] = useState('');
  const [getModalDescMessage, setModalDescMessage] = useState('');
  const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);

  const [isAvailableResendOtp, setAvailableResendOtp] = useState(true);

  const [getEmail, setEmail] = useState('');
  const [getCellPhoneNumber, setCellPhoneNumber] = useState('');

  const [getSelectedYear, setSelectedYear] = useState({ label: '', value: '' });

  const [getYears, setYears] = useState([{ label: '', value: '' }]);

  const [getSelectedYpId, setSelectedYpId] = useState({ label: '', value: '' });

  const [getYpId, setYpId] = useState([{ label: '', value: '' }]);

  const [isOpenModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [isOpenModalTaxMain, setOpenModalTaxMain] = useState(false);

  //State for otp timer and reset button
  const [getResendButtonColorOTP, setResendButtonColorOTP] = useState('#E0E0E0');
  const [getResendButtonTextColorOTP, setResendButtonTextColorOTP] = useState('#9E9E9E');
  const [isActiveResend, setActiveResend] = useState(false);

  const [etOtpPhone, setOtpPhone] = useState('');

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    // const currentYear = 3000
    let tempYearsData = [];
    for (let i = 2021; i <= currentYear; i++) {
      tempYearsData.push({ label: i.toString(), value: i.toString() });
    }
    setYears(tempYearsData);
    setSelectedYear({
      label: currentYear.toString(),
      value: currentYear.toString(),
    });
  }, []);

  const { seconds, minutes, reset } = useTimer(
    GeneralSettingsConstant().OTP_TIME_EXPIRED,
    handleTimerFinish
  );

  const censoredEmail = () => {
    var tempCensoredEmail = getEmail;
    if (tempCensoredEmail != null && tempCensoredEmail != '') {
      const middleLengthStart = tempCensoredEmail.split('@')[0].length - 4;
      var censorCharacter = '****';
      return (
        tempCensoredEmail.substring(0, middleLengthStart) +
        censorCharacter +
        tempCensoredEmail.substring(middleLengthStart + censorCharacter.length)
      );
    } else {
      return '';
    }
  };

  const censoredPhone = () => {
    var tempCensoredPhone = getCellPhoneNumber;
    if (tempCensoredPhone != null && tempCensoredPhone != '') {
      const middleLengthStart = tempCensoredPhone.length - 8;
      var censorCharacter = '****';
      return (
        tempCensoredPhone.substring(0, middleLengthStart) +
        censorCharacter +
        tempCensoredPhone.substring(middleLengthStart + censorCharacter.length)
      );
    } else {
      return '';
    }
  };

  function handleTimerFinish() {
    setResendButtonColorOTP('#F37F26');
    setResendButtonTextColorOTP('#FFFFFF');
    setActiveResend(true);
  }

  const resetButtonDecoration = () => {
    setPhoneBackgroundColor('#EDEDED');
    setPhoneIconColor('#9E9E9E');
    setPhoneTextColor('#9E9E9E');
  };

  const disableButtonAffterSendOTP = () => {
    setResendButtonColorOTP('#E0E0E0');
    setResendButtonTextColorOTP('#9E9E9E');
    setActiveResend(false);
  };

  const handleGetUserInfo = () => {
    const timeStamp = new Date().toISOString();

    const bodyApi = {
      userId: Storage.local.get('username'),
      value: 'all',
      key: MyEncrypt({
        code: MySecKey(),
        timestamp: timeStamp,
        id: Storage.local.get('username'),
      }),
      timestamp: timeStamp,
    };

    setLoadingButton(true);

    Axios.post(MYURL.GET_USER_INFO, bodyApi)
      .then((res) => {
        if (res.status == 200) {
          setLoadingButton(false);
          if (
            res.data.email != null &&
            res.data.email != '' &&
            res.data.mobile != null &&
            res.data.mobile
          ) {
            setEmail(res.data.email);
            setCellPhoneNumber(res.data.mobile);
            handleGetAllYpId();
          } else {
            setLoadingButton(false);
            setModalIllustrationStatus(false);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage(LANG().EMPTY_NO_OR_EMAIL);
            setOpenModalLoginPIN(true);
          }
        } else {
          setLoadingButton(false);
          setModalIllustrationStatus(false);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
          setOpenModalLoginPIN(true);
        }
      })
      .catch((res) => {
        setLoadingButton(false);
        setModalIllustrationStatus(false);
        setModalTitleMessage(LANG().FAILED);
        setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
        setOpenModalLoginPIN(true);
      });
  };

  const getAccType = (accTypeCd) => {
    switch (parseInt(accTypeCd)) {
      case 1:
        return 'Regular';
      case 2:
        return 'Day Trading';
      case 3:
        return 'Margin Trading';
      case 4:
        return 'Syariah';
      default:
        return '';
    }
  };

  const handleGetAllYpId = () => {
    Axios.get(MYURL.GET_YP_ID + '?userId=' + Storage.local.get('username'))
      .then((res) => {
        if (res.status == 200) {
          setLoadingButton(false);

          interface ListMock {
            readonly value: string;
            readonly label: string;
          }

          let item: ListMock[] = [];
          for (let i = 0; i < res.data.length; i++) {
            item.push({
              label:
                res.data[i].acntNo + ' - ' + getAccType(res.data[i].acntType),
              value: res.data[i].acntNo,
            });
          }
          setYpId(item);
          if (res.data.length > 0) {
            setSelectedYpId({
              label:
                res.data[0].acntNo + ' - ' + getAccType(res.data[0].acntType),
              value: res.data[0].acntNo,
            });
          }
          setPage(Page.TAX_DOWNLOAD_REQUEST);
        } else {
          setLoadingButton(false);
          setModalIllustrationStatus(false);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
          setOpenModalLoginPIN(true);
        }
      })
      .catch((res) => {
        setLoadingButton(false);
        setModalIllustrationStatus(false);
        setModalTitleMessage(LANG().FAILED);
        setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
        setOpenModalLoginPIN(true);
      });
  };

  const handleSendOTPPhone = (isResend: Boolean) => {
    const timeStamp = new Date().toISOString();

    const bodyApi = {
      userId: Storage.local.get('username'),
      activity: 'Tax Report',
      key: MyEncrypt({
        code: MySecKey(),
        timestamp: timeStamp,
        id: Storage.local.get('username'),
      }),
      timestamp: timeStamp,
    };

    setLoadingButton(true);
    Axios.post(MYURL.SEND_OTP_SMS, bodyApi)
      .then((res) => {
        setLoadingButton(false);
        if (res.status == 201) {
          // formInputOTPEmailState.values.etOTP1 = "";
          // formInputOTPEmailState.values.etOTP2 = "";
          // formInputOTPEmailState.values.etOTP3 = "";
          // formInputOTPEmailState.values.etOTP4 = "";
          // formInputOTPEmailState.values.etOTP5 = "";
          // formInputOTPEmailState.values.etOTP6 = "";

          formInputOTPPhoneState.values.etOTP1 = '';
          formInputOTPPhoneState.values.etOTP2 = '';
          formInputOTPPhoneState.values.etOTP3 = '';
          formInputOTPPhoneState.values.etOTP4 = '';
          formInputOTPPhoneState.values.etOTP5 = '';
          formInputOTPPhoneState.values.etOTP6 = '';

          if (isResend == false) {
            console.log('kesini dah broo')
            //reset availablility flag to send OTP
            setAvailableResendOtp(true);
            setPage(Page.BANK_OUT_INPUT_OTP_PHONE);
          }
        } else {
          console.log('kesini dah broo salah')
          setAvailableResendOtp(false);
          setModalIllustrationStatus(false);
          isResend
            ? setOpenModalOtpInputPhone(true)
            : setOpenModalOtpSendPhone(true);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage('' + res.data.message);
        }
      })
      .catch((res) => {
        setAvailableResendOtp(false);
        setLoadingButton(false);
        setModalIllustrationStatus(false);
        isResend
          ? setOpenModalOtpInputPhone(true)
          : setOpenModalOtpSendPhone(true);
        setModalTitleMessage(LANG().FAILED);
        setModalDescMessage('' + res.response.data.message);
      });
  };

  const tabChange = function (val: number) {
    try {
      if (whichPage == Page.LOGIN_PIN) {
        const pinValues =
          loginPINState.values.etPIN1 +
          loginPINState.values.etPIN2 +
          loginPINState.values.etPIN3 +
          loginPINState.values.etPIN4 +
          loginPINState.values.etPIN5 +
          loginPINState.values.etPIN6;

        if (pinValues.length == 6) {
          handleLoginPIN(pinValues);
        }
      }

      let ele = document.querySelectorAll('input');
      if (ele[val - 1].value != '') {
        ele[val].focus();
      } else if (ele[val - 1].value == '') {
        ele[val - 2].focus();
      }
    } catch (e) { }
  };
  const handleLoginPIN = (pin: string) => {
    const bodyApi = {
      userId: Storage.local.get('username'),
      pin: pin,
    };

        setLoadingButton(true)

        Axios.post(MYURL.VERIFY_PIN,
            bodyApi,
        ).then(
            res => {
                setLoadingButton(false)
                let decryptedMessage = decryptJson(res.data.message);
                if (decryptedMessage == "PIN verified") {
                    handleGetUserInfo()
                } else {
                    setPIN("")
                    setModalIllustrationStatus(false);
                    setOpenModalLoginPIN(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage("" + decryptedMessage);
                }
            }
        ).catch(
            res => {
                let decryptedMessage = decryptJson(res.response.data.message);
                setPIN("")
                setLoadingButton(false)
                setModalIllustrationStatus(false);
                setOpenModalLoginPIN(true);
                setModalTitleMessage(LANG().FAILED);
                setModalDescMessage("" + decryptedMessage);
            });
    }

  const handleVerifyOTP = (etOtp: string, otpFrom: number) => {
    /*const parseBody = JSON.parse(body);

        //Handle required OTP
        const otp = parseBody['etOTP1'] + parseBody['etOTP2'] + parseBody['etOTP3'] + parseBody['etOTP4'] + parseBody['etOTP5'] + parseBody['etOTP6'];*/
    if (etOtp.length != 6) {
      setModalIllustrationStatus(false);
      setOpenModalOtpInputPhone(true);
      setModalTitleMessage(LANG().FAILED);
      setModalDescMessage(LANG().OTP_INCOMPLETE);
      return;
    }

    const timeStamp = new Date().toISOString();

    const bodyApi = {
      otp: etOtp,
      userId: Storage.local.get('username'),
      key: MyEncrypt({
        code: MySecKey(),
        timestamp: timeStamp,
        id: Storage.local.get('username'),
      }),
      timestamp: timeStamp,
    };

    setLoadingButton(true);

    Axios.post(MYURL.VERIFY_OTP, bodyApi)
      .then((res) => {
        if (res.status == 200) {
          //If OTP Verifying is sent to the email, then update the data phone number else update the data email address (CROSS/Silang)
          let decryptedMessage = JSON.parse(decryptJson(res.data.message));
          if (Storage.local.get('username', '') === decryptedMessage.userId) {
            handleDownloadPDF2();
          } else {
            toast.error('Failed to authenticate user');
          }
        } else {
          setLoadingButton(false);
          setModalIllustrationStatus(false);
          //Modal dialog still same, not CROSS/Silang because it's about UI not flow
          setOpenModalOtpInputPhone(true);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage('' + res.data.message);
        }
      })
      .catch((res) => {
        setLoadingButton(false);
        setModalIllustrationStatus(false);
        //Modal dialog still same, not CROSS/Silang because it's about UI not flow
        setOpenModalOtpInputPhone(true);
        setModalTitleMessage(LANG().FAILED);
        setModalDescMessage('' + res.response.data.message);
      });
  };

  const handleDownloadPDF2 = () => {
    // const config: AxiosRequestConfig = { responseType: 'blob' };
    Axios
    .post(MYURL.REQUEST_TAX_REPORT_DOWNLOAD, {accountNo : getSelectedYpId.value,userId: Storage.local.get('username'), year: getSelectedYear.value})
    .then((res) => {
      setLoadingButton(false);
      // const url = URL.createObjectURL(res.data);
      // const a = document.createElement('a');
      // @ts-ignore
      // a.style = 'display: none';
      // a.href = url;

      //Rename Downloaded File Name by KYC-347 request
      //Format -> [YPID]_Tax Report [year]_Client Portofolio [Decyear]_Dividen[year]
      //Sample -> 123456_Tax Report 2023_Client Portofolio Dec2023_Dividen 2023
      // a.download =
      //   getSelectedYpId?.value +
      //   '_Tax Report ' +
      //   getSelectedYear.value +
      //   '_Client Portfolio Dec' +
      //   getSelectedYear.value +
      //   '_Dividen ' +
      //   getSelectedYear.value +
      //   '.pdf';

      // // + moment().format("DDMMYYYY") + ".pdf"
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      // URL.revokeObjectURL(url);

      setModalIllustrationStatus(true);
      setModalTitleMessage(LANG().SUCCESS_DOWNLOAD);
      setModalDescMessage(LANG().SUC_TAX_REPORT);
      setOpenModalOtpInputPhone(true);
    })
    .catch((res) => {
      setLoadingButton(false);
      setModalIllustrationStatus(false);
      setModalTitleMessage(LANG().FAILED);
      setModalDescMessage(LANG().FAILED_DOWNLOAD);
      setOpenModalOtpInputPhone(true);
    });
    // Axios.get(
    //   MYURL.DOWNLOAD_TAX_REPORT +
    //   '?account-no=' +
    //   getSelectedYpId.value +
    //   '&year=' +
    //   getSelectedYear.value,
    //   // config
    // )
    //   .then((res) => {
    //     setLoadingButton(false);
    //     const url = URL.createObjectURL(res.data);
    //     const a = document.createElement('a');
    //     // @ts-ignore
    //     a.style = 'display: none';
    //     a.href = url;

    //     //Rename Downloaded File Name by KYC-347 request
    //     //Format -> [YPID]_Tax Report [year]_Client Portofolio [Decyear]_Dividen[year]
    //     //Sample -> 123456_Tax Report 2023_Client Portofolio Dec2023_Dividen 2023
    //     a.download =
    //       getSelectedYpId?.value +
    //       '_Tax Report ' +
    //       getSelectedYear.value +
    //       '_Client Portfolio Dec' +
    //       getSelectedYear.value +
    //       '_Dividen ' +
    //       getSelectedYear.value +
    //       '.pdf';

    //     // + moment().format("DDMMYYYY") + ".pdf"
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(url);

    //     setModalIllustrationStatus(true);
    //     setModalTitleMessage(LANG().SUCCESS_DOWNLOAD);
    //     setModalDescMessage(LANG().SUC_TAX_REPORT);
    //     setOpenModalOtpInputPhone(true);
    //   })
    //   .catch((res) => {
    //     setLoadingButton(false);
    //     setModalIllustrationStatus(false);
    //     setModalTitleMessage(LANG().FAILED);
    //     setModalDescMessage(LANG().FAILED_DOWNLOAD);
    //     setOpenModalOtpInputPhone(true);
    //   });
  }

  const handleDownloadPDF = (otpFrom: number) => {
    //TODO change account number to dynamic user according to login user
    // @ts-ignore

    const config: AxiosRequestConfig = { responseType: 'blob' };

    Axios.get(
      MYURL.DOWNLOAD_TAX_REPORT +
      '?account-no=' +
      getSelectedYpId.value +
      '&year=' +
      getSelectedYear.value,
      config
    )
      .then((res) => {
        setLoadingButton(false);
        const url = URL.createObjectURL(res.data);
        const a = document.createElement('a');
        // @ts-ignore
        a.style = 'display: none';
        a.href = url;

        //Rename Downloaded File Name by KYC-347 request
        //Format -> [YPID]_Tax Report [year]_Client Portofolio [Decyear]_Dividen[year]
        //Sample -> 123456_Tax Report 2023_Client Portofolio Dec2023_Dividen 2023
        a.download =
          getSelectedYpId?.value +
          '_Tax Report ' +
          getSelectedYear.value +
          '_Client Portfolio Dec' +
          getSelectedYear.value +
          '_Dividen ' +
          getSelectedYear.value +
          '.pdf';

        // + moment().format("DDMMYYYY") + ".pdf"
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        setModalIllustrationStatus(true);
        setModalTitleMessage(LANG().SUCCESS_DOWNLOAD);
        setModalDescMessage(LANG().SUC_TAX_REPORT);
        setOpenModalOtpInputPhone(true);
      })
      .catch((res) => {
        setLoadingButton(false);
        setModalIllustrationStatus(false);
        setModalTitleMessage(LANG().FAILED);
        setModalDescMessage(LANG().FAILED_DOWNLOAD);
        setOpenModalOtpInputPhone(true);
      });
  };

  //Page 1 Login PIN
  const loginPINState = useFormik({
    initialValues: {
      etPIN1: '',
      etPIN2: '',
      etPIN3: '',
      etPIN4: '',
      etPIN5: '',
      etPIN6: '',
    },

    onSubmit: (values) => {
      handleLoginPIN(etPIN);
    },
  });

  const [etPIN, setPIN] = useState('');

  useEffect(() => {
    if (etPIN.length == 6) {
      handleLoginPIN(etPIN);
    }
  }, [etPIN]);

  const [isOpenModalLoginPIN, setOpenModalLoginPIN] = useState(false);

  const handleDownloadTaxReport = () => {
    console.log('DOWNLOAD TAX REPORT');
    const currentYear = new Date().getFullYear();
    const oneYearAgo = new Date().getFullYear() - 1;
    const currentMonth = new Date().getMonth() + 1;

    if (
      //Tidak bisa print tahun sekarang
      //Cannot print current year
      currentYear.toString() == getSelectedYear.value
    ) {
      setOpenModalTaxMain(true);
      setModalTitleMessage(LANG().NOT_AVAILABLE_DATA);
      setModalDescMessage(LANG().WARN_TAX_REPORT);
    } else {
      setOpenModalConfirmation(true);
      setModalDescMessage(
        `${LANG().TAX_CONFIRMATION} ${getSelectedYear.value}?`
      );
    }
  };

  const loginPinLayout = (
    <div className="page-wrapper-pin">
      <Modal style={{ top: '25%' }} isOpen={isOpenModalLoginPIN}>
        <ModalBody>
          <div className="text-center mb-4">
            {getModalIllustrationStatus ? (
              <SuccessIllustration />
            ) : (
              <FailedIllustration />
            )}
          </div>
          <p
            style={{ fontSize: '20px' }}
            className="text-center mb-2 font-m-500"
          >
            {getModalTitleMessage}
          </p>
          <p
            style={{ fontSize: '14px' }}
            className="text-center mb-2 font-r-400"
          >
            {getModalDescMessage}
          </p>
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setOpenModalLoginPIN(false);
              setModalTitleMessage('');
              setModalDescMessage('');
              location.reload();
            }}
          >
            <DarkPurpleButton text="OK" width="20%" />
          </div>
        </ModalBody>
      </Modal>

      <div
        style={
          width <= 360
            ? { marginLeft: 'auto', marginRight: 'auto', width: width }
            : { marginLeft: 'auto', marginRight: 'auto', width: '330px' }
        }
      >
        <Row className="nav-title">
          <div className="media-body d-flex justify-content-left">
            <Link
              to={{
                pathname: '/',
              }}
            >
              <div
                style={{ marginRight: 12 }}
                className="d-flex align-items-center"
              >
                <NavBackIcon />
              </div>
            </Link>
            <div className="font-r-400 text-black">
              {LANG().TITLE.LOGIN_PIN}
            </div>
          </div>
        </Row>

        <Row className="mt-4 pin-width">
          <OtpInput
            value={etPIN}
            onChange={(e) => {
              setPIN(e);
            }}
            numInputs={6}
            inputType={'password'}
            renderSeparator={<span style={{ width: '20px' }}></span>}
            renderInput={(props) => (
              <input
                id={'pinInput'}
                {...props}
                style={{
                  width: width <= 320 ? '28px' : width <= 360 ? '34px' : '40px',
                  height:
                    width <= 320 ? '28px' : width <= 360 ? '34px' : '40px',
                  fontSize: '24px',
                  borderTop: '0px',
                  borderLeft: '0px',
                  borderRight: '0px',
                  outline: 'none',
                  borderColor: '#E0E0E0',
                  color: '#000',
                  textAlign: 'center',
                }}
              />
            )}
            shouldAutoFocus
          />

          <br />
          <br />

          <div style={{ marginTop: 40 }}>
            <DarkPurpleButton
              width={width <= 360 ? width - 50 : '325px'}
              text={LANG().BUTTON.SEND}
              disabled={loadingButton}
            />
          </div>
        </Row>
      </div>
    </div>
  );

  const setOnClickRequestDownload = () => {
    setPage(Page.TAX_MAIN);
  }

  //Page 1.5 Tax Report Download Center
  const taxDownloadList =
    <TaxReportDownload
      ypId={getSelectedYpId.value}
      onClickRequestDownload={setOnClickRequestDownload} />

  const onClickModal = () => {
    setOpenModalTaxMain(false);
    setModalTitleMessage('');
    setModalDescMessage('');
  }

  const onModalConfirm = () => {
    setModalDescMessage('');
    setOpenModalConfirmation(false);
    setPage(Page.TAX_CHOOSE_PHONE);
  }

  const onModalCancel = () => {
    setModalTitleMessage('');
    setModalDescMessage('');
    setOpenModalConfirmation(false);
  }
  //Page 2 Tax Main
  const taxMain = (
    <div className="page-wrapper-account">
      <ModalTaxMain
        isOpenModalTaxMain={isOpenModalTaxMain}
        getModalTitleMessage={getModalTitleMessage}
        getModalDescMessage={getModalDescMessage}
        onClickModal={() => onClickModal()}
      />

      <ModalConfirmation
        isOpenModalConfirmation={isOpenModalConfirmation}
        getModalDescMessage={getModalDescMessage}
        onConfirm={() => onModalConfirm()}
        onCancel={() => onModalCancel()}
        />

      <ModalChooseTax 
        getSelectedYpId={getSelectedYpId}
        getYpId={getYpId}
        getSelectedYear={getSelectedYear}
        getYears={getYears}
        loadingButton={loadingButton}
        onBackClick={() => {
            setPage(Page.TAX_DOWNLOAD_REQUEST)
        }}
        onSelectedYpId={obj => {
          setSelectedYpId({ label: obj.label, value:obj.value })
        }}
        onSelectedYear={obj => {
          setSelectedYear({ label: obj.label, value:obj.value })
        }}
        onDownloadClick={() => 
          handleDownloadTaxReport()
        }
      />

      {/* <div
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        className="col-md-4"
      >
        <Row className="nav-title">
          <div className="media-body d-flex justify-content-left">
            <div
              style={{ marginRight: 12 }}
              className="d-flex align-items-center"
            >
              <NavBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setPage(Page.TAX_DOWNLOAD_REQUEST)
                }}
              />
            </div>
            <div className="font-r-400 text-black">
              {LANG().PageTitle.taxReport}
            </div>
          </div>
        </Row>

        <Row>
          <div>YP ID:</div>
        </Row>

        <Row className={'mb-4'}>
          <Select
            name={'sYpId'}
            className="basic-single"
            placeholder={''}
            value={getSelectedYpId}
            options={getYpId.sort((a, b) => a.label.localeCompare(b.label))}
            styles={{
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                border: 0,
                background: isFocused
                  ? '#f8dbc4'
                  : isSelected
                    ? '#F37F26'
                    : undefined,
                zIndex: 1,
              }),
            }}
            onChange={(e) => {
              setSelectedYpId({ label: e.label, value: e.value });
            }}
          />
        </Row>

        <Row>
          <div>{LANG().FORM.TAX_YEAR.LABEL}</div>
        </Row>

        <Row>
          <div className={'col-lg-8 col-md-12 mb-3'}>
            <Select
              name={'sYears'}
              className="basic-single"
              placeholder={''}
              value={getSelectedYear}
              options={getYears.sort((a, b) => a.label.localeCompare(b.label))}
              styles={{
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  border: 0,
                  background: isFocused
                    ? '#f8dbc4'
                    : isSelected
                      ? '#F37F26'
                      : undefined,
                  zIndex: 1,
                }),
              }}
              onChange={(e) => {
                setSelectedYear({ label: e.label, value: e.value });
              }}
            />
          </div>
          <div className={'col-lg-4 col-md-12'}>
            <OrangeButton
              margin={0}
              text={LANG().BUTTON.DOWNLOAD}
              disabled={loadingButton}
              onClick={() => {
                handleDownloadTaxReport();
              }}
            />
          </div>
        </Row>

        <Row
          style={{
            backgroundColor: '#FFF4EB',
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '8px',
            padding: '8px',
            borderRadius: '4px',
          }}
        >
          <Col style={{ maxWidth: '30px' }}>
            <div>
              <InfoOrangeIcon />
            </div>
          </Col>
          <Col>
            <Row>
              <div>
                <span style={{ fontSize: '10pt', fontWeight: '900' }}>
                  Informasi
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  {LANG().TAX_INFORMATION}
                </span>
                <br />
                <br />
                <span style={{ fontSize: '9pt', fontWeight: '600' }}>
                  Customer Service
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  {LANG().PHONE} &nbsp;
                  <a href="tel:021150350">(021) 150 350</a>
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  Email : &nbsp;
                  <a
                    href="mailto:cs@miraeasset.co.id"
                    title="mailto:cs@miraeasset.co.id"
                  >
                    cs@miraeasset.co.id
                  </a>
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  WhatsApp :{' '}
                  <a href="https://wa.me/6281119009000">+6281119009000</a>
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </div> */}
    </div>
  );

  //Page 3 form state [Choose Phone]:
  const formChoosePhoneState = useFormik({
    initialValues: {
      etPhone: '',
    },

    onSubmit: (values) => {
      handleSendOTPPhone(false);
    },
  });

  const [isOpenModalOtpSendPhone, setOpenModalOtpSendPhone] = useState(false);

  const choosePhoneLayout = (
    <div className="page-wrapper-account">
      <Modal style={{ top: '25%' }} isOpen={isOpenModalOtpSendPhone}>
        <ModalBody>
          <div className="text-center mb-4">
            {getModalIllustrationStatus ? (
              <SuccessIllustration />
            ) : (
              <FailedIllustration />
            )}
          </div>
          <p
            style={{ fontSize: '20px' }}
            className="text-center mb-2 font-m-500"
          >
            {getModalTitleMessage}
          </p>
          <p
            style={{ fontSize: '14px' }}
            className="text-center mb-2 font-r-400"
          >
            {getModalDescMessage}
          </p>
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setModalTitleMessage('');
              setModalDescMessage('');
              getModalIllustrationStatus
                ? (window.location.href = '/')
                : setOpenModalOtpSendPhone(false);
            }}
          >
            <DarkPurpleButton text="OK" width="20%" />
          </div>
        </ModalBody>
      </Modal>

      <div
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        className="col-md-4"
      >
        <Row className="nav-title">
          <div className="media-body d-flex justify-content-left">
            <div
              style={{ marginRight: 12 }}
              className="d-flex align-items-center"
            >
              <NavBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = '/';
                }}
              />
            </div>
            <div className="font-r-400 text-black">
              {LANG().PageTitle.taxReport}
            </div>
          </div>
        </Row>

        <Row className="desc-title">
          <div className="font-r-400 text-black">
            {LANG().SUBTITLE.CHOOSE_OTP}
          </div>
        </Row>

        <Button
          onClick={() => {
            resetButtonDecoration();
            setPhoneBackgroundColor('#F37F26');
            setPhoneIconColor('#FFFFFF');
            setPhoneTextColor('#FFFFFF');

            setPage(Page.BANK_OUT_CHOOSE_PHONE);
          }}
          style={{
            cursor: 'pointer',
            backgroundColor: getPhoneBackgroundColor,
            marginTop: '40px',
          }}
          className="tabItemMenu border-0"
        >
          <ForgotChoosePhoneIcon fill={getPhoneIconColor} />
          <div style={{ color: getPhoneTextColor, display: 'inline' }}>
            {LANG().TABMENU.SMS}
          </div>
        </Button>

        <AvForm
          onSubmit={formChoosePhoneState.handleSubmit}
          style={{ marginTop: '16px' }}
        >
          <div className="input-title text-black">
            <div className="input-id-title">{LANG().FORM.PHONE.CELL.LABEL}</div>
          </div>

          <AvField name="etPhone" value={censoredPhone()} disabled />

          <div className="mt-2">
            <DarkPurpleButton
              onClick={() => {
                disableButtonAffterSendOTP();
                reset();
              }}
              text={LANG().BUTTON.SEND}
              disabled={loadingButton}
            />
          </div>
        </AvForm>
      </div>
    </div>
  );

  //Page 4 form state [Input OTP From Phone]:
  const formInputOTPPhoneState = useFormik({
    initialValues: {
      etOTP1: '',
      etOTP2: '',
      etOTP3: '',
      etOTP4: '',
      etOTP5: '',
      etOTP6: '',
    },

    onSubmit: (values) => {
      /*setOpenModalConfirmationPhone(true)
            setModalDescMessage(`${LANG().TAX_CONFIRMATION} ${selectedYear.current.value}`);*/
      // handleVerifyOTP(JSON.stringify(values, null, 2), SEND_OTP_FROM.PHONE)
      handleVerifyOTP(etOtpPhone, SEND_OTP_FROM.PHONE);
    },
  });

  useEffect(() => {
    if (etOtpPhone.length == 6) {
      handleVerifyOTP(etOtpPhone, SEND_OTP_FROM.PHONE);
    }
  }, [etOtpPhone]);

  const [isOpenModalOtpInputPhone, setOpenModalOtpInputPhone] = useState(false);

  function modalOnClick() {
    setOpenModalOtpInputPhone(false)
    setModalTitleMessage('');
    setModalDescMessage('');
    getModalIllustrationStatus
      ? (window.location.href = '/')
      : setOpenModalOtpInputPhone(false);
  }

  const inputOtpPhoneLayout = (
    <div className="page-wrapper-account">
      <ModalPhoneLayout
        isOpenModalOtpInputPhone={isOpenModalOtpInputPhone}
        getModalIllustrationStatus={getModalIllustrationStatus}
        getModalTitleMessage={getModalTitleMessage}
        getModalDescMessage={getModalDescMessage}
        onClick={() => modalOnClick()}
      />

      <div
        style={
          width <= 360
            ? { marginLeft: 'auto', marginRight: 'auto', width: width }
            : { marginLeft: 'auto', marginRight: 'auto', width: '330px' }
        }
      >
        <Row className="nav-title">
          <div className="media-body d-flex justify-content-left">
            <div
              style={{ marginRight: 12 }}
              className="d-flex align-items-center"
            >
              <NavBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setPage(Page.BANK_OUT_CHOOSE_PHONE);
                }}
              />
            </div>
            <div className="font-r-400 text-black">
              {LANG().TITLE.INPUT_OTP}
            </div>
          </div>
        </Row>

        <Row
          style={width <= 360 ? { width: width - 50 } : { width: '330px' }}
          className="desc-title"
        >
          <div className="font-r-400 text-black">
            {LANG().SUBTITLE.INPUT_OTP_PHONE + censoredPhone()}
          </div>
        </Row>

        <Row className="mt-4">
          <AvForm onSubmit={formInputOTPPhoneState.handleSubmit}>
            <Row>
              {/*<Col className='d-flex justify-content-between'>
                            <input
                                name="etOTP1"
                                className="otp"
                                maxLength={1}
                                type="text"
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP1}
                                onChange={(e) => {
                                    let otp = e?.target?.value;
                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP1",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(1)}/>
                            <input
                                name="etOTP2"
                                className="otp"
                                type="text"
                                maxLength={1}
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP2}
                                onChange={(e) => {
                                    let otp = e?.target?.value;

                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP2",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(2)}/>
                            <input
                                name="etOTP3"
                                className="otp"
                                type="text"
                                maxLength={1}
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP3}
                                onChange={(e) => {
                                    let otp = e?.target?.value;

                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP3",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(3)}/>
                            <input
                                name="etOTP4"
                                className="otp"
                                type="text"
                                maxLength={1}
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP4}
                                onChange={(e) => {
                                    let otp = e?.target?.value;

                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP4",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(4)}/>
                            <input
                                name="etOTP5"
                                className="otp"
                                type="text"
                                maxLength={1}
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP5}
                                onChange={(e) => {
                                    let otp = e?.target?.value;

                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP5",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(5)}/>
                            <input
                                name="etOTP6"
                                className="otp"
                                type="text"
                                maxLength={1}
                                placeholder='_'
                                value={formInputOTPPhoneState.values.etOTP6}
                                onChange={(e) => {
                                    let otp = e?.target?.value;

                                    formInputOTPPhoneState.handleChange({
                                        target: {
                                            name: "etOTP6",
                                            value: otp?.replace(/[^0-9]+/g, '')
                                        }
                                    })
                                }}
                                onKeyUp={(e) => tabChange(6)}/>
                        </Col>*/}
              <div>
                <OtpInput
                  value={etOtpPhone}
                  onChange={(e) => {
                    setOtpPhone(e);
                  }}
                  inputType={'tel'}
                  numInputs={6}
                  containerStyle={{ paddingLeft: 0, marginTop: '16px' }}
                  renderSeparator={<span style={{ width: '20px' }}></span>}
                  renderInput={(props) => (
                    <input
                      id={'pinInput'}
                      {...props}
                      style={{
                        textAlign: 'center',
                        width:
                          width <= 320
                            ? '28px'
                            : width <= 360
                              ? '34px'
                              : '40px',
                        height:
                          width <= 320
                            ? '28px'
                            : width <= 360
                              ? '34px'
                              : '40px',
                        border: '1px solid #C2C2C2',
                        boxSizing: 'border-box',
                        fontSize: '20px',
                        boxShadow: 'inset 1px 2px 2px rgba(0, 0, 0, 0.12)',
                        borderRadius: '6px',
                      }}
                      placeholder={'_'}
                    />
                  )}
                  shouldAutoFocus
                />
              </div>
            </Row>

            <div style={{ marginTop: 20 }} className="mt-4">
              <DarkPurpleButton
                width={width <= 360 ? width - 50 : '330px'}
                text={LANG().BUTTON.SEND}
                disabled={loadingButton}
              />
            </div>

            <Row className="mt-4">
              <div className="col d-flex justify-content-between">
                {isAvailableResendOtp ? (
                  <>
                    <div className="d-flex align-items-center">
                      {LANG().OTP_RECEIVE}?{' '}
                      <span
                        className="time"
                        style={{
                          marginLeft: '8px',
                          color: '#D8382E',
                        }}
                      >{`${minutes} : ${seconds}`}</span>
                    </div>

                    {width > 375 && (
                      <div>
                        <Button
                          onClick={
                            isActiveResend
                              ? () => {
                                disableButtonAffterSendOTP();
                                reset();
                                handleSendOTPPhone(true);
                              }
                              : () => { }
                          }
                          style={{
                            backgroundColor: getResendButtonColorOTP,
                            textAlign: 'center',
                          }}
                        >
                          <span style={{ color: getResendButtonTextColorOTP }}>
                            {LANG().BUTTON.RESEND}
                          </span>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      {LANG().OTP_RECEIVE}?
                    </div>

                    <div>
                      <Button
                        style={{
                          backgroundColor: '#F37F26',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          handleSendOTPPhone(true);
                        }}
                      >
                        <span style={{ color: '#FFFFFF' }}>
                          {LANG().BUTTON.RESEND}
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {width <= 375 && (
                <div className={'mt-2'}>
                  <div>
                    <Button
                      onClick={
                        isActiveResend
                          ? () => {
                            disableButtonAffterSendOTP();
                            reset();
                            handleSendOTPPhone(true);
                          }
                          : () => {}
                      }
                      style={{
                        backgroundColor: getResendButtonColorOTP,
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ color: getResendButtonTextColorOTP }}>
                        {LANG().BUTTON.RESEND}
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </Row>
          </AvForm>
        </Row>
      </div>
    </div>
  );

  switch (whichPage) {
    case Page.TAX_MAIN:
      return taxMain;
    case Page.TAX_CHOOSE_PHONE:
      return choosePhoneLayout;
    case Page.TAX_INPUT_OTP_PHONE:
      return inputOtpPhoneLayout;
    case Page.LOGIN_PIN:
      return loginPinLayout;
    case Page.TAX_DOWNLOAD_REQUEST:
      return taxDownloadList;
  }

  return loginPinLayout;
};

export default connect()(TaxReport);
