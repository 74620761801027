import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Button, Col, Input, Label, Modal, ModalBody, Row, Spinner} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import './../form.scss';
import PageConstUtil from "app/shared/util/page-constants";
import EndPoint from "app/shared/util/api-constant"
import {useFormik} from "formik";
import {Storage} from "react-jhipster";
import Axios from "axios";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";
import NavBackIcon from "app/component/nav-back-icon";
import * as Yup from 'yup';
import HeaderForm from "app/shared/layout/header-form/header-form";
import MenuRevampPersonalData from "app/component/menu-revamp-personal-data";
import MenuRevampAddress from "app/component/menu-revamp-address";
import MenuRevampWorkingData from "app/component/menu-revamp-working-data";
import MenuRevampArrowDown from "app/component/menu-revamp-arrow-down";
import MenuRevampArrowUp from "app/component/menu-revamp-arrow-up";
import RadioSelectIcon from "app/component/radio-select-icon";
import RadioDeselectIcon from "app/component/radio-deselect-icon";
import OrangeButton from "app/shared/layout/my-button/orange-button";
import LANG from "app/shared/util/lang-constant";
import Select, {useStateManager} from "react-select";
import ForgotChooseEmailIcon from "app/component/forgot-choose-email-icon";
import ForgotChoosePhoneIcon from "app/component/forgot-choose-phone-icon";
import MyEncrypt from "app/shared/util/security-encrypt";
import MySecKey from "app/shared/util/security-key";
import GeneralSettingsConstant from "app/shared/util/general-settings-constant";
import {useTimer} from "reactjs-countdown-hook";
import DatePicker from 'react-datepicker';
import "./revamp.scss"
import moment from "moment";
import axios from "axios";
import {dateIsValid, toDateHots} from "app/shared/util/date-utils";
import Resizer from "react-image-file-resizer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfoIcon from "app/component/info-icon";

interface StoreBioFormProps {
    userId: any,
    ypId: [],
    investorCountry: any,
    investorCitizenship: any,
    investorFullName: any,
    investorCidNo: any,
    investorCidExp: any,
    investorKitasNo: any,
    investorKitasExp: any,
    investorPassNo: any,
    investorPassExp: any,
    investorBirthDate: any,
    investorBirthPlace: any,
    investorSex: any,
    investorMartialStatus: any,
    investorSpouseName: any,
    investorSpouseCidNo: any,
    investorSpouseBirthDate: any,
    investorSpouseCntcNo: any,
    investorMothersMaidenName: any,
    investorDependencyCount: any,
    investorSeperateAggreement: any,
    investorNPWPNumber: any,
    investorNPWPRegistrationDate: any,
    investorHomePhone: any,
    investorMobilePhone: any,
    investorEmail: any,
    // investorFax: any,
    investorEducationalBackground: any,

    investorCountryCode: any,
    investorCountryName: any,
    investorAddress: any,
    investorPrpsCd: any,
    investorPrpsNm: any,
    investorCityCd: any,
    investorCityNm: any,
    investorDistrictNm: any,
    investorUrbanCd: any,
    investorDistrictCd: any,
    investorUrbanNm: any,

    investorOtherCountryCode: any,
    investorOtherCountryName: any,
    investorOtherAddress: any,
    investorOtherPrpsCd: any,
    investorOtherPrpsNm: any,
    investorOtherCityCd: any,
    investorOtherCityNm: any,
    investorOtherDistrictCd: any,
    investorOtherDistrictNm: any,
    investorOtherUrbanCd: any,
    investorOtherUrbanNm: any,

    investorWorkCountryCode: any,
    investorWorkCountryName: any,
    investorWorkAddress: any,
    investorWorkPrpsCd: any,
    investorWorkPrpsNm: any,
    investorWorkCityCd: any,
    investorWorkCityNm: any,
    investorWorkDistrictCd: any,
    investorWorkDistrictNm: any,
    investorWorkUrbanCd: any,
    investorWorkUrbanNm: any,

    investorPostalCode: any,
    investorOtherPostalCode: any,
    investorOccupation: any,
    investorOccupationDetl: any,
    investorOtherJob: any,
    investorOfficeName: any,
    investorEntpType: any,
    investorWorkPostalCode: any,
    investorWorkHomePhone: any,
    // investorWorkFax: any,
    investorJobRole: any,
    investorWorkYear: any,
    investorWorkMonth: any,
    assetOwner: any,
    investorFundSource: any,
    investorFundSourceDetl: any,
    investorIncomePerAnnum: any,
    iBenfOwnerNm: any,
    iBenfOwnerDob: any
}

export const RevampDataReupload = () => {
    return (<div></div>);
}

// export const RevampDataReupload = () => {

//     const Page = PageConstUtil();
//     const MYURL = EndPoint();

//     const [isEnableTabChange, setEnableChange] = useState<Boolean>(true)

//     const [getBioCache, setBioCache] = useState<StoreBioFormProps>({
//         assetOwner: null,

//         investorCountryCode: null,
//         investorCountryName: null,
//         investorAddress: null,
//         investorPrpsCd: null,
//         investorPrpsNm: null,
//         investorCityCd: null,
//         investorCityNm: null,
//         investorDistrictCd: null,
//         investorDistrictNm: null,
//         investorUrbanCd: null,
//         investorUrbanNm: null,

//         investorOtherCountryCode: null,
//         investorOtherCountryName: null,
//         investorOtherAddress: null,
//         investorOtherPrpsCd: null,
//         investorOtherPrpsNm: null,
//         investorOtherCityCd: null,
//         investorOtherCityNm: null,
//         investorOtherDistrictCd: null,
//         investorOtherDistrictNm: null,
//         investorOtherUrbanCd: null,
//         investorOtherUrbanNm: null,

//         investorWorkCountryCode: null,
//         investorWorkCountryName: null,
//         investorWorkAddress: null,
//         investorWorkPrpsCd: null,
//         investorWorkPrpsNm: null,
//         investorWorkCityCd: null,
//         investorWorkCityNm: null,
//         investorWorkDistrictCd: null,
//         investorWorkDistrictNm: null,
//         investorWorkUrbanCd: null,
//         investorWorkUrbanNm: null,

//         investorBirthDate: null,
//         investorBirthPlace: null,
//         investorCidExp: null,
//         investorCidNo: null,
//         investorCitizenship: null,
//         investorCountry: null,
//         investorDependencyCount: null,
//         investorEducationalBackground: null,
//         investorEmail: null,
//         // investorFax: null,
//         investorFullName: null,
//         investorFundSource: null,
//         investorFundSourceDetl: null,
//         investorHomePhone: null,
//         investorIncomePerAnnum: null,
//         investorJobRole: null,
//         investorOtherJob: null,
//         investorKitasExp: null,
//         investorKitasNo: null,
//         investorMartialStatus: null,
//         investorMobilePhone: null,
//         investorMothersMaidenName: null,
//         investorNPWPNumber: null,
//         investorNPWPRegistrationDate: null,
//         investorOccupation: null,
//         investorOccupationDetl: null,
//         investorOfficeName: null,

//         investorEntpType: null,
//         investorOtherPostalCode: null,
//         investorPassExp: null,
//         investorPassNo: null,
//         investorPostalCode: null,
//         investorSeperateAggreement: null,
//         investorSex: null,
//         investorSpouseBirthDate: null,
//         investorSpouseCidNo: null,
//         investorSpouseCntcNo: null,
//         investorSpouseName: null,

//         // investorWorkFax: null,
//         investorWorkHomePhone: null,
//         investorWorkPostalCode: null,
//         investorWorkYear: null,
//         investorWorkMonth: null,
//         userId: null,
//         ypId: [],

//         iBenfOwnerNm: null,
//         iBenfOwnerDob: null
//     })

//     const [isTabOneReqShow, setTabOneReqShow] = useState<boolean>(false)
//     const [isTabTwoReqShow, setTabTwoReqShow] = useState<boolean>(false)
//     const [isTabThreeReqShow, setTabThreeReqShow] = useState<boolean>(false)

//     const [isUpdatedetFUllname, setUpdatedetFUllname] = useState<boolean>(false)
//     const [isUpdatedetKTPNumber, setUpdatedetKTPNumber] = useState<boolean>(false)
//     const [isUpdatedetKTPExpired, setUpdatedetKTPExpired] = useState<boolean>(false)
//     const [isUpdatedetDOBPlace, setUpdatedetDOBPlace] = useState<boolean>(false)
//     const [isUpdateddpDOB, setUpdateddpDOB] = useState<boolean>(false)
//     const [isUpdatedrbGender, setUpdatedrbGender] = useState<boolean>(false)
//     const [isUpdatedrbMaritalStatus, setUpdatedrbMaritalStatus] = useState<boolean>(false)
//     const [isUpdatedetCoupleName, setUpdatedetCoupleName] = useState<boolean>(false)
//     const [isUpdatedetCoupleIdentityNumber, setUpdatedetCoupleIdentityNumber] = useState<boolean>(false)
//     const [isUpdatedetCoupleDOB, setUpdatedetCoupleDOB] = useState<boolean>(false)
//     const [isUpdatedetCouplePhone, setUpdatedetCouplePhone] = useState<boolean>(false)
//     const [isUpdatedetMomName, setUpdatedetMomName] = useState<boolean>(false)
//     const [isUpdatedetNumberofDependents, setUpdatedetNumberofDependents] = useState<boolean>(false)
//     const [isUpdatedrbPropertySeparationAgreement, setUpdatedrbPropertySeparationAgreement] = useState<boolean>(false)
//     const [isUpdatedetNPWP, setUpdatedetNPWP] = useState<boolean>(false)
//     const [isUpdateddpNPWPJoinDate, setUpdateddpNPWPJoinDate] = useState<boolean>(false)
//     const [isUpdatedetHomePhoneNumber, setUpdatedetHomePhoneNumber] = useState<boolean>(false)
//     // const [isUpdatedetFaxNumber, setUpdatedetFaxNumber] = useState<boolean>(false)
//     const [isUpdatedetPhoneNumber, setUpdatedetPhoneNumber] = useState<boolean>(false)
//     const [isUpdatedetEmailAddress, setUpdatedetEmailAddress] = useState<boolean>(false)
//     const [isUpdatedrbLastEducation, setUpdatedrbLastEducation] = useState<boolean>(false)
//     // SLIK
//     const [isUpdatedBailsmanName, setUpdatedBailsmanName] = useState<boolean>(false)
//     const [isUpdatedBailsmanDob, setUpdatedBailsmanDob] = useState<boolean>(false)


//     const [isHaveHomePhone, setHaveHomePhone] = useState(false)


//     //Personal Address
//     const [isUpdatedetFullAddress, setUpdatedetFullAddress] = useState<boolean>(false)
//     const [isUpdatedetNationallity, setUpdatedetNationallity] = useState<boolean>(false)
//     const [isUpdatedetProvince, setUpdatedetProvince] = useState<boolean>(false)
//     const [isUpdatedetCity, setUpdatedetCity] = useState<boolean>(false)
//     const [isUpdatedetDistrict, setUpdatedetDistrict] = useState<boolean>(false)
//     const [isUpdatedetUrban, setUpdatedetUrban] = useState<boolean>(false)

//     const [isUpdatedetPostalCode, setUpdatedetPostalCode] = useState<boolean>(false)
//     const [isUpdatedcbAddressMatch, setUpdatedcbAddressMatch] = useState<boolean>(false)

//     //Domisili Address
//     const [isUpdatedetFullAddressDom, setUpdatedetFullAddressDom] = useState<boolean>(false)
//     const [isUpdatedetNationallityDom, setUpdatedetNationallityDom] = useState<boolean>(false)
//     const [isUpdatedetProvinceDom, setUpdatedetProvinceDom] = useState<boolean>(false)
//     const [isUpdatedetCityDom, setUpdatedetCityDom] = useState<boolean>(false)
//     const [isUpdatedetDistrictDom, setUpdatedetDistrictDom] = useState<boolean>(false)
//     const [isUpdatedetUrbanDom, setUpdatedetUrbanDom] = useState<boolean>(false)

//     const [isUpdatedetPostalCodeDom, setUpdatedetPostalCodeDom] = useState<boolean>(false)

//     const [isUpdatedrbDomisilliStatus, setUpdatedrbDomisilliStatus] = useState<boolean>(false)

//     const [isUpdatedrbOccupation, setUpdatedrbOccupation] = useState<boolean>(false)
//     const [isUpdatedetKindOfBusiness, setUpdatedetKindOfBusiness] = useState<boolean>(false)
//     const [isUpdatedetNameOfBusiness, setUpdatedetNameOfBusiness] = useState<boolean>(false)
//     const [isUpdatedrbBusinessSector, setUpdatedrbBusinessSector] = useState<boolean>(false)

//     //Working Address
//     const [isUpdatedetCompanyAddress, setUpdatedetCompanyAddress] = useState<boolean>(false)
//     const [isUpdatedetCompanyCountry, setUpdatedetCompanyCountry] = useState<boolean>(false)
//     const [isUpdatedetCompanyProvince, setUpdatedetCompanyProvince] = useState<boolean>(false)
//     const [isUpdatedetCompanyCity, setUpdatedetCompanyCity] = useState<boolean>(false)
//     const [isUpdatedetCompanyDistrict, setUpdatedetCompanyDistrict] = useState<boolean>(false)
//     const [isUpdatedetCompanyUrban, setUpdatedetCompanyUrban] = useState<boolean>(false)

//     const [isUpdatedetCompanyPostalCode, setUpdatedetCompanyPostalCode] = useState<boolean>(false)
//     const [isUpdatedetOfficePhoneNumber, setUpdatedetOfficePhoneNumber] = useState<boolean>(false)
//     // const [isUpdatedetCompanyFaxNumber, setUpdatedetCompanyFaxNumber] = useState<boolean>(false)
//     const [isUpdatedetPosition, setUpdatedetPosition] = useState<boolean>(false)
//     const [isUpdatedetWorkingFor, setUpdatedetWorkingFor] = useState<boolean>(false)
//     const [isUpdatedetWorkingForMo, setUpdatedetWorkingForMo] = useState<boolean>(false)

//     const [isUpdatedrbFundSourceFrom, setUpdatedrbFundSourceFrom] = useState<boolean>(false)
//     const [isUpdatedrbFundPerMonth, setUpdatedrbFundPerMonth] = useState<boolean>(false)

//     const isAnyUnsavedUpdatesTab1 = () => {
//         return [
//             isUpdatedetFUllname, isUpdatedetKTPNumber, isUpdatedetKTPExpired, isUpdatedetDOBPlace, isUpdateddpDOB, isUpdatedrbGender, isUpdatedrbMaritalStatus, isUpdatedetCoupleName, isUpdatedetCoupleIdentityNumber, isUpdatedetCoupleDOB, isUpdatedetCouplePhone, isUpdatedetMomName, isUpdatedetNumberofDependents, isUpdatedrbPropertySeparationAgreement, isUpdatedetNPWP, isUpdateddpNPWPJoinDate, isUpdatedetHomePhoneNumber, isUpdatedetPhoneNumber, isUpdatedetEmailAddress, isUpdatedrbLastEducation,
//         ].includes(true);
//     }

//     const isAnyUnsavedUpdatesTab2 = () => {
//         return [
//             //Personal Address
//             isUpdatedetFullAddress, isUpdatedetNationallity, isUpdatedetProvince,
//             isUpdatedetCity, isUpdatedetDistrict, isUpdatedetUrban, isUpdatedetPostalCode, isUpdatedcbAddressMatch,

//             //Domisili Address
//             isUpdatedetFullAddressDom, isUpdatedetNationallityDom, isUpdatedetProvinceDom, isUpdatedetCityDom, isUpdatedetDistrictDom, isUpdatedetUrbanDom, isUpdatedetPostalCodeDom, isUpdatedrbDomisilliStatus,
//         ].includes(true);
//     }

//     const isAnyUnsavedUpdatesTab3 = () => {
//         return [
//             // SLIK
//             isUpdatedBailsmanName, isUpdatedBailsmanDob,

//             isUpdatedrbOccupation, isUpdatedetKindOfBusiness, isUpdatedetNameOfBusiness, isUpdatedrbBusinessSector,
//             //Working Address
//             isUpdatedetCompanyAddress, isUpdatedetCompanyCountry, isUpdatedetCompanyProvince, isUpdatedetCompanyCity, isUpdatedetCompanyDistrict, isUpdatedetCompanyUrban, isUpdatedetCompanyPostalCode,
//             isUpdatedetOfficePhoneNumber, isUpdatedetPosition, isUpdatedetWorkingFor, isUpdatedetWorkingForMo, isUpdatedrbFundSourceFrom, isUpdatedrbFundPerMonth
//         ].includes(true);
//     }

//     const isAnyUnsavedUpdates = () => {
//         return [isAnyUnsavedUpdatesTab1(), isAnyUnsavedUpdatesTab2(), isAnyUnsavedUpdatesTab3()].includes(true);
//     }

//     useEffect(() => {

//     }, [getBioCache])

//     const [whichPage, setPage] = useState(Page.LOGIN_PIN);
//     //
//     // useEffect(() => {
//     //     if(whichPage == Page.LOGIN_PIN) {
//     //         getAccType()
//     //     }
//     // }, )


//     const [getModalTitleMessage, setModalTitleMessage] = useState("");
//     const [getModalDescMessage, setModalDescMessage] = useState("");
//     const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);
//     const [isModalShowLink, setModalShowLink] = useState(false)

//     const [getEmailBackgroundColor, setEmailBackgroundColor] = useState("#F37F26");
//     const [getEmailIconColor, setEmailIconColor] = useState("#FFFFFF");
//     const [getEmailTextColor, setEmailTextColor] = useState("#FFFFFF");

//     const [getPhoneBackgroundColor, setPhoneBackgroundColor] = useState("#EDEDED");
//     const [getPhoneIconColor, setPhoneIconColor] = useState("#9E9E9E");
//     const [getPhoneTextColor, setPhoneTextColor] = useState("#9E9E9E");

//     const [loadingButton, setLoadingButton] = useState<boolean>(false)

//     const [loadingButtonPersonalData, setLoadingButtonPersonalData] = useState<boolean>(false)
//     const [loadingButtonAddressData, setLoadingButtonAddressData] = useState<boolean>(false)
//     const [loadingButtonWorkingData, setLoadingButtonWorkingData] = useState<boolean>(false)
//     const [loadingButtonFundSourceData, setLoadingButtonFundSourceData] = useState<boolean>(false)
//     const [loadingButtonNextMain, setLoadingButtonNextMain] = useState<boolean>(false)
//     const [loadingButtonResetData, setLoadingButtonResetData] = useState<boolean>(false)

//     const [getCellPhoneNumber, setCellPhoneNumber] = useState("")
//     const [getEmail, setEmail] = useState("")

//     const [isPersonalDataShow, setPersonalDataShow] = useState("collapse show")
//     const [isAddressDataShow, setAddressDataShow] = useState("collapse hide")
//     const [isWorkingDataShow, setWorkingDataShow] = useState("collapse hide")
//     const [isFundSourceDataShow, setFundSourceDataShow] = useState("collapse hide")

//     const [getActiveAccordion1, setActiveAccordion1] = useState("activeAccordion")
//     const [getActiveAccordion2, setActiveAccordion2] = useState("deactiveAccordion")
//     const [getActiveAccordion3, setActiveAccordion3] = useState("deactiveAccordion")

//     const [getItemBorder1, setItemBorder1] = useState("mainMenuItemNoBorder")
//     const [getItemBorder2, setItemBorder2] = useState("mainMenuItemWithBorder")
//     const [getItemBorder3, setItemBorder3] = useState("mainMenuItemWithBorder")
//     const [getItemBorder4, setItemBorder4] = useState("mainMenuItemWithBorder")

//     const ACC_SHOW = "activeAccordion"
//     const ACC_HIDE = "deactiveAccordion"

//     const COLL_SHOW = "collapse show"
//     const COLL_HIDE = "collapse hide"

//     const BORDER_SHOW = "mainMenuItemWithBorder"
//     const BORDER_HIDE = "mainMenuItemNoBorder"

//     const [isWni, setWni] = useState(true)
//     const [isHaveNPWP, setHaveNPWP] = useState(false)
//     const [isHaveNPWPWNA, setHaveNPWPWNA] = useState(false)
//     const [isWorking, setWorking] = useState(false)
//     const [isUpdateWorking, setUpdateWorking] = useState<boolean>(false)

//     const [getCurrentFileNPWP, setCurrentFileNPWP] = useState(null)
//     const [getCurrentFilePaspor, setCurrentFilePaspor] = useState(null)
//     const [getCurrentFileKITAS, setCurrentFileKITAS] = useState(null)
//     const [getCurrentFileNPWPWNA, setCurrentFileNPWPWNA] = useState("")

//     const [getPreviewImageKTP, setPreviewImageKTP] = useState("")
//     const [getPreviewImageNPWP, setPreviewImageNPWP] = useState("")
//     const [getPreviewImagePaspor, setPreviewImagePaspor] = useState("")
//     const [getPreviewImageKITAS, setPreviewImageKITAS] = useState("")
//     const [getPreviewImageNPWPWNA, setPreviewImageNPWPWNA] = useState("")

//     const [getKTPFullName, setKTPFullName] = useState("");
//     const [getKTPNo, setKTPNo] = useState("");
//     const [getKTPExp, setKTPExp] = useState("");
//     const [getKTPBirthPlace, setKTPBirthPlace] = useState("");
//     const [getKTPBirthDate, setKTPBirthDate] = useState("");
//     const [getKTP, setKTP] = useState("");
//     const [getNpwpNo, setNpwpNo] = useState(null);

//     const [isKTPViewedPH, setKTPViewedPH] = useState("d-show")
//     const [isKTPViewedIMG, setKTPViewedIMG] = useState("d-none")

//     const [isNPWPViewedPH, setNPWPViewedPH] = useState("d-show")
//     const [isNPWPViewedIMG, setNPWPViewedIMG] = useState("d-none")

//     const [isPasporViewedPH, setPasporViewedPH] = useState("d-show")
//     const [isPasporViewedIMG, setPasporViewedIMG] = useState("d-none")

//     const [isKitasViewedPH, setKitasViewedPH] = useState("d-show")
//     const [isKitasViewedIMG, setKitasViewedIMG] = useState("d-none")

//     const [isNPWPWNAViewedPH, setNPWPWNAViewedPH] = useState("d-show")
//     const [isNPWPWNAViewedIMG, setNPWPWNAViewedIMG] = useState("d-none")

//     const [isCacheFilled, setCacheFilled] = useState(false)

//     //Revamp Card Date
//     const [getPassporExp, setPassporExp] = useState(null)
//     const [getKitasExp, setKitasExp] = useState(null)

//     //Revamp Main Date
//     const [getCidExpMain, setCidExpMain] = useState("");

//     //useEffect(() => { }, [getCidExpMain])

//     const [getDob, setDob] = useState(new Date());
//     const [getSpDob, setSpDob] = useState(new Date());
//     const [getNPWPRegisteredAt, setNPWPRegisteredAt] = useState(null);

//     const [getDobBailsman, setDobBailsman] = useState(new Date());


//     //Radio Button State

//     //PropertySeparationAgreement
//     const [isPropertySeparationAgreement, setPropertySeparationAgreement] = useState(true)

//     //DomesticStatus
//     const [isDomestic, setDomestic] = useState(true)

//     //Gender Start
//     const [getGender] = useState([{"id":"M", "name":"Laki-laki", "eng": "Male"}, {"id":"F", "name":"Perempuan", "eng":"Female"}])
//     //Gender End
//     const [getSelectedPositionGender, setSelectedPositionGender] = useState(0)

//     //Marital Status start
//     const [getMaritalStatus] = useState(
//         [
//             {"id": "1", "name": "Belum Menikah", "eng": "Single"},
//             {"id": "2", "name": "Menikah", "eng": "Maried"},
//             {"id": "3", "name": "Janda", "eng": "Widow"},
//             {"id": "4", "name": "Duda", "eng": "Widower"}
//         ])
//     const [getSelectedPositionMarital, setSelectedPositionMarital] = useState(0)
//     //Marital Status end

//     //Educational Background Start
//     const [getEducationalBackground] = useState(
//         [
//             {"id": "2", "name": "SD", "eng": "Elementary School"},
//             {"id": "3", "name": "SMP", "eng": "Junior High School"},
//             {"id": "4", "name": "SMA/D1/D2", "eng": "High school"},
//             {"id": "5", "name": "D3", "eng": "Diploma"},
//             {"id": "6", "name": "S1", "eng": "Bachelor"},
//             {"id": "7", "name": "S2", "eng": "Master"},
//             {"id": "8", "name": "S3", "eng": "Postdoc"}
//         ])
//     const [getSelectedEducationalBackground, setSelectedEducationalBackground] = useState(0)

//     const [isOpenModalRejectRevamp, setOpenModalRejectRevamp] = useState(false)

//     interface ListMock {
//         readonly value: string;
//         readonly label: string;
//     }

//     const getWorkPositionList: ListMock[] = [
//         {"label":"Owner","value":"0"},
//         {"label":"Direktur/Komisaris","value":"1"},
//         {"label":"Manager","value":"2"},
//         {"label":"Staff","value":"3"},
//         {"label":"Lainnya","value":"4"}
//     ]

//     const [getSelectedWorkPosition, setSelectedWorkPosition]  = useState([{"label":"","value":""}])

//     const getAccType = () => {
//         const timeStamp = (new Date()).toISOString();
//         axios.post(
//             MYURL.GET_USER_TYPE,
//             {
//                 userId: Storage.local.get('username'),
//                 value: "info",
//                 key: MyEncrypt(({code: MySecKey(), timestamp: timeStamp, id: Storage.local.get('username')})),
//                 timestamp: timeStamp
//             },
//             {headers: {"Content-Type": "application/json"}}
//         ).then(res => {
//             if (isCacheFilled == false && (res.data.acntType).includes("Regular")) {
//                 setPage(Page.LOGIN_PIN)
//             } else {
//                 setOpenModalRejectRevamp(true)
//             }
//         });
//     }

//     const getEducationalName = (param) => {
//         var found = ""
//         for (let i = 0; i < getEducationalBackground.length; i++) {
//             if (getEducationalBackground[i].id == param) {
//                 found = getEducationalBackground[i].name
//             }
//         }

//         return found
//     }

//     //Educational Background End

//     //separation status start
//     const [getSeparationAgreement] = useState(
//         [
//             {"id": "1", "name": "Ya", "eng": "Yes"},
//             {"id": "2", "name": "Tidak", "eng": "No"}
//         ]
//     )
//     const [getSelectedSeparationAgreement, setSelectedSeparationAgreement] = useState(0)

//     //separation status end

//     //Working Background Start
//     const [getWorkingPlace] = useState(
//         [
//             {"id": "06", "name": "Pegawai Negeri Sipil", "eng": "Government Officer"},
//             {"id": "03", "name": "Pegawai Swasta", "eng": "Private Employee"},
//             {"id": "07", "name": "TNI/POLRI", "eng": "Armed Force/Police"},
//             {"id": "10", "name": "Wirausaha", "eng": "Entrepreneur"}, //Wirausaha dipilih maka muncul jenis usaha, akan tetapi kalau selain wirausaha jenis usaha tidak muncul
//             {"id": "01", "name": "Pelajar/Mahasiswa", "eng": "Student"},
//             {"id": "05", "name": "Ibu Rumah Tangga", "eng": "Housewife"},
//             {"id": "08", "name": "Pensiunan", "eng": "Retiree"},

//             {"id": "991", "name": "BUMN/BUMD", "eng": "Governmental Corporation"},
//             {"id": "992", "name": "Politikus", "eng": "Politician"},
//             {"id": "993", "name": "Dosen/Pengajar", "eng": "Teacher/Educator"},
//             {"id": "994", "name": "Profesional", "eng": "Professional"},
//             {"id": "995", "name": "Pengurus Yayasan, Koperasi, dan Keagamaan", "eng": "Foundation Activist, Trade Union, and Religious Community"},
//             {"id": "997", "name": "Trader Saham", "eng": "Stock Trader"}
//         ])
//     const [getSelectedWorkingPlace, setSelectedWorkingPlace] = useState(0)
//     //Working Background End

//     const getBusinessSectorTranslate = {
//         "Perusahaan Terbatas": "Limited Company",
//         "Asuransi": "Insurance",
//         "Perusahaan Sekuritas": "Securities (Stock Broker)",
//         "Yayasan": "Foundation",
//         "Reksa dana": "Mutual Fund",
//         "Pemerintahan": "Governmental",
//         "Institusi Keuangan": "Economical Institution",
//         "Pengelola Dana Pensiun": "Retirement ",
//         "Kesehatan": "Health",
//         "Transportasi": "Transportation",
//         "Pertanian": "Agriculture",
//         "Toko online": "Online Store",
//         "Retail dan eceran": "Retail",
//         "Pendidikan": "Education",
//         "Pariwisata": "Tourism",
//         "Perdagangan": "Trade",
//         "Perikanan": "Fishery",
//         "Koperasi": "Trade Union",
//         "Telekomunikasi": "Telecomunication",
//         "Pertambangan": "Mining",
//         "Manufaktur": "Manufacture",
//         "Pers/Media": "Media/Press",
//         "Property": "Property",
//         "Hukum dan Konsultan": "Law and Consultants",
//         "Jasa": "Service",
//         "Kontraktur/Konstruksi": "Contractor/Construction"
//     }

//     //BusinessSector Start
//     const [getBusinessSector] = useState(
//         [
//             {"id": "1", "name": "Perusahaan Terbatas", "enabled": true},
//             {"id": "2", "name": "Asuransi", "enabled": true},
//             {"id": "3", "name": "Perusahaan Sekuritas", "enabled": true},
//             {"id": "4", "name": "Yayasan", "enabled": true},
//             {"id": "5", "name": "Reksa dana", "enabled": true},
//             {"id": "6", "name": "Pemerintahan", "enabled": true},
//             {"id": "7", "name": "Institusi Keuangan", "enabled": true},
//             {"id": "8", "name": "Pengelola Dana Pensiun", "enabled": true},

//             //order fund source
//             {"id": "9", "name": "Kesehatan", "enabled": true},
//             {"id": "10", "name": "Transportasi", "enabled": true},
//             {"id": "11", "name": "Pertanian", "enabled": true},
//             {"id": "12", "name": "Toko online", "enabled": true},
//             {"id": "13", "name": "Retail dan eceran", "enabled": true},
//             {"id": "14", "name": "Pendidikan", "enabled": true},
//             {"id": "15", "name": "Pariwisata", "enabled": true},
//             {"id": "16", "name": "Perdagangan", "enabled": true},
//             {"id": "17", "name": "Perikanan", "enabled": true},
//             {"id": "18", "name": "Koperasi", "enabled": true},
//             {"id": "19", "name": "Telekomunikasi", "enabled": true},
//             {"id": "20", "name": "Pertambangan", "enabled": true},
//             {"id": "21", "name": "Manufaktur", "enabled": true},
//             {"id": "22", "name": "Pers/Media", "enabled": true},
//             {"id": "23", "name": "Property", "enabled": true},
//             {"id": "24", "name": "Hukum dan Konsultan", "enabled": true},
//             {"id": "25", "name": "Jasa", "enabled": true},
//             {"id": "26", "name": "Kontraktor/Konstruksi", "enabled": true},
//             {"id": "27", "name": "", "enabled": true}
//         ])
//     const [getSelectedBusinessSector, setSelectedBusinessSector] = useState(0)
//     //Working Background End

//     //AssetSource Start
//     // const [getFundSource, setFundSource] = useState([
//     //     {"id": "02", "name": "Gaji", "enabled": true},
//     //     {"id": "05", "name": "Warisan", "enabled": false},
//     //     {"id": "08", "name": "Dana Pensiun", "enabled": false},
//     //     {"id": "11", "name": "Deposito", "enabled": false},
//     //     {"id": "07", "name": "Pasangan", "enabled": false},
//     //     {"id": "06", "name": "Orang Tua/Anak", "enabled": false},
//     //     {"id": "13", "name": "Pinjaman", "enabled": false},
//     //     {"id": "03", "name": "Laba Usaha", "enabled": false},
//     //     {"id": "02", "name": "Hasil Investasi", "enabled": false},
//     //     {"id": "04", "name": "Pendapatan Bunga", "enabled": false},
//     //     {"id": "09", "name": "Hadiah Undian", "enabled": false},
//     //     {"id": "12", "name": "Modal", "enabled": false}
//     // ])

//     //AssetSource Start
//     const [getFundSource, setFundSource] = useState([
//         {"id": "01", "name": "Gaji", "enabled": true, "eng": "Salary"},
//         {"id": "07", "name": "Warisan", "enabled": true, "eng": "Legacy"},
//         {"id": "05", "name": "Dana Pensiun", "enabled": true, "eng": "Pension Fund"},
//         {"id": "06", "name": "Deposito", "enabled": true, "eng": "Deposito"},
//         {"id": "11", "name": "Pasangan", "enabled": true, "eng": "Partner"},
//         {"id": "03", "name": "Orang Tua/Anak", "enabled": true, "eng": "Parent/Child"},
//         {"id": "13", "name": "Pinjaman", "enabled": true, "eng": "Loan"},
//         {"id": "02", "name": "Laba Usaha", "enabled": true, "eng": "Business Profit"},
//         {"id": "08", "name": "Hasil Investasi", "enabled": true, "eng": "Investment Return"},
//         {"id": "09", "name": "Pendapatan Bunga", "enabled": true, "eng": "Interest Earning"},
//         {"id": "10", "name": "Hadiah Undian", "enabled": true, "eng": "Sweeptakes Prize"},
//         {"id": "12", "name": "Modal", "enabled": true, "eng": "Capital"}
//     ])

//     //IncomePerMonth Start
//     const [getIncomePerMonth] = useState([
//         {"id": "02", "name": "Rp 1 Juta - Rp 4 Juta"},
//         {"id": "03", "name": "Rp 4 Juta - Rp 9 Juta"},
//         {"id": "04", "name": "Rp 9 Juta - Rp 40 Juta"},
//         {"id": "05", "name": "Rp 40 Juta - Rp 90 Juta"},
//         {"id": "06", "name": ">Rp 90 Juta"}
//     ])

//     const [getSelectedFundSource, setSelectedFundSource] = useState(0)

//     const [getSelectedIncomePerMonth, setSelectedIncomePerMonth] = useState(0)


//     interface ListMock {
//         readonly value: string;
//         readonly label: string;
//     }
//     interface ListMockUrban {
//         readonly value: string;
//         readonly label: string;
//         readonly postal: string;
//     }

//     //For Verification Card List
//     var dataCitizenshipList: ListMock[] = []
//     const [getCzList, setCzList] = useState([])
//     const [getSelectedCzCd, setSelectedCzCd] = useState("")


//     //Address
//     var dataCountryList: ListMock[] = []
//     const [getCountryList, setCountryList] = useState([])
//     const [getSelectedCountryCd, setSelectedCountryCd] = useState([])

//     var dataProvinceList: ListMock[] = []
//     const [getProvinceList, setProvinceList] = useState([])
//     const [getSelectedProvinceCd, setSelectedProvinceCd] = useState([])

//     var dataCityList: ListMock[] = []
//     const [getCityList, setCityList] = useState([])
//     const [getSelectedCityCd, setSelectedCityCd] = useState([])

//     var dataDistrictList: ListMock[] = []
//     const [getDistrictList, setDistrictList] = useState([])
//     const [getSelectedDistrictCd, setSelectedDistrictCd] = useState([])

//     var dataUrbanList: ListMockUrban[] = []
//     const [getUrbanList, setUrbanList] = useState([])
//     const [getSelectedUrbanCd, setSelectedUrbanCd] = useState([])

//     //Address Dom
//     var dataCountryListDom: ListMock[] = []
//     const [getSelectedCountryCdDom, setSelectedCountryCdDom] = useState([])

//     var dataProvinceListDom: ListMock[] = []
//     const [getProvinceListDom, setProvinceListDom] = useState([])
//     const [getSelectedProvinceCdDom, setSelectedProvinceCdDom] = useState([])

//     var dataCityListDom: ListMock[] = []
//     const [getCityListDom, setCityListDom] = useState([])
//     const [getSelectedCityCdDom, setSelectedCityCdDom] = useState([])

//     var dataDistrictListDom: ListMock[] = []
//     const [getDistrictListDom, setDistrictListDom] = useState([])
//     const [getSelectedDistrictCdDom, setSelectedDistrictCdDom] = useState([])

//     var dataUrbanListDom: ListMockUrban[] = []
//     const [getUrbanListDom, setUrbanListDom] = useState([])
//     const [getSelectedUrbanCdDom, setSelectedUrbanCdDom] = useState([])


//     //Address Work
//     var dataCountryListWork: ListMock[] = []
//     const [getSelectedCountryCdWork, setSelectedCountryCdWork] = useState([])

//     var dataProvinceListWork: ListMock[] = []
//     const [getProvinceListWork, setProvinceListWork] = useState([])
//     const [getSelectedProvinceCdWork, setSelectedProvinceCdWork] = useState([])

//     var dataCityListWork: ListMock[] = []
//     const [getCityListWork, setCityListWork] = useState([])
//     const [getSelectedCityCdWork, setSelectedCityCdWork] = useState([])

//     var dataDistrictListWork: ListMock[] = []
//     const [getDistrictListWork, setDistrictListWork] = useState([])
//     const [getSelectedDistrictCdWork, setSelectedDistrictCdWork] = useState([])

//     var dataUrbanListWork: ListMockUrban[] = []
//     const [getUrbanListWork, setUrbanListWork] = useState([])
//     const [getSelectedUrbanCdWork, setSelectedUrbanCdWork] = useState([])


//     const [getCbDisclaimer, setCbDisclaimer] = useState(false);

//     let [getChangedData, setChangedData] = useState([])

//     const [isLoadingCity, setLoadingCity] = useState(false)
//     const [isLoadingDistrict, setLoadingDistrict] = useState(false)
//     const [isLoadingVillage, setLoadingVillage] = useState(false)

//     const [isLoadingCityDom, setLoadingCityDom] = useState(false)
//     const [isLoadingDistrictDom, setLoadingDistrictDom] = useState(false)
//     const [isLoadingVillageDom, setLoadingVillageDom] = useState(false)

//     const [isLoadingCityWork, setLoadingCityWork] = useState(false)
//     const [isLoadingDistrictWork, setLoadingDistrictWork] = useState(false)
//     const [isLoadingVillageWork, setLoadingVillageWork] = useState(false)

//     const [isEditHiddenCity, setEditHiddenCity] = useState(false)
//     const [isEditHiddenDistrict, setEditHiddenDistrict] = useState(false)
//     const [isEditHiddenVillage, setEditHiddenVillage] = useState(false)

//     const [isEditHiddenCityDom, setEditHiddenCityDom] = useState(false)
//     const [isEditHiddenDistrictDom, setEditHiddenDistrictDom] = useState(false)
//     const [isEditHiddenVillageDom, setEditHiddenVillageDom] = useState(false)

//     const [isEditHiddenCityWork, setEditHiddenCityWork] = useState(false)
//     const [isEditHiddenDistrictWork, setEditHiddenDistrictWork] = useState(false)
//     const [isEditHiddenVillageWork, setEditHiddenVillageWork] = useState(false)


//     const censoredEmail = () => {
//         if (getEmail != null && getEmail != "") {
//             var tempCensoredEmail = getEmail.trim()
//             if (tempCensoredEmail != null && tempCensoredEmail != "") {
//                 const middleLengthStart = tempCensoredEmail.split('@')[0].length - 4;
//                 var censorCharacter = "****";
//                 return tempCensoredEmail.substring(0, middleLengthStart) + censorCharacter + tempCensoredEmail.substring(middleLengthStart + censorCharacter.length);
//             } else {
//                 return ""
//             }
//         }
//     }

//     const censoredPhone = () => {
//         if (getCellPhoneNumber != null && getCellPhoneNumber != "") {
//             var tempCensoredPhone = getCellPhoneNumber.trim()
//             if (tempCensoredPhone != null && tempCensoredPhone != "") {
//                 const middleLengthStart = tempCensoredPhone.length - 8;
//                 var censorCharacter = "****";
//                 return tempCensoredPhone.substring(0, middleLengthStart) + censorCharacter + tempCensoredPhone.substring(middleLengthStart + censorCharacter.length);
//             } else {
//                 return ""
//             }
//         }
//     }

//     const tabChange = function (val: number) {
//         // if(isEnableTabChange) {
//         try {
//             if (whichPage == Page.LOGIN_PIN) {
//                 const pinValues = loginPINState.values.etPIN1 + loginPINState.values.etPIN2 + loginPINState.values.etPIN3 +
//                     loginPINState.values.etPIN4 + loginPINState.values.etPIN5 + loginPINState.values.etPIN6

//                 if (pinValues.length == 6) {
//                     handleLoginPIN(pinValues)
//                 }
//             }

//             let ele = document.querySelectorAll('input');
//             if (ele[val - 1].value != '') {
//                 ele[val].focus()
//             } else if (ele[val - 1].value == '') {
//                 ele[val - 2].focus()
//             }
//         } catch (e) {

//         }
//         // }
//     }

//     const resetFocusLoginPIN = () => {
//         // setEnableChange(false)
//         // loginPINState.resetForm()
//         //
//         // loginPINState.values.etPIN6 =""
//         // loginPINState.values.etPIN5 =""
//         // loginPINState.values.etPIN4 =""
//         // loginPINState.values.etPIN3 =""
//         // loginPINState.values.etPIN2 =""
//         // loginPINState.values.etPIN1 =""
//     }

//     const smoothScrollTo = (elementid: string) => {
//         var element = document.getElementById(elementid)
//         var headerOffset = 70;
//         var elementPosition = element.getBoundingClientRect().top;
//         var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

//         window.scrollTo({
//             top: offsetPosition,
//             behavior: "smooth"
//         })
//     }

//     const handleCountryList = (param) => {
//         Axios.get(MYURL.COUNTRY_LIST, {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 res.data.forEach((it, index) => {
//                     if (index == 0) {
//                         formCardVerification.values.etVerifCardCountryLabel = it.cntrNm
//                     }
//                     dataCountryList.push(
//                         {
//                             "label": it.cntrNm,
//                             "value": it.cntrCd
//                         }
//                     )
//                 })
//                 setCountryList(dataCountryList)
//                 handleGetProvince(param)
//             }
//         )
//     }

//     const handleCitizenshipList = () => {
//         Axios.get(MYURL.CITIZENSHIP_LIST, {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 res.data.forEach((it, index) => {
//                     if (index == 0) {
//                         formCardVerification.values.etVerifCardCountryLabel = it.ctznNm
//                     }
//                     dataCitizenshipList.push(
//                         {
//                             "label": it.ctznNm,
//                             "value": it.ctznCd
//                         }
//                     )
//                 })
//                 setCzList(dataCitizenshipList)
//             }
//         )
//     }

//     const handleNpwpExistingData = () => {
//         Axios.get(MYURL.EXISTING_NPWP, {headers: {"Content-Type": "application/json"}})
//             .then(
//                 res => {
//                     if (res.status == 200) {
//                         setHaveNPWP(res.data.investorNPWPNumber != null);
//                         setWorking(res.data.investorOtherCountryCode == "112");
//                         formCardVerification.values.etIsWorking = (res.data.investorOtherCountryCode == "112");

//                         if (res.data.investorNPWPNumber != null) {
//                             formCardVerification.values.npwpNo = res.data.investorNPWPNumber;
//                             setNpwpNo(res.data.investorNPWPNumber);
//                             if (res.data.investorNPWPRegistrationDate != null) {
//                                 formCardVerification.values.npwpIssued = res.data.investorNPWPRegistrationDate;
//                                 setNPWPRegisteredAt(toDateHots(res.data.investorNPWPRegistrationDate));
//                             }
//                         }
//                     }
//                 }
//             )
//     }

//     const handleGetUser = () => {
//         const timeStamp = (new Date()).toISOString()

//         const bodyApi = {
//             userId: Storage.local.get('username'),
//             value: "all",
//             key: MyEncrypt(({code: MySecKey(), timestamp: timeStamp, id: Storage.local.get('username')})),
//             timestamp: timeStamp
//         };

//         Axios.post(MYURL.GET_USER_CONTACT,
//             bodyApi,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 if (res.status == 200) {

//                     if (res.data.email != null && res.data.email != "" && res.data.mobile != null && res.data.mobile) {
//                         setEmail(res.data.email)
//                         setCellPhoneNumber(res.data.mobile)
//                     } else {
//                         setLoadingButton(false)
//                         setModalIllustrationStatus(false);
//                         setModalTitleMessage(LANG().FAILED);
//                         setModalDescMessage(LANG().EMPTY_NO_OR_EMAIL);
//                         setOpenModalLoginPIN(true)
//                     }
//                 } else {
//                     setLoadingButton(false)
//                     setModalIllustrationStatus(false);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
//                     setOpenModalLoginPIN(true)
//                 }
//             }
//         ).catch(
//             res => {
//                 setLoadingButton(false)
//                 setModalIllustrationStatus(false);
//                 setModalTitleMessage(LANG().FAILED);
//                 setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
//                 setOpenModalLoginPIN(true)
//             })
//     }

//     const handleLoginPIN = (pin: string) => {
//         const bodyApi = {
//             userId: Storage.local.get('username'),
//             pin: pin
//         };

//         setLoadingButton(true)

//         Axios.post(MYURL.VERIFY_PIN,
//             bodyApi,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 if (res.status != 200) {
//                     setLoadingButton(false)
//                     setModalIllustrationStatus(false);
//                     setOpenModalLoginPIN(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         )
//             .then(r => {
//                 handleGetUser()
//             })
//             .then(r => {
//                 handleCitizenshipList()
//             })
//             .then(r => {
//                 handleNpwpExistingData()
//             })
//             .catch(
//                 res => {
//                     if(res.status >=500){
//                         setLoadingButton(false)
//                         setModalIllustrationStatus(false);
//                         setOpenModalLoginPIN(true);
//                         setModalTitleMessage(LANG().FAILED);
//                         setModalDescMessage("Terjadi kesalahan jaringan, mohon coba kembali.");
//                     } else {
//                         setLoadingButton(false)
//                         setModalIllustrationStatus(false);
//                         setOpenModalLoginPIN(true);
//                         setModalTitleMessage(LANG().FAILED);
//                         setModalDescMessage("" + res.response.data.message);
//                     }

//                 });
//     }

//     const refreshAccordionDisplay = () => {
//         setActiveAccordion1(ACC_HIDE)
//         setActiveAccordion2(ACC_HIDE)
//         setActiveAccordion3(ACC_HIDE)

//         setPersonalDataShow(COLL_HIDE)
//         setAddressDataShow(COLL_HIDE)
//         setWorkingDataShow(COLL_HIDE)
//         setFundSourceDataShow(COLL_HIDE)

//         setItemBorder1(BORDER_SHOW)
//         setItemBorder2(BORDER_SHOW)
//         setItemBorder3(BORDER_SHOW)
//         setItemBorder4(BORDER_SHOW)
//     }

//     const handleCardVerification = (file: any) => {

//         setShowKTPSpinner(true)
//         setKTPViewedPH("d-none")
//         setKTPViewedIMG("d-none")

//         if (isWni) {
//             const data = new FormData();
//             data.append("citizenship", "INDONESIAN")
//             data.append("country", "INDONESIA")
//             data.append("ktp_pic", file);
//             if (isHaveNPWP) {
//                 data.append("npwp_pic", getCurrentFileNPWP);
//             }
//             setShowKTPSpinner(true)
//             Axios.post(MYURL.OCR_CARD,
//                 data, {headers: {"Content-Type": "application/json"}})
//                 .then(res => {
//                     setShowKTPSpinner(false)
//                     if (res.status == 200) {
//                         formCardVerification.values.etKTP = res.data.message

//                         setKTPViewedPH("d-none")
//                         setKTPViewedIMG("d-show")
//                         let birthPlaceBirthday = res.data.birthPlaceBirthday;
//                         setKTPFullName(res.data.name)
//                         setKTPExp(res.data.expiryDate)
//                         setKTPBirthPlace(birthPlaceBirthday?.split(",")[0])
//                         setKTPBirthDate(birthPlaceBirthday?.split(",")[1].trim())
//                         setKTP(res.data.message)

//                         formCardVerification.handleChange({
//                             target: {
//                                 name: "fileKTP",
//                                 value: file
//                             }
//                         })

//                     } else {
//                         setOpenModalRevampCard(true)
//                         setModalIllustrationStatus(true)
//                         setModalTitleMessage(LANG().SUCCESS)
//                         setModalDescMessage(res.data.message)

//                         setKTPViewedPH("d-show")
//                         setKTPViewedIMG("d-none")
//                     }
//                 })
//                 .catch(res => {
//                     setShowKTPSpinner(false)
//                     setOpenModalRevampCard(true)
//                     setModalIllustrationStatus(false)
//                     setModalTitleMessage(LANG().FAILED)
//                     setModalDescMessage(res.response.status == 400 ? res.response.data.message : LANG().DATA_INVALID)

//                     setKTPViewedPH("d-show")
//                     setKTPViewedIMG("d-none")
//                 });

//         } else {
//             const data = new FormData();
//             data.append("citizenship", "OTHER");
//             data.append("country", formCardVerification.values.etVerifCardCountryLabel);

//             data.append("pass_pic", getCurrentFilePaspor);
//             if (isWorking) {
//                 data.append("npwp_pic", getCurrentFileNPWPWNA);
//                 data.append("kitas_pic", getCurrentFileKITAS);
//             }

//             setLoadingButton(true)

//             Axios.post(MYURL.VERIFICATION_CARD,
//                 {headers: {"Content-Type": "application/json"}})
//                 .then(res => {
//                     setLoadingButton(false)
//                     console.log("VERIFICATION_CARD: " + res.status.toString())
//                 })
//                 .catch(res => {
//                     setLoadingButton(false)
//                     console.log("VERIFICATION_CARD: " + res.response.status)
//                 });
//         }
//     }


//     //Province
//     const handleGetProvince = (param) => {

//         Axios.get(MYURL.PROVINCE_LIST, {headers: {"Content-Type": "application/json"}})
//             .then(res => {
//                 if (res.status == 200) {
//                     res.data.forEach((it, index) => {

//                         dataProvinceList.push(
//                             {
//                                 "label": it.prpsNm,
//                                 "value": it.prpsCd
//                             }
//                         )
//                     })

//                     setProvinceList(dataProvinceList)

//                     handleGetAllAddrDropdowns(param)

//                     // handleGetCity(param)

//                 } else {
//                     setLoadingButton(false)
//                     setOpenModalRevampCard(true)
//                     setModalIllustrationStatus(false)
//                     setModalTitleMessage(LANG().FAILED)
//                     setModalDescMessage(res.data.message)
//                 }
//             }).catch(res => {
//             setLoadingButton(false)
//             setOpenModalRevampCard(true)
//             setModalIllustrationStatus(false)
//             setModalTitleMessage(LANG().FAILED)
//             setModalDescMessage(LANG().DATA_INVALID)
//         })
//     }


//     //Address

//     const errOnAddrRet = () => {
//         setLoadingButton(false)
//         setOpenModalRevampCard(true)
//         setModalIllustrationStatus(false)
//         setModalTitleMessage(LANG().FAILED)
//     }

//     const getUrlMultiAddrRet = (prpsCd, cityCd, districtCd) => (MYURL.MULTI_REG_LIST + "?" + "prpsCd=" + prpsCd + "&cityCd=" + cityCd + "&districtCd=" + districtCd)

//     const handleGetAllAddrDropdowns = (param) => {
//         Axios.get(getUrlMultiAddrRet(param.investorPrpsCd, param.investorCityCd, param.investorDistrictCd), {headers: {"Content-Type": "application/json"}})
//             .then(res => {
//                 if (res.status == 200) {
//                     JSON.parse(res.data.cities).map(it => {
//                         dataCityList.push({"label": it.cityNm, "value": it.cityCd})
//                     })
//                     setCityList(dataCityList)
//                     JSON.parse(res.data.districts).map(it => {
//                         dataDistrictList.push({"label": it.districtNm, "value": it.districtCd})
//                     })
//                     setDistrictList(dataDistrictList)
//                     JSON.parse(res.data.urbans).map(it => {
//                         dataUrbanList.push({"label": it.urbanNm, "value": it.urbanCd, "postal": it.postalCode})
//                     })
//                     setUrbanList(dataUrbanList)

//                     handleGetAllAddrDropdownsDom(param)
//                 } else {
//                     errOnAddrRet()
//                     setModalDescMessage(res.data.message)
//                 }
//             })
//             .catch(res => {
//                 errOnAddrRet()
//                 setModalDescMessage(LANG().DATA_INVALID)
//             })
//     }

//     const handleGetAllAddrDropdownsDom = (param) => {
//         if (param.investorPrpsCd == param.investorOtherPrpsCd
//             && param.investorCityCd == param.investorOtherCityCd
//             && param.investorDistrictCd == param.investorOtherDistrictCd
//         ) {
//             setCityListDom(dataCityList)
//             setDistrictListDom(dataDistrictList)
//             setUrbanListDom(dataUrbanList)
//             handleGetAllAddrDropdownsWork(param)
//         } else {
//             Axios.get(getUrlMultiAddrRet(param.investorOtherPrpsCd, param.investorOtherCityCd, param.investorOtherDistrictCd), {headers: {"Content-Type": "application/json"}})
//                 .then(res => {
//                     if (res.status == 200) {
//                         JSON.parse(res.data.cities).map(it => {
//                             dataCityListDom.push({"label": it.cityNm, "value": it.cityCd})
//                         })
//                         setCityListDom(dataCityListDom)
//                         JSON.parse(res.data.districts).map(it => {
//                             dataDistrictListDom.push({"label": it.districtNm, "value": it.districtCd})
//                         })
//                         setDistrictListDom(dataDistrictListDom)
//                         JSON.parse(res.data.urbans).map(it => {
//                             dataUrbanListDom.push({"label": it.urbanNm, "value": it.urbanCd, "postal": it.postalCode})
//                         })
//                         setUrbanListDom(dataUrbanListDom)

//                         handleGetAllAddrDropdownsWork(param)
//                     } else {
//                         errOnAddrRet()
//                         setModalDescMessage(res.data.message)
//                     }
//                 })
//                 .catch(res => {
//                     errOnAddrRet()
//                     setModalDescMessage(LANG().DATA_INVALID)
//                 })
//         }
//     }

//     const handleGetAllAddrDropdownsWork = (param) => {
//         setLoadingButton(false)
//         Axios.get(getUrlMultiAddrRet(param.investorWorkPrpsCd, param.investorWorkCityCd, param.investorWorkDistrictCd), {headers: {"Content-Type": "application/json"}})
//             .then(res => {
//                     if (res.status == 200) {
//                         JSON.parse(res.data.cities).map(it => {
//                             dataCityListWork.push({"label": it.cityNm, "value": it.cityCd})
//                         })
//                         setCityListWork(dataCityListWork)
//                         JSON.parse(res.data.districts).map(it => {
//                             dataDistrictListWork.push({"label": it.districtNm, "value": it.districtCd})
//                         })
//                         setDistrictListWork(dataDistrictListWork)
//                         JSON.parse(res.data.urbans).map(it => {
//                             dataUrbanListWork.push({"label": it.urbanNm, "value": it.urbanCd, "postal": it.postalCode})
//                         })
//                         setUrbanListWork(dataUrbanListWork)

//                         if (param.from != "save_cache" || param.from == "first_initial") {
//                             if (param.from == "first_initial") {
//                                 setCacheFilled(true)
//                             }
//                             setPage(Page.REVAMP_MAIN)
//                         }
//                     } else {
//                         errOnAddrRet()
//                         setModalDescMessage(res.data.message)
//                     }
//                 }
//             ).catch(res => {
//             errOnAddrRet()
//             setModalDescMessage(LANG().DATA_INVALID)
//         })
//     }


//     const refreshUpdatedEditCancel = () => {
//         setUpdatedetFUllname(false)
//         setUpdatedetKTPNumber(false)
//         setUpdatedetKTPExpired(false)
//         setUpdatedetDOBPlace(false)
//         setUpdateddpDOB(false)
//         setUpdatedetKindOfBusiness(false)
//         setUpdatedrbGender(false)
//         setUpdatedrbMaritalStatus(false)
//         setUpdatedetCoupleName(false)
//         setUpdatedetCoupleIdentityNumber(false)
//         setUpdatedetCoupleDOB(false)
//         setUpdatedetCouplePhone(false)
//         setUpdatedetMomName(false)
//         setUpdatedetNumberofDependents(false)
//         setUpdatedrbPropertySeparationAgreement(false)
//         setUpdatedetNPWP(false)
//         setUpdateWorking(false)
//         setUpdateddpNPWPJoinDate(false)
//         setUpdatedetHomePhoneNumber(false)
//         // setUpdatedetFaxNumber(false)
//         setUpdatedetPhoneNumber(false)
//         setUpdatedetEmailAddress(false)
//         setUpdatedrbLastEducation(false)
//         setUpdatedetFullAddress(false)
//         setUpdatedetNationallity(false)
//         setUpdatedetProvince(false)
//         setUpdatedetCity(false)
//         setUpdatedetDistrict(false)
//         setUpdatedetUrban(false)
//         setUpdatedetPostalCode(false)
//         setUpdatedetFullAddressDom(false)
//         setUpdatedetNationallityDom(false)
//         setUpdatedetProvinceDom(false)
//         setUpdatedetCityDom(false)
//         setUpdatedetDistrictDom(false)
//         setUpdatedetUrbanDom(false)
//         setUpdatedetPostalCodeDom(false)
//         setUpdatedrbDomisilliStatus(false)
//         setUpdatedrbOccupation(false)
//         setUpdatedetNameOfBusiness(false)
//         setUpdatedrbBusinessSector(false)
//         setUpdatedetCompanyAddress(false)
//         setUpdatedetCompanyCountry(false)
//         setUpdatedetCompanyProvince(false)
//         setUpdatedetCompanyCity(false)
//         setUpdatedetCompanyDistrict(false)
//         setUpdatedetCompanyUrban(false)
//         setUpdatedetCompanyPostalCode(false)
//         setUpdatedetOfficePhoneNumber(false)
//         // setUpdatedetCompanyFaxNumber(false)
//         setUpdatedetPosition(false)
//         setUpdatedetWorkingFor(false)
//         setUpdatedrbFundSourceFrom(false)
//         setUpdatedrbFundPerMonth(false)
//         setUpdatedBailsmanName(false)
//         setUpdatedBailsmanDob(false)
//     }

//     const handleValidateDukcapil = async () => {
//         setLoadingButton(true)

//         const data = new FormData();
//         if (isWni) {
//             data.append("citizenship", "85");
//             data.append("country", "112");
//             data.append("fullName", getKTPFullName);
//             data.append("ktpNo", getKTP);
//             data.append("ktpExp", getKTPExp);
//             data.append("birthPlace", getKTPBirthPlace);
//             data.append("dob", getKTPBirthDate);
//             data.append("npwpIssued", getNPWPRegisteredAt == null ? null : moment(getNPWPRegisteredAt).format("YYYYMMDD"));
//             data.append("ktp_pic", await fetch(getPreviewImageKTP).then(r => r.blob()));
//             if (isHaveNPWP) {
//                 // data.append("npwp_pic", await fetch(getCurrentFileNPWP).then(r => r.blob()));
//                 data.append("npwp_pic", getCurrentFileNPWP);
//                 data.append("npwpNo", getNpwpNo);
//             }
//         } else {
//             data.append("citizenship", formCardVerification.values.etVerifCardCz);
//             data.append("country", isWorking ? "85" : "99");
//             data.append("passNo", formCardVerification.values.etNoPaspor);
//             data.append("passExp", getPassporExp == null ? null : moment(getPassporExp).format("DD-MM-YYYY"));
//             // data.append("pass_pic", await fetch(getCurrentFilePaspor).then(r => r.blob()));
//             data.append("pass_pic", getCurrentFilePaspor);
//             data.append("dob", null);
//             if (isWorking) {
//                 // data.append("kitas_pic", await fetch(getCurrentFileKITAS).then(r => r.blob()));
//                 data.append("kitas_pic", getCurrentFileKITAS);
//                 data.append("ktpNo", formCardVerification.values.etKITAS);
//                 data.append("ktpExp", getKitasExp == null ? null : moment(getKitasExp).format("DD-MM-YYYY"));
//                 // data.append("npwp_pic", await fetch(getCurrentFileNPWP).then(r => r.blob()));
//                 data.append("npwp_pic", getCurrentFileNPWP);
//                 data.append("npwpNo", getNpwpNo);
//                 data.append("npwpIssued", null);
//                 //     data.append("npwpIssued", getNPWPRegisteredAt == null ? null : moment(getNPWPRegisteredAt).format("YYYYMMDD"));
//             } else {
//                 data.append("kitas_pic", null);
//                 data.append("ktpNo", null);
//                 data.append("ktpExp", null);
//                 data.append("npwp_pic", null);
//                 data.append("npwpNo", null);
//                 data.append("npwpIssued", null);
//             }
//         }


//         // console.log("country", formCardVerification.values.etVerifCardCountryLabel);
//         // console.log("passNo", formCardVerification.values.etNoPaspor);
//         // console.log("passExp", getPassporExp);
//         // console.log("pass_pic", getCurrentFilePaspor);
//         // console.log(isWorking)
//         // console.log("kitas_pic", getCurrentFileKITAS);
//         // console.log("ktpNo", formCardVerification.values.etKITAS);
//         // console.log("ktpExp", getKitasExp);
//         // console.log("npwp_pic", getCurrentFileNPWP);
//         // console.log("npwpNo", getNpwpNo);
//         // console.log(data);
//         // setLoadingButton(false);
//         // return

//         Axios.post(MYURL.VERIFICATION_CARD, data, {headers: {"Content-Type": "application/json"}})
//             .then(res => {

//                 if (res.status == 200) {

//                 } else {
//                     setLoadingButton(false)
//                     setOpenModalRevampCard(true)
//                     setModalIllustrationStatus(false)
//                     setModalTitleMessage(LANG().FAILED)
//                     setModalDescMessage(res.data.message)
//                 }
//             })
//             .catch(res => {
//                 setLoadingButton(false)
//                 setOpenModalRevampCard(true)
//                 setModalIllustrationStatus(false)
//                 setModalTitleMessage(LANG().FAILED)
//                 if (res.response.status == 400) {
//                     setModalDescMessage(res.response.data.message)
//                 } else {
//                     setModalDescMessage(isWni ? LANG().DATA_INVALID_KTP : LANG().DATA_INVALID_WNA)
//                 }
//             })
//     }

//     const handleSendOTPEmail = () => {

//         const timeStamp = (new Date()).toISOString()

//         const bodyApi = {
//             userId: Storage.local.get('username'), activity: "Pengkinian Data",
//             key: MyEncrypt({code: MySecKey(), timestamp: timeStamp, id: Storage.local.get('username')}),
//             timestamp: timeStamp
//         };

//         setLoadingButton(true)

//         Axios.post(MYURL.SEND_OTP_EMAIL,
//             bodyApi,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 setLoadingButton(false)
//                 if (res.status == 201) {
//                     formInputOTPEmailState.values.etOTP1 = "";
//                     formInputOTPEmailState.values.etOTP2 = "";
//                     formInputOTPEmailState.values.etOTP3 = "";
//                     formInputOTPEmailState.values.etOTP4 = "";
//                     formInputOTPEmailState.values.etOTP5 = "";
//                     formInputOTPEmailState.values.etOTP6 = "";

//                     formInputOTPPhoneState.values.etOTP1 = "";
//                     formInputOTPPhoneState.values.etOTP2 = "";
//                     formInputOTPPhoneState.values.etOTP3 = "";
//                     formInputOTPPhoneState.values.etOTP4 = "";
//                     formInputOTPPhoneState.values.etOTP5 = "";
//                     formInputOTPPhoneState.values.etOTP6 = "";
//                     setPage(Page.REVAMP_INPUT_OTP_EMAIL);
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendEmail(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage(res.data.message);
//                 }
//             }
//         ).catch(
//             res => {
//                 setLoadingButton(false)
//                 setModalIllustrationStatus(false);
//                 setOpenModalOtpSendEmail(true);
//                 setModalTitleMessage(LANG().FAILED);
//                 setModalDescMessage("" + res.response.data.title);
//             });
//     }

//     const handleSendOTPPhone = () => {

//         const timeStamp = (new Date()).toISOString()

//         const bodyApi = {
//             userId: Storage.local.get('username'), activity: "Pengkinian Data",
//             key: MyEncrypt(({code: MySecKey(), timestamp: timeStamp, id: Storage.local.get('username')})),
//             timestamp: timeStamp
//         };

//         setLoadingButton(true)
//         Axios.post(MYURL.SEND_OTP_SMS,
//             bodyApi,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 setLoadingButton(false)
//                 if (res.status == 201) {
//                     formInputOTPEmailState.values.etOTP1 = "";
//                     formInputOTPEmailState.values.etOTP2 = "";
//                     formInputOTPEmailState.values.etOTP3 = "";
//                     formInputOTPEmailState.values.etOTP4 = "";
//                     formInputOTPEmailState.values.etOTP5 = "";
//                     formInputOTPEmailState.values.etOTP6 = "";

//                     formInputOTPPhoneState.values.etOTP1 = "";
//                     formInputOTPPhoneState.values.etOTP2 = "";
//                     formInputOTPPhoneState.values.etOTP3 = "";
//                     formInputOTPPhoneState.values.etOTP4 = "";
//                     formInputOTPPhoneState.values.etOTP5 = "";
//                     formInputOTPPhoneState.values.etOTP6 = "";
//                     setPage(Page.REVAMP_INPUT_OTP_PHONE);
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendPhone(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         ).catch(
//             res => {
//                 setLoadingButton(false)
//                 setModalIllustrationStatus(false);
//                 setOpenModalOtpSendPhone(true);
//                 setModalTitleMessage(LANG().FAILED);
//                 setModalDescMessage("" + res.response.data.message);
//             });
//     }

//     // const personalItem = () => {
//     //     let data = []
//     //     for (let i = 0; i < 20; i++) {
//     //         data.push(<div className={"col-md-6 mb-2"}>
//     //             <Row>
//     //                 <div>
//     //                     <div className={"titleContent"}>Title</div>
//     //                     <span className={"updatedText"}>*Updated</span>
//     //                 </div>
//     //             </Row>
//     //             <Row>
//     //                 <div className={"titleContentReview"}>Inputed Text</div>
//     //             </Row>
//     //         </div>)
//     //     }
//     //
//     //     return data
//     // }

//     const getCardPict = () => {
//         Axios.get(MYURL.GET_CARD_PICT,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 setLoadingButtonNextMain(false)
//                 if (res.status == 200) {
//                     setPage(Page.REVAMP_PREVIEW)
//                     window.scrollTo(0, 0)
//                     setPreviewImageKTP('data:image/png;base64,' + res.data.ktpPic);
//                     (res.data.kitasPic == null) ? setPreviewImageKITAS(null) : setPreviewImageKITAS('data:image/png;base64,' + res.data.kitasPic);
//                     (res.data.npwpPic == null) ? setPreviewImageNPWP(null) : setPreviewImageNPWP('data:image/png;base64,' + res.data.npwpPic);
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendPhone(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         ).catch(
//             res => {
//                 setLoadingButtonNextMain(false)
//                 setModalIllustrationStatus(false);
//                 setOpenModalOtpSendPhone(true);
//                 setModalTitleMessage(LANG().FAILED);
//                 setModalDescMessage("" + res.response.data.message);
//             });
//     }

//     const handlePreviewData = async () => {

//         var p1 = await formPersonalDataState.values;
//         var p2 = await formAddressDataState.values;
//         var p3 = await formWorkingDataState.values;

//         var rq1 = await getRequiredAc1();
//         var rq2 = await getRequiredAc2(p2);
//         var rq3 = await getRequiredAc3(p3);

//         var rq = await [...rq1, ...rq2, ...rq3];
//         var p = await {...p1, ...p2, ...p3};

//         for (var i = 0; i < rq.length; i++) {
//             if (isEmptyField(p[rq[i]])) {

//                 let c1 = await Object.keys(p1)
//                     .filter(v => (rq1.includes(v) && isEmptyField(p1[v])))
//                     .map(v => ac1Map[v]["in"]);

//                 let c2 = await Object.keys(p2)
//                     .filter(v => (rq2.includes(v) && isEmptyField(p2[v])))
//                     .map(v => ac2Map[v]["in"]);

//                 let c3 = await Object.keys(p3)
//                     .filter(v => (rq3.includes(v) && isEmptyField(p3[v])))
//                     .map(v => {
//                         console.log(v);
//                         console.log(ac3Map[v]);
//                         return ac3Map[v]["in"];
//                     });

//                 if (c1.length > 0) setTabOneReqShow(true)
//                 if (c2.length > 0) setTabTwoReqShow(true)
//                 if (c3.length > 0) setTabThreeReqShow(true)

//                 setOpenRequiredDialog(true)
//                 setModalIllustrationStatus(false)
//                 setModalTitleMessage(LANG().FAILED)
//                 setModalDescMessage(LANG().FORM.ERROR.REQUIRED_REVAMP)
//                 setEmptyRequiredList({
//                     "Data Pribadi": c1,
//                     "Alamat": c2,
//                     "Data Pekerjaan & Sumber Dana": c3
//                 })
//                 return
//             }
//         }

//         setLoadingButtonNextMain(true)

//         Axios.get(MYURL.CACHED_DIF,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 // setLoadingButtonNextMain(false)
//                 if (res.status == 200) {
//                     getCardPict()
//                     setChangedData(res.data.map(v => v['key']))
//                     // setPage(Page.REVAMP_PREVIEW)
//                     // window.scrollTo(0, 0)
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendPhone(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         ).catch(res => {
//             setLoadingButtonNextMain(false)
//             setModalIllustrationStatus(false);
//             setOpenModalOtpSendPhone(true);
//             setModalTitleMessage(LANG().FAILED);
//             setModalDescMessage("" + res.response.data.message);
//         });
//     }

//     const handleResetData = () => {

//         setLoadingButtonResetData(true)
//         Axios.delete(MYURL.CACHED_USER_BIO + "?userId=" + Storage.local.get('username'),
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 setLoadingButtonResetData(false)
//                 if (res.status == 200) {
//                     window.location.replace('/');
//                     // setChangedData(res.data)
//                     // setPage(Page.REVAMP_PREVIEW)
//                     // window.scrollTo(0, 0)
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendPhone(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         ).catch(
//             res => {
//                 setLoadingButtonResetData(false)
//                 setModalIllustrationStatus(false);
//                 setOpenModalOtpSendPhone(true);
//                 setModalTitleMessage(LANG().FAILED);
//                 setModalDescMessage("" + res.response.data.message);
//             });
//     }

//     const handleSubmitPengkinianData = (from) => {

//         var investorSpouseNameCondition = null
//         var investorSpouseCidNoCondition = null
//         var investorSpouseBirthDateCondition = null
//         var investorSpouseCntcNoCondition = null

//         if (getBioCache.investorMartialStatus?.trim() == "2") {
//             investorSpouseNameCondition = getChangedData.indexOf("investorSpouseName") > -1 ? getBioCache.investorSpouseName : null
//             investorSpouseCidNoCondition = getChangedData.indexOf("investorSpouseCidNo") > -1 ? getBioCache.investorSpouseCidNo : null
//             investorSpouseBirthDateCondition = getChangedData.indexOf("investorSpouseBirthDate") > -1 ? getBioCache.investorSpouseBirthDate : null
//             investorSpouseCntcNoCondition = getChangedData.indexOf("investorSpouseCntcNo") > -1 ? getBioCache.investorSpouseCntcNo : null
//         }

//         let otpEmail = formInputOTPEmailState.values.etOTP1 +
//             formInputOTPEmailState.values.etOTP2 +
//             formInputOTPEmailState.values.etOTP3 +
//             formInputOTPEmailState.values.etOTP4 +
//             formInputOTPEmailState.values.etOTP5 +
//             formInputOTPEmailState.values.etOTP6

//         let otpPhone = formInputOTPPhoneState.values.etOTP1 +
//             formInputOTPPhoneState.values.etOTP2 +
//             formInputOTPPhoneState.values.etOTP3 +
//             formInputOTPPhoneState.values.etOTP4 +
//             formInputOTPPhoneState.values.etOTP5 +
//             formInputOTPPhoneState.values.etOTP6

//         const bodyApi = {
//             userId: Storage.local.get('username'),
//             otp: (from == "phone") ? otpPhone : otpEmail,
//             investorFullName: getChangedData.indexOf("investorFullName") > -1 ? getBioCache.investorFullName : null,
//             investorCidNo: getChangedData.indexOf("investorCidNo") > -1 ? getBioCache.investorCidNo : null,
//             investorCidExp: getChangedData.indexOf("investorCidExp") > -1 ? getBioCache.investorCidExp : null,
//             investorBirthDate: getChangedData.indexOf("investorBirthDate") > -1 ? getBioCache.investorBirthDate : null,
//             investorBirthPlace: getChangedData.indexOf("investorBirthPlace") > -1 ? getBioCache.investorBirthPlace : null,
//             investorSex: getChangedData.indexOf("investorSex") > -1 ? getBioCache.investorSex : null,
//             investorMartialStatus: getChangedData.indexOf("investorMartialStatus") > -1 ? getBioCache.investorMartialStatus : null,
//             investorSpouseName: investorSpouseNameCondition,
//             investorSpouseCidNo: investorSpouseCidNoCondition,
//             investorSpouseBirthDate: investorSpouseBirthDateCondition,
//             investorSpouseCntcNo: investorSpouseCntcNoCondition,
//             investorMothersMaidenName: getChangedData.indexOf("investorMothersMaidenName") > -1 ? getBioCache.investorMothersMaidenName : null,
//             investorDependencyCount: getChangedData.indexOf("investorDependencyCount") > -1 ? getBioCache.investorDependencyCount : null,
//             investorSeperateAggreement: getChangedData.indexOf("investorSeperateAggreement") > -1 ? getBioCache.investorSeperateAggreement : null,
//             investorNPWPNumber: getChangedData.indexOf("investorNPWPNumber") > -1 ? getBioCache.investorNPWPNumber : null,
//             investorNPWPRegistrationDate: getChangedData.indexOf("investorNPWPRegistrationDate") > -1 ? getBioCache.investorNPWPRegistrationDate : null,
//             investorHomePhone: getChangedData.indexOf("investorHomePhone") > -1 ? getBioCache.investorHomePhone : null,
//             // investorFax: getChangedData.indexOf("investorFax") > -1 ? getBioCache.investorFax : null,
//             investorMobilePhone: getChangedData.indexOf("investorMobilePhone") > -1 ? getBioCache.investorMobilePhone : null,
//             investorEmail: getChangedData.indexOf("investorEmail") > -1 ? getBioCache.investorEmail : null,
//             investorEducationalBackground: getChangedData.indexOf("investorEducationalBackground") > -1 ? getBioCache.investorEducationalBackground : null,

//             investorCountryCode: getChangedData.indexOf("investorCountryCode") > -1 ? getBioCache.investorCountryCode : null,
//             investorCountryName: getBioCache.investorCountryName,
//             investorPrpsNm: getBioCache.investorPrpsNm,
//             investorCityNm: getBioCache.investorCityNm,
//             investorDistrictNm: getBioCache.investorDistrictNm,
//             investorUrbanNm: getBioCache.investorUrbanNm,
//             investorAddress: getChangedData.indexOf("investorAddress") > -1 ? getBioCache.investorAddress : null,
//             investorPrpsCd: getChangedData.indexOf("investorPrpsCd") > -1 ? getBioCache.investorPrpsCd : null,
//             investorCityCd: getChangedData.indexOf("investorCityCd") > -1 ? getBioCache.investorCityCd : null,
//             investorDistrictCd: getChangedData.indexOf("investorDistrictCd") > -1 ? getBioCache.investorDistrictCd : null,
//             investorUrbanCd: getChangedData.indexOf("investorUrbanCd") > -1 ? getBioCache.investorUrbanCd : null,
//             investorPostalCode: getChangedData.indexOf("investorPostalCode") > -1 ? getBioCache.investorPostalCode : null,

//             investorOtherCountryCode: getChangedData.indexOf("investorOtherCountryCode") > -1 ? getBioCache.investorOtherCountryCode : null,
//             investorOtherCountryName: getBioCache.investorOtherCountryName,
//             investorOtherPrpsNm: getBioCache.investorOtherPrpsNm,
//             investorOtherCityNm: getBioCache.investorOtherCityNm,
//             investorOtherDistrictNm: getBioCache.investorOtherDistrictNm,
//             investorOtherUrbanNm: getBioCache.investorOtherUrbanNm,
//             investorOtherAddress: getChangedData.indexOf("investorOtherAddress") > -1 ? getBioCache.investorOtherAddress : null,
//             investorOtherPrpsCd: getChangedData.indexOf("investorOtherPrpsCd") > -1 ? getBioCache.investorOtherPrpsCd : null,
//             investorOtherCityCd: getChangedData.indexOf("investorOtherCityCd") > -1 ? getBioCache.investorOtherCityCd : null,
//             investorOtherDistrictCd: getChangedData.indexOf("investorOtherDistrictCd") > -1 ? getBioCache.investorOtherDistrictCd : null,
//             investorOtherUrbanCd: getChangedData.indexOf("investorOtherUrbanCd") > -1 ? getBioCache.investorOtherUrbanCd : null,
//             investorOtherPostalCode: getChangedData.indexOf("investorOtherPostalCode") > -1 ? getBioCache.investorOtherPostalCode : null,

//             investorOccupation: getChangedData.indexOf("investorOccupation") > -1 ? getBioCache.investorOccupation : null,
//             investorOccupationDetl: getChangedData.indexOf("investorOccupationDetl") > -1 ? getBioCache.investorOccupationDetl : null,
//             investorEntpType: getChangedData.indexOf("investorEntpType") > -1 ? getBioCache.investorEntpType : null,
//             investorOfficeName: getChangedData.indexOf("investorOfficeName") > -1 ? getBioCache.investorOfficeName : null,
//             investorFundSource: getChangedData.indexOf("investorFundSource") > -1 ? getBioCache.investorFundSource : null,
//             investorFundSourceDetl: getChangedData.indexOf("investorFundSourceDetl") > -1 ? getBioCache.investorFundSourceDetl : null,
//             investorIncomePerAnnum: getChangedData.indexOf("investorIncomePerAnnum") > -1 ? getBioCache.investorIncomePerAnnum : null,
//             investorOtherJob: getChangedData.indexOf("investorOtherJob") > -1 ? getBioCache.investorOtherJob : null,

//             investorWorkCountryCode: getChangedData.indexOf("investorWorkCountryCode") > -1 ? getBioCache.investorWorkCountryCode : null,
//             investorWorkCountryName: getBioCache.investorWorkCountryName,
//             investorWorkPrpsNm: getBioCache.investorWorkPrpsNm,
//             investorWorkCityNm: getBioCache.investorWorkCityNm,
//             investorWorkDistrictNm: getBioCache.investorWorkDistrictNm,
//             investorWorkUrbanNm: getBioCache.investorWorkUrbanNm,
//             investorWorkAddress: getChangedData.indexOf("investorWorkAddress") > -1 ? getBioCache.investorWorkAddress : null,
//             investorWorkPrpsCd: getChangedData.indexOf("investorWorkPrpsCd") > -1 ? getBioCache.investorWorkPrpsCd : null,
//             investorWorkCityCd: getChangedData.indexOf("investorWorkCityCd") > -1 ? getBioCache.investorWorkCityCd : null,
//             investorWorkDistrictCd: getChangedData.indexOf("investorWorkDistrictCd") > -1 ? getBioCache.investorWorkDistrictCd : null,
//             investorWorkUrbanCd: getChangedData.indexOf("investorWorkUrbanCd") > -1 ? getBioCache.investorWorkUrbanCd : null,
//             investorWorkPostalCode: getChangedData.indexOf("investorWorkPostalCode") > -1 ? getBioCache.investorWorkPostalCode : null,
//             investorWorkHomePhone: getChangedData.indexOf("investorWorkHomePhone") > -1 ? getBioCache.investorWorkHomePhone : null,
//             // investorWorkFax: getChangedData.indexOf("investorWorkFax") > -1 ? getBioCache.investorWorkFax : null,
//             investorJobRole: getChangedData.indexOf("investorJobRole") > -1 ? getBioCache.investorJobRole : null,
//             investorWorkYear: getChangedData.indexOf("investorWorkYear") > -1 ? getBioCache.investorWorkYear : null,
//             investorWorkMonth: getChangedData.indexOf("investorWorkMonth") > -1 ? getBioCache.investorWorkMonth : null,
//             iBenfOwnerNm: getChangedData.indexOf("iBenfOwnerNm") > -1 ? getBioCache.iBenfOwnerNm : null,
//             iBenfOwnerDob: getChangedData.indexOf("iBenfOwnerDob") > -1 ? getBioCache.iBenfOwnerDob : null,
//         };

//         // console.log(bodyApi)
//         // return

//         setLoadingButton(true)
//         Axios.post(MYURL.SAVE_CACHE,
//             bodyApi,
//             {headers: {"Content-Type": "application/json"}}).then(
//             res => {
//                 setLoadingButton(false)
//                 if (res.status == 200) {
//                     setModalIllustrationStatus(true);
//                     (from == "phone") ? setOpenModalOtpInputPhone(true) : setOpenModalOtpInputEmail(true)
//                     setModalTitleMessage(LANG().SUCCESS);
//                     setModalDescMessage("Berhasil melakukan pengkinian data");
//                 } else {
//                     setModalIllustrationStatus(false);
//                     setOpenModalOtpSendPhone(true);
//                     setModalTitleMessage(LANG().FAILED);
//                     setModalDescMessage("" + res.data.message);
//                 }
//             }
//         ).catch(
//             async res => {
//                 setLoadingButton(false)
//                 setModalIllustrationStatus(false);
//                 (from == "phone") ? setOpenModalOtpInputPhone(true) : setOpenModalOtpInputEmail(true)
//                 setModalTitleMessage(LANG().FAILED);

//                 let msg = await res.response.data.message;
//                 if (res.response.status >= 500) {
//                     msg = "Terjadi kesalahan jaringan, mohon coba kembali."
//                     // setTimeout(() => { window.location.reload(); }, 5000);
//                 } else if (await msg.substring(msg.length - 4, msg.length) == "link") {
//                     msg = await msg.substring(0, msg.length - 4);
//                     setModalShowLink(true);
//                 }
//                 setModalDescMessage("" + msg);
//             });
//     }

//     //Page 1 Login PIN
//     const loginPINState = useFormik({
//         initialValues: {
//             etPIN1: "",
//             etPIN2: "",
//             etPIN3: "",
//             etPIN4: "",
//             etPIN5: "",
//             etPIN6: ""
//         },

//         onSubmit: values => {
//             handleLoginPIN(values.etPIN1 + values.etPIN2 + values.etPIN3 + values.etPIN4 + values.etPIN5 + values.etPIN6)
//         }
//     });

//     const [isOpenModalLoginPIN, setOpenModalLoginPIN] = useState(false);

//     const loginPinLayout =
//         <div className="page-wrapper-pin">

//             <Modal style={{top: "25%"}} isOpen={isOpenModalLoginPIN}>
//                 <ModalBody>
//                     <div className="text-center mb-4">{getModalIllustrationStatus ? <SuccessIllustration/> :
//                         <FailedIllustration/>}</div>
//                     <p style={{fontSize: "20px"}} className="text-center mb-2 font-m-500">{getModalTitleMessage}</p>
//                     <p style={{fontSize: "14px"}} className="text-center mb-2 font-r-400">{getModalDescMessage}</p>
//                     <div className="d-flex justify-content-center" onClick={() => {
//                         setOpenModalLoginPIN(false);
//                         setModalTitleMessage("");
//                         setModalDescMessage("");
//                         resetFocusLoginPIN()
//                     }}>
//                         <DarkPurpleButton text='OK' width="20%"/>
//                     </div>
//                 </ModalBody>
//             </Modal>

//             <div style={{marginLeft: "auto", marginRight: "auto"}} className="col-md-4">

//                 <Row className="nav-title">
//                     <div className="media-body d-flex justify-content-left">
//                         <Link to={{
//                             pathname: "/"
//                         }}>
//                             <div style={{marginRight: 12}} className="d-flex align-items-center">
//                                 <NavBackIcon/>
//                             </div>
//                         </Link>
//                         <div className="font-r-400 text-black">{LANG().TITLE.LOGIN_PIN}</div>
//                     </div>
//                 </Row>

//                 <Row className='mt-4'>
//                     <AvForm onSubmit={loginPINState.handleSubmit} style={{marginTop: "40px"}} autoComplete="off">
//                         <Row>
//                             <Col className='d-flex justify-content-between'>
//                                 <input
//                                     className="field-pin"
//                                     type="password"
//                                     maxLength={1}
//                                     value={loginPINState.values.etPIN1}
//                                     onChange={(e) => {
//                                         let otp = e?.target?.value;

//                                         loginPINState.handleChange({
//                                             target: {
//                                                 name: "etPIN1",
//                                                 value: otp?.replace(/[^0-9]+/g, '')
//                                             }
//                                         })
//                                     }}
//                                     onKeyUp={(e) => tabChange(1)}/>
//                                 <input className="field-pin"
//                                        type="password"
//                                        maxLength={1}
//                                        value={loginPINState.values.etPIN2}
//                                        onChange={(e) => {
//                                            let otp = e?.target?.value;

//                                            loginPINState.handleChange({
//                                                target: {
//                                                    name: "etPIN2",
//                                                    value: otp?.replace(/[^0-9]+/g, '')
//                                                }
//                                            })
//                                        }}
//                                        onKeyUp={(e) => tabChange(2)}/>
//                                 <input className="field-pin"
//                                        type="password"
//                                        maxLength={1}
//                                        value={loginPINState.values.etPIN3}
//                                        onChange={(e) => {
//                                            let otp = e?.target?.value;

//                                            loginPINState.handleChange({
//                                                target: {
//                                                    name: "etPIN3",
//                                                    value: otp?.replace(/[^0-9]+/g, '')
//                                                }
//                                            })
//                                        }}
//                                        onKeyUp={(e) => tabChange(3)}/>
//                                 <input className="field-pin"
//                                        type="password"
//                                        maxLength={1}
//                                        value={loginPINState.values.etPIN4}
//                                        onChange={(e) => {
//                                            let otp = e?.target?.value;

//                                            loginPINState.handleChange({
//                                                target: {
//                                                    name: "etPIN4",
//                                                    value: otp?.replace(/[^0-9]+/g, '')
//                                                }
//                                            })
//                                        }}
//                                        onKeyUp={(e) => tabChange(4)}/>
//                                 <input className="field-pin"
//                                        type="password"
//                                        maxLength={1}
//                                        value={loginPINState.values.etPIN5}
//                                        onChange={(e) => {
//                                            let otp = e?.target?.value;

//                                            loginPINState.handleChange({
//                                                target: {
//                                                    name: "etPIN5",
//                                                    value: otp?.replace(/[^0-9]+/g, '')
//                                                }
//                                            })
//                                        }}
//                                        onKeyUp={(e) => tabChange(5)}/>
//                                 <input className="field-pin"
//                                        type="password"
//                                        maxLength={1}
//                                        onChange={(e) => {
//                                            let otp = e?.target?.value;

//                                            loginPINState.handleChange({
//                                                target: {
//                                                    name: "etPIN6",
//                                                    value: otp?.replace(/[^0-9]+/g, '')
//                                                }
//                                            })
//                                        }}
//                                        onKeyUp={(e) => tabChange(6)}/>
//                             </Col>
//                         </Row>

//                         <br/>
//                         <br/>

//                         <Link to="/forgot-pin" style={{color: '#F37F26'}}>
//                             {LANG().FORGOT_PIN}
//                         </Link>

//                         <div style={{marginTop: 40}}>
//                             <DarkPurpleButton text={LANG().BUTTON.SEND} disabled={loadingButton}/>
//                         </div>

//                     </AvForm>

//                 </Row>

//             </div>
//         </div>


//     //Page 2 Verification Card

//     const resizeFile = (file) =>
//         new Promise((resolve) => {
//             Resizer.imageFileResizer(
//                 file,
//                 400,
//                 400,
//                 "JPEG",
//                 100,
//                 0,
//                 (uri) => {
//                     resolve(uri);
//                 },
//                 "file"
//             );
//         });

//     const selectFileKTP = async (e) => {
//         let cFile = e.target.files[0];
//         if (cFile && cFile.size > 2000000) {
//             try {
//                 cFile = await resizeFile(cFile);
//             } catch (err) {
//                 console.log(err);
//             }
//         }

//         if (cFile && cFile.size > 2000000) {
//             try {
//                 cFile = await resizeFile(cFile);
//             } catch (err) {
//                 console.log(err);
//             }
//         }

//         console.log(cFile);
//         setPreviewImageKTP(URL.createObjectURL(cFile))

//         if (cFile) {
//             handleCardVerification(cFile)
//         }
//     }

//     const selectFileNPWP = (e) => {
//         let cFile = e.target.files[0];
//         // if (cFile && cFile.size > 2000000) { try { cFile = await resizeFile(cFile); } catch (err) { console.log(err); } }

//         setCurrentFileNPWP(cFile)
//         setPreviewImageNPWP(URL.createObjectURL(cFile))
//         if (cFile) {
//             setNPWPViewedPH("d-none")
//             setNPWPViewedIMG("d-show")
//         }
//     }

//     const selectFilePaspor = (e) => {
//         let cFile = e.target.files[0];
//         // if (cFile && cFile.size > 2000000) { try { cFile = await resizeFile(cFile); } catch (err) { console.log(err); } }

//         setCurrentFilePaspor(cFile)
//         setPreviewImagePaspor(URL.createObjectURL(cFile))
//         if (cFile) {
//             setPasporViewedPH("d-none")
//             setPasporViewedIMG("d-show")
//         }
//     }

//     const selectFileKITAS = (e) => {
//         let cFile = e.target.files[0];
//         // if (cFile && cFile.size > 2000000) { try { cFile = await resizeFile(cFile); } catch (err) { console.log(err); } }

//         setCurrentFileKITAS(cFile)
//         setPreviewImageKITAS(URL.createObjectURL(cFile))
//         if (cFile) {
//             setKitasViewedPH("d-none")
//             setKitasViewedIMG("d-show")
//         }
//     }

//     const selectFileNPWPWNA = (e) => {
//         let cFile = e.target.files[0];
//         // if (cFile && cFile.size > 2000000) { try { cFile = await resizeFile(cFile); } catch (err) { console.log(err); } }

//         setCurrentFileNPWPWNA(cFile)
//         setPreviewImageNPWPWNA(URL.createObjectURL(cFile))
//         if (cFile) {
//             setNPWPWNAViewedPH("d-none")
//             setNPWPWNAViewedIMG("d-show")
//         }
//     }

//     const [isShowKTPSpinner, setShowKTPSpinner] = useState(false)

//     const formCardVerification = useFormik({
//         initialValues: {
//             rbNationalityInd: false,

//             country: "",
//             citizenship: "",
//             etVerifCardCz: null,
//             etVerifCardCountryLabel: "",

//             etIsWorking: false,

//             //WNI
//             fileKTP: null,
//             etKTP: "",
//             etNoKTP: "",
//             fileNPWPWNI: null,
//             rbNPWPWNI: false,
//             npwpNo: null,
//             npwpIssued: null,

//             //WNA
//             filePaspor: null,
//             etNoPaspor: "",
//             etPasporExp: "",
//             // etNPWPWNA: "",
//             etKITAS: "",
//             rbIsWNAWorking: false,
//             fileKITAS: null,
//             // fileNPWP: null,
//             etKITASExp: null

//         },

//         validationSchema: Yup.object().shape({
//             // fileKTP: Yup.string().required(LANG().FORM.ERROR.REQUIRED).nullable(),
//             // etKTP: Yup.string().required(LANG().FORM.ERROR.REQUIRED),
//         }),

//         onSubmit: values => {
//             handleValidateDukcapil()
//         }
//     });

//     const [isOpenModalRevampCard, setOpenModalRevampCard] = useState(false);

//     const revampCard = <div className="page-wrapper">

//         <Modal style={{top: "25%"}} isOpen={isOpenModalRevampCard}>
//             <ModalBody>
//                 <div className="text-center mb-4">{getModalIllustrationStatus ? <SuccessIllustration/> :
//                     <FailedIllustration/>}</div>
//                 <p style={{fontSize: "20px"}} className="text-center mb-2 font-m-500">{getModalTitleMessage}</p>
//                 <p style={{fontSize: "14px"}} className="text-center mb-2 font-r-400">{getModalDescMessage}</p>
//                 <div className="d-flex justify-content-center" onClick={() => {
//                     if (getModalTitleMessage == LANG().SUCCESS) {
//                         setPage(Page.REVAMP_MAIN)
//                     } else {
//                         setOpenModalRevampCard(false);
//                         setModalTitleMessage("");
//                         setModalDescMessage("");
//                     }
//                 }}>
//                     <DarkPurpleButton text='OK' width="20%"/>
//                 </div>
//             </ModalBody>
//         </Modal>

//         <div style={{marginLeft: "auto", marginRight: "auto"}} className="col-md-6 col-lg-6">
//             <HeaderForm navTitle={LANG().TITLE.VERIFICATION}/>
//             <AvForm className="font-r-400" onSubmit={formCardVerification.handleSubmit}>
//                 <Col>
//                     <Row className={"d-none"}>
//                         <div
//                             style={{marginBottom: "16px"}}>{isWni ? 'Pilihan Kewarganegaraan' : 'Citizenship Class'}</div>
//                     </Row>
//                     <Row style={{marginBottom: "32px"}} className={"d-none"}>
//                         <Col
//                             className={isWni ? "d-show" : "d-none"}><RadioSelectIcon/> {isWni ? 'WNI (Warga Negara Indonesia)' : 'WNI (Citizen of Indonesia)'}
//                         </Col>
//                         <Col className={isWni ? "d-none" : "d-show"}><RadioDeselectIcon onClick={() => {
//                             formCardVerification.values.rbNationalityInd = true
//                             setWni(true)
//                         }}/> {isWni ? 'WNI (Warga Negara Indonesia)' : 'WNI (Citizen of Indonesia)'}</Col>

//                         <Col className={isWni ? "d-show" : "d-none"}><RadioDeselectIcon onClick={() => {
//                             formCardVerification.values.rbNationalityInd = false
//                             setWni(false)
//                             // setKTPViewedIMG("d-none")
//                             // setKTPViewedPH("d-show")
//                             // formCardVerification.values.fileKTP = null
//                             // formCardVerification.handleChange({ target: { name: "etKTP", value: "" } })
//                             // setUpdatedetNPWP(false)
//                             // setNpwpNo(formCardVerification.values.npwpNo)
//                             // if(formCardVerification.values.npwpIssued != null) {
//                             //     setNPWPRegisteredAt(toDateHots(formCardVerification.values.npwpIssued))
//                             // }
//                         }}/> {isWni ? 'WNA (Warga Negara Asing)' : 'WNA (Not Citizen of Indonesia)'}</Col>
//                         <Col
//                             className={isWni ? "d-none" : "d-show"}><RadioSelectIcon/> {isWni ? 'WNA (Warga Negara Asing)' : 'WNA (Not Citizen of Indonesia)'}
//                         </Col>
//                     </Row>

//                     {/*WNI START*/}
//                     <div className={"wniContainer " && isWni ? "d-show" : "d-none"}>
//                         {/*KTP CONTAINER START*/}
//                         <Row>
//                             <div style={{marginBottom: "16px"}}>KTP</div>
//                         </Row>
//                         <div style={{marginBottom: "12px"}} className={isKTPViewedPH}>
//                             <Col className={"boxUpload uploadPlaceHolder d-flex align-items-center"}
//                                  style={{position: "relative"}}>
//                                 <Row style={{margin: "auto"}}>
//                                     <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}}>
//                                         <img src="../../content/images/pd_ktp_ilustr.png" height="100"/>
//                                     </div>
//                                     <div className={"text-center"}><b>Upload KTP</b></div>
//                                     <div className={"text-center"}>PNG and JPG (Max. File Size 1MB)</div>
//                                 </Row>
//                                 <Input name="fileKTP" className={"uploadField"} type="file" accept="image/*"
//                                        onChange={selectFileKTP}
//                                        onClick={(event): string => (event.currentTarget.value = "")}/>
//                             </Col>
//                             {formCardVerification.touched.fileKTP && formCardVerification.errors.fileKTP ?
//                                 <div style={{marginTop: "8px"}}><span
//                                     style={{color: "#D8382E"}}>{formCardVerification.errors.fileKTP}</span></div> :
//                                 <div></div>}
//                         </div>

//                         <Col className={isKTPViewedIMG} style={{marginBottom: "12px"}}>
//                             <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                 <a style={{cursor: "pointer"}} className={"closeButton"} onClick={() => {
//                                     setKTPViewedIMG("d-none")
//                                     setKTPViewedPH("d-show")
//                                     formCardVerification.values.fileKTP = null
//                                 }}></a>
//                                 <img className={"previewImageContainer"} src={getPreviewImageKTP} width={323}
//                                      height={204}/>
//                             </div>
//                         </Col>

//                         <Col style={{marginBottom: "12px", position: "relative"}}
//                              className={isShowKTPSpinner ? "boxUpload uploadPlaceHolder d-flex align-items-center justify-content-center d-show" : "boxUpload uploadPlaceHolder d-flex align-items-center justify-content-center d-none"}>
//                             <Spinner color='#fff'/>
//                         </Col>

//                         <div style={{
//                             padding: '8px',
//                             paddingBottom: '0px',
//                             paddingLeft: '0px',
//                             marginBottom: "20px", 
//                             backgroundColor: 'rgba(243, 127, 38, 0.3)', 
//                             fontSize: '10.5px', 
//                             borderRadius: '2px', 
//                             border: '1px solid rgba(243, 127, 38, 1)',
//                             width: '323px'

//                         }} >
//                             <ul style={{paddingLeft: '1.5rem'}} >
//                                 <li>Pastikan foto KTP harus jelas dan tidak buram (foto asli)</li>
//                                 <li>Pastikan tidak mengupload foto KTP berupa scan/fotokopi</li>
//                             </ul>
//                         </div>

//                         <Row>
//                             <div style={{marginBottom: "16px"}}>No KTP</div>
//                         </Row>
//                         <Row style={{marginBottom: "32px"}}>
//                             <div>
//                                 <input
//                                     className="form-control"
//                                     value={formCardVerification.values.etKTP}
//                                     maxLength={16}
//                                     onChange={(e) => {
//                                         let valueText = e?.target?.value

//                                         formCardVerification.handleChange({
//                                             target: {
//                                                 name: "etKTP",
//                                                 value: valueText.replace(/[^0-9]+/g, '')
//                                             }
//                                         })
//                                     }}
//                                     autoComplete={"off"}/>
//                             </div>
//                             {formCardVerification.touched.etKTP && formCardVerification.errors.etKTP ?
//                                 <div style={{marginTop: "8px"}}><span
//                                     style={{color: "#D8382E"}}>{formCardVerification.errors.etKTP}</span></div> :
//                                 <div></div>}
//                         </Row>
//                         {/*KTP CONTAINER END*/}

//                         {/*NPWP CONTAINER START*/}
//                         <Row style={{marginBottom: "16px"}}>
//                             <Col>Apakah anda memiliki NPWP?</Col>
//                             <Col className={"d-flex justify-content-end flex-grow-0"}
//                                  style={{cursor: "pointer"}} onClick={() => {
//                                 if (isUpdatedetNPWP) {
//                                     setUpdatedetNPWP(false)
//                                     setNpwpNo(formCardVerification.values.npwpNo)
//                                     if (formCardVerification.values.npwpIssued != null) {
//                                         setNPWPRegisteredAt(toDateHots(formCardVerification.values.npwpIssued))
//                                     }
//                                     // formPersonalDataState.values.etNPWP = getBioCache.investorNPWPNumber
//                                 } else {
//                                     setUpdatedetNPWP(true)
//                                 }
//                             }
//                             }>{isUpdatedetNPWP ? "Cancel" : "Edit"}</Col>
//                         </Row>

//                         <Row style={{marginBottom: "32px"}}>
//                             <Col className={isHaveNPWP ? "d-show" : "d-none"}><RadioSelectIcon/>Ya</Col>
//                             <Col className={isHaveNPWP ? "d-none" : "d-show"}><RadioDeselectIcon onClick={() => {
//                                 if (isUpdatedetNPWP) {
//                                     setHaveNPWP(true)
//                                 }
//                             }}/>Ya</Col>

//                             <Col className={isHaveNPWP ? "d-none" : "d-show"}><RadioSelectIcon/>Tidak</Col>
//                             <Col className={isHaveNPWP ? "d-show" : "d-none"}><RadioDeselectIcon onClick={() => {
//                                 if (isUpdatedetNPWP) {
//                                     setHaveNPWP(false)
//                                 }
//                             }}/>Tidak</Col>
//                         </Row>

//                         <div style={{marginBottom: "16px"}}
//                              className={(isHaveNPWP && isUpdatedetNPWP) ? "d-show" : "d-none"}>
//                             <Row>
//                                 <div style={{marginBottom: "16px"}}>NPWP</div>
//                             </Row>
//                             <Col
//                                 className={"boxUpload uploadPlaceHolder d-flex align-items-center " + isNPWPViewedPH}
//                                 style={{marginBottom: "32px", position: "relative"}}>
//                                 <Row style={{margin: "auto"}}>
//                                     <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}}>
//                                         <img src="../../content/images/pd_npwp_ilustr.png" height="100"/>
//                                     </div>
//                                     <div className={"text-center"}><b>Upload NPWP</b></div>
//                                     <div className={"text-center"}>PNG and JPG (Max. File Size 2MB)</div>
//                                 </Row>
//                                 <input className={"uploadField"} type="file" accept="image/*"
//                                        onChange={selectFileNPWP}
//                                        onClick={(event): string => (event.currentTarget.value = "")}/>
//                             </Col>
//                             <Col className={isNPWPViewedIMG} style={{marginBottom: "32px"}}>
//                                 <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                     <a style={{cursor: "pointer"}} className={"closeButton"} onClick={() => {
//                                         setNPWPViewedIMG("d-none")
//                                         setNPWPViewedPH("d-show")
//                                     }}></a>
//                                     <img className={"previewImageContainer"} src={getPreviewImageNPWP} width={323}
//                                          height={204}/>
//                                 </div>
//                             </Col>
//                         </div>

//                         {isHaveNPWP && <><Row style={{marginTop: "20px"}}>
//                             <Col>No NPWP</Col>
//                         </Row>
//                             <Row style={{marginTop: "8px"}}>
//                                 <div>
//                                     <Input
//                                         name="etNPWP"
//                                         className="form-control"
//                                         value={getNpwpNo}
//                                         onChange={(e) => setNpwpNo(e.target.value)}
//                                         disabled={!isUpdatedetNPWP}
//                                         required={false}/>
//                                 </div>
//                             </Row>

//                             {/*<Row style={{marginTop: "20px"}}>*/}
//                             {/*    <Col>Tanggal Terdaftar NPWP</Col>*/}
//                             {/*    /!*<Col className={"d-flex justify-content-end"}*/}
//                             {/*         style={{cursor: "pointer"}} onClick={() => {*/}
//                             {/*        if (isUpdateddpNPWPJoinDate) {*/}
//                             {/*            setUpdateddpNPWPJoinDate(false)*/}
//                             {/*            // formPersonalDataState.values.dpNPWPJoinDate = moment(getBioCache.investorNPWPRegistrationDate).format("YYYY-MM-DD")*/}
//                             {/*        } else {*/}
//                             {/*            setUpdateddpNPWPJoinDate(true)*/}
//                             {/*        }*/}
//                             {/*    }*/}
//                             {/*    }>{isUpdateddpNPWPJoinDate ? "Cancel" : "Edit"}</Col>*!/*/}
//                             {/*</Row>*/}
//                             {/*<Row style={{marginTop: "8px"}}>*/}
//                             {/*    <div>*/}

//                             {/*        <DatePicker style={{textAlign: "right"}}*/}
//                             {/*                    className={"form-control"}*/}
//                             {/*                    selected={getNPWPRegisteredAt}*/}
//                             {/*                    disabled={!isUpdatedetNPWP}*/}
//                             {/*                    onChange={(date) => {*/}
//                             {/*                        // formPersonalDataState.values.dpNPWPJoinDate = date*/}
//                             {/*                        setNPWPRegisteredAt(date)*/}
//                             {/*                    }}*/}
//                             {/*                    maxDate={new Date()}*/}
//                             {/*            // minDate={new Date()}*/}
//                             {/*            //         dateFormat="yyyy-MM-dd"*/}
//                             {/*                    dateFormat="dd-MM-yyyy"*/}
//                             {/*                    value={getNPWPRegisteredAt}*/}
//                             {/*        />*/}
//                             {/*    </div>*/}
//                             {/*</Row>*/}

//                         </>}
//                         {/*NPWP CONTAINER END*/}
//                     </div>
//                     {/*WNI END*/}

//                     {/*WNA START*/}
//                     <div className={"wnaContainer " && isWni ? "d-none" : "d-show"}>
//                         <Col>
//                             <Row style={{marginBottom: "32px"}}>
//                                 <Row>
//                                     <div style={{marginBottom: "16px"}}>Citizenship</div>
//                                 </Row>
//                                 <Select
//                                     name={"etNegara"}
//                                     className="basic-single"
//                                     placeholder={""}
//                                     options={getCzList.sort((a, b) => a.label.localeCompare(b.label))}
//                                     styles={{
//                                         option: (styles, {isFocused, isSelected}) => ({
//                                             ...styles,
//                                             border: 0,
//                                             background: isFocused
//                                                 ? '#f8dbc4'
//                                                 : isSelected
//                                                     ? '#F37F26'
//                                                     : undefined,
//                                             zIndex: 1
//                                         })
//                                     }}
//                                     onChange={
//                                         (e) => {
//                                             formCardVerification.handleChange({
//                                                 target: {
//                                                     name: "etVerifCardCz",
//                                                     value: e.value
//                                                 }
//                                             })
//                                         }}
//                                 />
//                             </Row>

//                             <Row>
//                                 <div style={{marginBottom: "16px"}}>Passport</div>
//                             </Row>
//                             <Col
//                                 className={"boxUpload uploadPlaceHolder d-flex align-items-center " + isPasporViewedPH}
//                                 style={{marginBottom: "32px", position: "relative"}}>
//                                 <Row style={{margin: "auto"}}>
//                                     <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}}>
//                                         <img src="../../content/images/pd_pass_ilustr.jpg" height="100"/>
//                                     </div>
//                                     <div className={"text-center"}><b>Upload Paspor</b></div>
//                                     <div className={"text-center"}>PNG and JPG (Max. File Size 2MB)</div>
//                                 </Row>
//                                 <input className={"uploadField"} type="file" accept="image/*"
//                                        onChange={selectFilePaspor}
//                                        onClick={(event): string => (event.currentTarget.value = "")}/>
//                             </Col>
//                             <Col className={isPasporViewedIMG} style={{marginBottom: "32px"}}>
//                                 <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                     <a style={{cursor: "pointer"}} className={"closeButton"} onClick={() => {
//                                         setPasporViewedIMG("d-none")
//                                         setPasporViewedPH("d-show")
//                                     }}></a>
//                                     <img className={"previewImageContainer"} src={getPreviewImagePaspor} width={323}
//                                          height={204}/>
//                                 </div>
//                             </Col>

//                             <Row>
//                                 <div style={{marginBottom: "16px"}}>Passport Number</div>
//                             </Row>
//                             <Row style={{marginBottom: "32px"}}>
//                                 <div>
//                                     <input
//                                         name="etNoPaspor"
//                                         className="form-control"
//                                         type="tel"
//                                         onChange={(e) => {
//                                             let valueText = e?.target?.value

//                                             formCardVerification.handleChange({
//                                                 target: {
//                                                     name: "etNoPaspor",
//                                                     value: valueText.replace(/[^0-9A-Za-z]+/g, '')
//                                                 }
//                                             })
//                                         }}/>
//                                 </div>
//                             </Row>

//                             <Row>
//                                 <div style={{marginBottom: "16px"}}>Passport Expiry Date</div>
//                             </Row>
//                             <Row style={{marginBottom: "32px"}}>
//                                 <div>
//                                     {/*<input*/}
//                                     {/*    name="etPasporExpired"*/}
//                                     {/*    className="form-control"*/}
//                                     {/*    type="tel"*/}
//                                     {/*    onChange={(e) => {*/}
//                                     {/*    }}/>*/}

//                                     <DatePicker
//                                         style={{textAlign: "right"}}
//                                         className={"form-control"}
//                                         selected={getPassporExp}
//                                         onChange={(date) => {
//                                             // formCardVerification.values.etPasporExp = moment(date).format("YYYY-MM-DD")
//                                             setPassporExp(date)
//                                         }}
//                                         minDate={new Date()}
//                                         dateFormat="dd-MM-yyyy"
//                                         value={getPassporExp}
//                                         showYearDropdown
//                                         // onFocus={e => e.target.blur()}
//                                     />

//                                 </div>
//                             </Row>


//                             <Row style={{marginBottom: "16px"}}>
//                                 <Col>Are you live and working at Indonesia?</Col>
//                                 <Col className={"d-flex justify-content-end flex-grow-0"}
//                                      style={{cursor: "pointer"}} onClick={() => {
//                                     if (isUpdateWorking) {
//                                         setUpdateWorking(false)
//                                         setWorking(formCardVerification.values.etIsWorking)
//                                     } else {
//                                         setUpdateWorking(true)
//                                     }
//                                 }
//                                 }>{isUpdateWorking ? "Cancel" : "Edit"}</Col>
//                             </Row>


//                             <Row style={{marginBottom: "32px"}}>
//                                 <Col className={isWorking ? "d-show" : "d-none"}><RadioSelectIcon/> Yes </Col>
//                                 <Col className={isWorking ? "d-none" : "d-show"}><RadioDeselectIcon onClick={() => {
//                                     if (isUpdateWorking) {
//                                         setWorking(true)
//                                     }
//                                 }}/> Yes</Col>

//                                 <Col className={isWorking ? "d-show" : "d-none"}><RadioDeselectIcon onClick={() => {
//                                     if (isUpdateWorking) {
//                                         setWorking(false)
//                                     }
//                                 }}/> No </Col>
//                                 <Col className={isWorking ? "d-none" : "d-show"}><RadioSelectIcon/> No </Col>
//                             </Row>


//                             <Row className={isWorking ? "d-show" : "d-none"}>
//                                 {/*KITAS*/}
//                                 <Col>
//                                     <Row>
//                                         <div style={{marginBottom: "16px"}}>KITAS</div>
//                                     </Row>
//                                     <Col
//                                         className={"boxUpload uploadPlaceHolder d-flex align-items-center " + isKitasViewedPH}
//                                         style={{marginBottom: "32px", position: "relative"}}>
//                                         <Row style={{margin: "auto"}}>
//                                             <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}}>
//                                                 <img src="../../content/images/pd_kitas_ilustr.png" height="100"/>
//                                             </div>
//                                             <div className={"text-center"}><b>Upload KITAS</b></div>
//                                             <div className={"text-center"}>PNG and JPG (Max. File Size 2MB)</div>
//                                         </Row>
//                                         <input className={"uploadField"} type="file" accept="image/*"
//                                                onChange={selectFileKITAS}
//                                                onClick={(event): string => (event.currentTarget.value = "")}/>
//                                     </Col>
//                                     <Col className={isKitasViewedIMG} style={{marginBottom: "32px"}}>
//                                         <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                             <a style={{cursor: "pointer"}} className={"closeButton"}
//                                                onClick={() => {
//                                                    setKitasViewedIMG("d-none")
//                                                    setKitasViewedPH("d-show")
//                                                }}></a>
//                                             <img className={"previewImageContainer"} src={getPreviewImageKITAS}
//                                                  width={323} height={204}/>
//                                         </div>
//                                     </Col>
//                                 </Col>

//                                 <Row>
//                                     <Col>Permit Number</Col>
//                                 </Row>
//                                 <div style={{marginTop: "16px"}}>
//                                     <Input
//                                         name="etKITAS"
//                                         className="form-control"
//                                         value={formCardVerification.values.etKITAS}
//                                         onChange={(e) => {
//                                             formCardVerification.handleChange({
//                                                 target: {
//                                                     name: "etKITAS",
//                                                     value: e?.target?.value //?.replace(/[^0-9]+/g, '')
//                                                 }
//                                             })
//                                         }}
//                                         disabled={false}
//                                         required={isWorking}/>
//                                 </div>

//                                 <Row style={{marginTop: "20px"}}>
//                                     <div style={{marginBottom: "16px"}}>KITAS Expiry Date</div>
//                                 </Row>
//                                 <div style={{marginBottom: "24px"}}>
//                                     <DatePicker style={{textAlign: "right"}}
//                                                 className={"form-control"}
//                                                 selected={getKitasExp}
//                                                 onChange={(date) => {
//                                                     // formCardVerification.values.etKITASExp = moment(date).format("YYYY-MM-DD")
//                                                     setKitasExp(date)
//                                                 }}
//                                         // maxDate={new Date()}
//                                                 minDate={new Date()}
//                                                 dateFormat="dd-MM-yyyy"
//                                                 value={getKitasExp}
//                                         // onFocus={e => e.target.blur()}
//                                                 showYearDropdown
//                                     />
//                                 </div>

//                                 {/*NPWP*/}

//                                 <div style={{marginBottom: "16px", display: 'flex', alignItems: 'center'}}>
//                                     <Col>NPWP</Col>
//                                     <Col
//                                         className={formCardVerification.values.npwpNo == null ? "d-none" : "d-flex justify-content-end flex-grow-0"}
//                                         style={{cursor: "pointer"}} onClick={() => {
//                                         if (isUpdatedetNPWP) {
//                                             setUpdatedetNPWP(false)
//                                             setNpwpNo(formCardVerification.values.npwpNo)
//                                             // if(formCardVerification.values.npwpIssued != null) {
//                                             //     setNPWPRegisteredAt(toDateHots(formCardVerification.values.npwpIssued))
//                                             // }
//                                             // formPersonalDataState.values.etNPWP = getBioCache.investorNPWPNumber
//                                         } else {
//                                             setUpdatedetNPWP(true)
//                                         }
//                                     }
//                                     }>{isUpdatedetNPWP ? "Cancel" : "Edit"}</Col>
//                                 </div>

//                                 <div style={{marginBottom: "16px"}}
//                                      className={(isWorking && isUpdatedetNPWP) ? "d-show" : "d-none"}>
//                                     <Col
//                                         className={"boxUpload uploadPlaceHolder d-flex align-items-center " + isNPWPViewedPH}
//                                         style={{marginBottom: "32px", position: "relative"}}>
//                                         <Row style={{margin: "auto"}}>
//                                             <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}}>
//                                                 <img src="../../content/images/pd_npwp_ilustr.png" height="100"/>
//                                             </div>
//                                             <div className={"text-center"}><b>Upload NPWP (Tax Registration Card)</b>
//                                             </div>
//                                             <div className={"text-center"}>PNG and JPG (Max. File Size 2MB)</div>
//                                         </Row>
//                                         <input className={"uploadField"} type="file" accept="image/*"
//                                                onChange={selectFileNPWP}
//                                                onClick={(event): string => (event.currentTarget.value = "")}/>
//                                     </Col>
//                                     <Col className={isNPWPViewedIMG} style={{marginBottom: "32px"}}>
//                                         <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                             <a style={{cursor: "pointer"}} className={"closeButton"} onClick={() => {
//                                                 setNPWPViewedIMG("d-none")
//                                                 setNPWPViewedPH("d-show")
//                                             }}></a>
//                                             <img className={"previewImageContainer"} src={getPreviewImageNPWP}
//                                                  width={323}
//                                                  height={204}/>
//                                         </div>
//                                     </Col>
//                                     <Row style={{marginTop: "20px"}}>
//                                         <Col>NPWP Number</Col>
//                                     </Row>
//                                 </div>


//                                 {isWorking &&
//                                     <div>
//                                         <Input
//                                             name="etNPWP"
//                                             className="form-control"
//                                             value={getNpwpNo}
//                                             onChange={(e) => setNpwpNo(e.target.value)}
//                                             disabled={(formCardVerification.values.npwpNo != null) && !isUpdatedetNPWP}
//                                             required={isWorking}/>
//                                     </div>
//                                 }

//                                 {/*<Col>
//                                     <Row>
//                                         <div style={{marginBottom: "16px"}}>NPWP</div>
//                                     </Row>
//                                     <Col
//                                         className={"boxUpload uploadPlaceHolder d-flex align-items-center " + isNPWPWNAViewedPH}
//                                         style={{marginBottom: "32px", position: "relative"}}>
//                                         <Row style={{margin: "auto"}}>
//                                             <div style={{width: '170px', margin: 'auto', marginBottom: '10px'}} >
//                                                 <img src="../../content/images/pd_npwp_ilustr.png" height="100"/>
//                                             </div>
//                                             <div className={"text-center"}><b>Upload NPWP</b></div>
//                                             <div className={"text-center"}>PNG and JPG (Max. File Size 2MB)</div>
//                                         </Row>
//                                         <input className={"uploadField"} type="file" accept="image/*"
//                                                onChange={selectFileNPWPWNA}
//                                                onClick={(event): string => (event.currentTarget.value = "")}/>
//                                     </Col>
//                                     <Col className={isNPWPWNAViewedIMG} style={{marginBottom: "32px"}}>
//                                         <div className="imageRatioKeeper" style={{position: "relative"}}>
//                                             <a style={{cursor: "pointer"}} className={"closeButton"}
//                                                onClick={() => {
//                                                    setNPWPWNAViewedIMG("d-none")
//                                                    setNPWPWNAViewedPH("d-show")
//                                                }}></a>
//                                             <img className={"previewImageContainer"} src={getPreviewImageNPWPWNA}
//                                                  width={323} height={204}/>
//                                         </div>
//                                     </Col>
//                                 </Col>

//                                 <Row style={{marginTop: "20px"}}>
//                                     <Col>No NPWP</Col>
//                                 </Row>
//                                 <Row style={{marginTop: "8px"}}>
//                                     <div>
//                                         <Input
//                                             name="etNPWP"
//                                             className="form-control"
//                                             value={getNpwpNo}
//                                             onChange={(e) => setNpwpNo(e.target.value)}
//                                             disabled={!isUpdatedetNPWP}
//                                             required={false}/>
//                                     </div>
//                                 </Row>*/}


//                             </Row>
//                         </Col>
//                     </div>
//                     {/*WNA END*/}
//                     <div style={{marginTop: '16px'}}/>
//                     <OrangeButton text={LANG().BUTTON.VERIFICATION} disabled={loadingButton}/>
//                 </Col>
//             </AvForm>
//         </div>
//     </div>

 
//     const renderLayout = () => {
//         switch (whichPage) {
//             case Page.LOGIN_PIN:
//                 return loginPinLayout;
//             case Page.REVAMP_CARD:
//                 return revampCard;
//             default:
//                 return <>
//                     <Modal style={{top: "25%"}} isOpen={isOpenModalRejectRevamp}>
//                         <ModalBody>
//                             <p style={{fontSize: "14px", marginTop: "20px", marginBottom: "20px"}}
//                                className="text-center font-r-400">Pengkinian data hanya dapat dilakukan menggunakan akun
//                                 Reguler</p>
//                             <div className="d-flex justify-content-center" onClick={() => {
//                                 setOpenModalRejectRevamp(false)
//                                 window.location.href = "/"
//                             }}>
//                                 <DarkPurpleButton text='OK' width="20%"/>
//                             </div>
//                         </ModalBody>
//                     </Modal>
//                 </>
//         }
//     }

//     return renderLayout()
// };

export default connect()(RevampDataReupload);