import './header.scss';
import '../../../../content/css/font.scss';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import {Col, Modal, ModalBody, Navbar, Row} from 'reactstrap';

import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';

import { Brand } from './header-components';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import LANG from "app/shared/util/lang-constant";
import GeneralSettingConstant from "app/shared/util/general-settings-constant";

import EndPoint from "app/shared/util/api-constant";
import axios from 'axios';
import MyEncrypt from "app/shared/util/security-encrypt";
import MySecKey from "app/shared/util/security-key";
import Axios from "axios";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";


export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
  account?: any,
  logout?: any
}

const Header = (props: IHeaderProps) => {

  const [activeLangColor] = useState("#F37F26")
  const [deactiveLangColor] = useState("#b7b7b7")
  const [isID, setID] = useState(Storage.local.get('locale') == "ID"? true : false)

  const onClickToggle = () => {
    var wrapper = document.getElementById('wrapper');
    wrapper.classList.toggle('menu--is-revealed');

    var drawerWrapper = document.getElementById('wrapper-drawer-content');
    var isOpen = drawerWrapper.classList.contains('slide-in');
    drawerWrapper.setAttribute('class', isOpen ? 'slide-out' : 'slide-in')
  }


  const MYURL = EndPoint();

  useEffect(() => {
    if(isID){
      Storage.local.set('locale', "ID")
    } else {
      Storage.local.set('locale', "EN")
    }
  }, [isID]);

  let history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    props.logout();
  }

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.local.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const getAccTypeCombo = (accTypeCds) => {
    return JSON.parse(accTypeCds).map(v => getAccType(v)).join(", ")
  }

  const getAccType = (accTypeCd) => {
    switch(parseInt(accTypeCd)) {
      case 1: return "Regular";
      case 2: return "Day Trading";
      case 3: return "Margin Trading";
      case 4: return "Syariah";
      default: return "";
    }
  }

  const [isOpenModal, setOpenModal] = useState(false);
  const [getModalTitleMessage, setModalTitleMessage] = useState("");
  const [getModalDescMessage, setModalDescMessage] = useState("");
  const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);

  const handleIsAvailableChangePin = () => {
    Axios.post(MYURL.AVAILABLE_PASSWORD_PIN).then(
        res => {
          if(res.status == 200) {
            if(res.data.code == "OK") {
              window.location.href = "/forgot-pin"
            } else {
              setModalIllustrationStatus(false);
              setOpenModal(true);
              setModalTitleMessage(LANG().FAILED);
              setModalDescMessage(res.data.message);
            }
          } else {
            setModalIllustrationStatus(false);
            setOpenModal(true);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage(LANG().FORM.ERROR.CANNOT_CHANGE_PIN);
          }
        }
    ).catch(
        res => {
          setModalIllustrationStatus(false);
          setOpenModal(true);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage(LANG().FORM.ERROR.CANNOT_CHANGE_PIN);
        }
    )
  }

  if (
    (useLocation().pathname == "/bank-out") ||
    (useLocation().pathname == "/email-phone") ||
    (useLocation().pathname == "/login-pin") ||
    (useLocation().pathname == "/forgot-pin") ||
    (useLocation().pathname == "/forgot-userid") ||
    // (useLocation().pathname == "/567DFGHB89VGYHJBHJ89234BHJNBHN") ||
    (useLocation().pathname == "/revamp-data") ||
    (useLocation().pathname == "/revamp-data-reupload") ||
    (useLocation().pathname == "/move-stock") ||
    (useLocation().pathname == "/activate-rtl") ||
    (useLocation().pathname == "/deactivate-rtl")
  ) {
    return (
      <>
        <Navbar dark expand="sm" fixed="top" className="dms-navbar">
          <Brand />
        </Navbar>
      </>
    );
  } else {

      return (
        <>
          {/* {renderDevRibbon()} */}
              <Navbar dark expand="md" fixed="top" className="dms-navbar">

                <Modal style={{top:"25%"}} isOpen={isOpenModal}>
                  <ModalBody>
                    <div className="text-center mb-4">{getModalIllustrationStatus? <SuccessIllustration/> : <FailedIllustration/>}</div>
                    <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{getModalTitleMessage}</p>
                    <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{getModalDescMessage}</p>
                    <div className="d-flex justify-content-center" onClick={ () => {
                      setOpenModal(false);
                      setModalTitleMessage("");
                      setModalDescMessage("");
                      // window.location.href="/";
                    }}>
                      <DarkPurpleButton text='OK' width="20%"/>
                    </div>
                  </ModalBody>
                </Modal>

                <Row className='d-flex align-items-center'>
                  {/*small device*/}
                  { (Storage.local.get("isLoggedIn", "false") == "true") ?
                  <>
                  <Col style={{padding:0}} className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    <div id="wrapper">
                    <button role="button" className="menu-toggle" onClick={onClickToggle}>
                      <span className="icon-bars"></span>
                    </button>

                      <div id="wrapper-drawer-content" className='slide in'>
                        <Col style={{marginTop:"40px", marginLeft:"45px"}}>
                          <Row>
                            <div className="d-flex justify-content-start align-items-center">
                              <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/user-circle.svg"/>
                              <div className="font-medium right-menu-text">
                                  <div style={{display:"flex", flexDirection:"column"}}>
                                      {props.account.firstName ? props.account.firstName : 'Profile'}
                                      <i style={{fontSize:"12px"}}>{
                                        props.account.otherType != null ?
                                          (' ' + getAccTypeCombo(props.account.otherType)) :
                                          props.account.acntType != null ?
                                            (' ' + getAccType(props.account.acntType)) : ''
                                      }</i>
                                  </div>
                                </div>
                            </div>
                          </Row>

                          {(Storage.local.get("role", GeneralSettingConstant().ROLE_USER) == GeneralSettingConstant().ROLE_USER)?
                          <Row style={{marginTop:"32px"}}>
                            <Link to="forgot-pin" >
                              <div style={{cursor: "pointer"}} className="d-flex align-items-center">
                                <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/lock-closed.svg"/>
                                <span className="font-medium right-menu-text">{LANG().TITLE.FORGOT_PIN}</span>
                              </div>
                            </Link>
                          </Row> : <></>}

                          <Row style={{marginTop:"32px"}}>
                          <div style={{cursor: "pointer"}} className="d-flex align-items-center" onClick={() => handleLogout()}>
                            <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/logout.svg"/>
                            <span className="font-medium right-menu-text">{LANG().BUTTON.LOGOUT}</span>
                          </div>
                          </Row>
                        </Col>
                      </div>
                    </div>
                  </Col>
                  <Col><Brand /></Col>
                  </> :
                  <Col><Brand /></Col>
                  }
                </Row>
                {/*large device*/}
                { (Storage.local.get("isLoggedIn", "false") == "true") ?
                  <>
                  <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                  <ul className="right-menu-container navbar-nav d-flex align-items-center justify-content-end">
                    <li style={{marginRight:"4px"}} className="nav-item">
                      <div className="d-flex justify-content-center">
                        <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/user-circle.svg"/>
                      </div>
                    </li>
                    <li style={{marginRight:"24px"}} className="nav-item">
                      <div className="d-flex justify-content-center">
                        <div className="font-medium right-menu-text">
                            <div style={{display:"flex", flexDirection:"column"}}>
                                {props.account.firstName ? props.account.firstName : 'Profile'}
                                <i style={{fontSize:"12px"}}>{
                                  props.account.otherType != null ?
                                    (' ' + getAccTypeCombo(props.account.otherType)) :
                                          props.account.acntType != null ?
                                      (' ' + getAccType(props.account.acntType)) : ''
                                }</i>
                            </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="vertical-divider">|</div>
                    </li>

                    {(Storage.local.get("role", GeneralSettingConstant().ROLE_USER) == GeneralSettingConstant().ROLE_USER)?
                    <>
                    <li style={{margin:"auto 24px"}} className="nav-item">
                      {/*<Link to="forgot-pin">*/}
                      <div onClick={() => {
                        handleIsAvailableChangePin()
                      }} style={{cursor: "pointer"}} className="d-flex align-items-center" >
                        <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/lock-closed.svg"/>
                        <span className="font-medium right-menu-text">{LANG().TITLE.FORGOT_PIN}</span>
                      </div>
                      {/*</Link>*/}
                    </li>
                    <li className="nav-item">
                      <div className="vertical-divider">|</div>
                    </li>
                    </> : <></>}
                    <li style={{marginLeft:"24px"}} className="nav-item">

                      <div style={{cursor: "pointer"}} className="d-flex align-items-center" onClick={() => handleLogout()}>
                        <img style={{marginRight:"10px"}} width="20px" height="20px" src="../../../../content/Icon/logout.svg"/>
                        <span className="font-medium right-menu-text">{LANG().BUTTON.LOGOUT}</span>
                      </div>
                    </li>
                  </ul>
                  </div>
                  
                  {/* <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none"> */}
                    {/* <Row classNameName='d-flex justify-content-between'>
                      <Col>
                        <img width="20px" height="20px" src="../../../../content/Icon/user-circle.svg"/>
                      </Col>
                      
                      <Col style={{cursor: "pointer"}} classNameName="d-flex align-items-center" onClick={() => handlePIN()} >
                        <img width="20px" height="20px" src="../../../../content/Icon/lock-closed.svg"/>
                      </Col>
                      
                      <Col style={{cursor: "pointer"}} classNameName="d-flex align-items-center" onClick={() => handleLogout()}>
                        <img width="20px" height="20px" src="../../../../content/Icon/logout.svg"/>
                      </Col>
                    </Row> */}
                      {/* <i className="fa fa-bars"></i> */}
                  {/* </div> */}

                  </>
                  : <>
                    {/*<ul className={"navbar-nav d-flex align-items-center justify-content-center"}>
                      {isID ?
                      <li style={{fontSize:"14pt"}}>
                        <b onClick = {() => {
                          setID(!isID)
                          location.reload()
                        }} style={{color:deactiveLangColor, cursor:"pointer"}}>EN</b> | <b onClick = {() => {
                          setID(!isID)
                          location.reload()
                        }} style={{color:activeLangColor, cursor:"pointer"}}>ID</b>
                      </li> :
                      <li style={{fontSize:"14pt"}}>
                        <b onClick = {() => {
                          setID(!isID)
                          location.reload()
                        }} style={{color:activeLangColor, cursor:"pointer"}}>EN</b> | <b onClick = {() => {
                          setID(!isID)
                          location.reload()
                        }} style={{color:deactiveLangColor, cursor:"pointer"}}>ID</b>
                      </li>}
                    </ul>*/}
                    </>
                }

              </Navbar>
        </>
      );

  }
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
})

const mapDispatchToProps = {
  logout
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
