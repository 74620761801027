import axios from "axios"
import EndPoint from 'app/shared/util/api-constant';
import { ACTION_TYPES } from "app/shared/reducers/tax-report-reducer";

export const getTaxReportDownloadList: (data) => void = (data) => async (dispatch, getState) => {
    const MYURL = EndPoint();
    
    await dispatch({
        type: ACTION_TYPES.TAXREPORT_DOWNLOAD_LIST,
        payload: axios.post(MYURL.TAXREPORT_DOWNLOAD_LIST, data)

    })
}

export const requestTaxReport: (data) => void = (data) => async (dispatch, getState) => {
    const MYURL = EndPoint();
    await dispatch({
        type: ACTION_TYPES.TAXREPORT_REQUEST_DOWNLOAD,
        payload: axios.post(MYURL.REQUEST_TAX_REPORT_DOWNLOAD, data)

    })

}

export const requestDownloadTaxReport: (data) => void = (data) => async (dispatch, getState) => {
    const MYURL = EndPoint();
    await dispatch({
        type: ACTION_TYPES.TAXREPORT_REQUEST_DOWNLOAD,
        payload: axios.get(`${MYURL.DOWNLOAD_TAX_REPORT}?account-no=${data.accountNO}&year=${data.year}`)

    })

}

export const requestTaxReportDownloadList: (userId) => void = (userId) => async (dispatch, getState) => {
    const MYURL = EndPoint();
    await dispatch({
    type: ACTION_TYPES.TAXREPORT_DOWNLOAD_LIST,
        payload: axios.get(`${MYURL.TAXREPORT_DOWNLOAD_LIST}?userId=${userId}`)
    })

}