import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import TaxReportDownloadList from "./tax-report-download-list";
import { IRootState } from "app/shared/reducers";
import { requestTaxReportDownloadList, requestTaxReport, requestDownloadTaxReport } from "app/api/tax-report-api";
import { Button, Col, Container, Row } from "reactstrap";
import InfoOrangeIcon from "app/component/info-orange-icon";
import { Storage, translate } from 'react-jhipster';

const downloads = [
    { name: "TAX-REPORT", year: "2022", expired: true, downloadLink: '' },
    { name: "TAX-REPORT", year: "2022", expired: true, downloadLink: '' },
    // Add more download items as needed
];


const TaxReportDownload = (props: any) => {
    let { downloadList } = props?.taxReport
    let { ypId } = props
    let [download, setDownload] = useState(downloads)

    useEffect(() => {
        // console.log(props?.taxReport)
        // console.log("=====>",ypId)
        let userid = Storage.local.get('username')
        props.requestTaxReportDownloadList(userid)
    }, [props.ypId])

    // useEffect(() => {console.log('props.taxReport.downloadList ==>',downloadList)},[downloadList])

    const body = {
        accountNo: '133424',
        year: '2022'
    }

    const onClickRequestTaxReport = async (accountNo, year) => {
        console.log('here')
        props.requestTaxReport(body)
    }

    const onClickRequestDownloadList = async () => {
        // props.requestTaxReportDownloadList('ahmadrafi')
        let userid = Storage.local.get('username')
        props.requestTaxReportDownloadList(userid)
    }

    const onClickRequestDownload = () => {
        props.onClickRequestDownload()

    }
    return (
        
        <div className="container mt-4">

            <Container >
                {/*large device*/}
                <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                    <Row >
                        <Col xs={12} className="mt-4">
                            <div className="d-flex justify-content-between">
                                <div style={{ fontSize: '24px', fontWeight: 700, color: '#0A0A0A' }}>{translate('report.title')}</div>
                                <Button
                                    style={{ backgroundColor: "#F37F26", cursor: "pointer" }}
                                    className="button btn-primary border border-0 rounded-2"
                                    onClick={() => onClickRequestDownload()}
                                >
                                    {translate('report.btn.downloadTaxReport')}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} className="mt-4">
                            <p style={{ fontSize: '14px' }}>
                                {translate('report.howTo.title')}
                            </p>
                            <p style={{ fontSize: '14px' }}>
                                {translate('report.howTo.one')}<br />
                                {translate('report.howTo.two')}<br />
                                {translate('report.howTo.three')}
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <div className="row" style={{
                                backgroundColor: "#FFF4EB",
                                borderRadius: "4px"
                            }}>
                                <span style={{ fontSize: "14px", marginBottom: '8px' }}>
                                {translate('report.example.title')}<br /><b>19950608</b>
                                </span>
                                <span style={{ fontSize: "14px" }}>
                                    {translate('report.example.y')}<br />
                                    {translate('report.example.m')}<br />
                                    {translate('report.example.d')}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <div className="d-flex bd-highlight">
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-middle align-items-center">
                                    <div style={{ fontSize: '20px', fontWeight: 700, color: '#0A0A0A' }}>
                                        {translate('report.downloadList')}
                                    </div>
                                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#404040' }}>
                                        {translate('report.redownloadText')}
                                    </div>
                                </div>
                            </div>
                            <div className="bd-highlight">
                                <Button
                                    style={{ backgroundColor: "#043B72", cursor: "pointer", marginLeft: '8px' }}
                                    onClick={() => onClickRequestDownloadList()}
                                >
                                    {translate('report.btn.refresh')}
                                </Button>
                            </div>
                        </div>
                        <Col xs={12} className="mt-2">
                            <div className="d-flex p-2" style={{
                                backgroundColor: "#FFF4EB",
                                borderRadius: "4px"
                            }}>
                                <div className="pe-2">
                                    <InfoOrangeIcon />
                                </div>
                                <div>
                                    <span style={{ fontSize: "12px" }}>{translate('report.info')}</span>
                                </div>
                            </div>

                        </Col>
                        <Col xs={12} className="mt-2" style={{overflowX: 'auto'}}>
                            <TaxReportDownloadList
                                downloads={downloadList} />
                        </Col>
                    </Row>
                </div>

                {/*small device*/}
                <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    <Row >
                        <Col xs={12} className="mt-4">
                            <div className="d-flex justify-content-between">
                                <div style={{ fontSize: '20px', fontWeight: 700, color: '#0A0A0A' }}>{translate('report.title')}</div>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} className="mt-4">
                            <p style={{ fontSize: '12px' }}>
                                {translate('report.howTo.title')}
                            </p>
                            <p style={{ fontSize: '12px' }}>
                                {translate('report.howTo.one')}<br />
                                {translate('report.howTo.two')}<br />
                                {translate('report.howTo.three')}
                            </p>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <div className="row" style={{
                                backgroundColor: "#F7F9FA",
                                borderRadius: "4px"
                            }}>
                                <span style={{ fontSize: "12px", marginBottom: '8px' }}>
                                {translate('report.example.title')}<br /><b>19950608</b>
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                    {translate('report.example.y')}<br />
                                    {translate('report.example.m')}<br />
                                    {translate('report.example.d')}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <div className="d-flex bd-highlight">
                            <div className="flex-grow-1">
                                <div style={{ fontSize: '16px', fontWeight: 700, color: '#0A0A0A' }}>
                                    {translate('report.downloadList')}
                                </div>
                                <div style={{ fontSize: '12px', fontWeight: 400, color: '#404040' }}>
                                    {translate('report.redownloadText')}
                                </div>
                            </div>
                            <div className="bd-highlight">
                                <Button
                                    style={{ backgroundColor: "#043B72", cursor: "pointer", marginLeft: '8px' }}
                                    onClick={() => onClickRequestDownloadList()}
                                >
                                    {translate('report.btn.refresh')}
                                </Button>
                            </div>
                        </div>
                        <Col xs={12} className="mt-2">
                            <div className="d-flex p-2" style={{
                                backgroundColor: "#FFF4EB",
                                borderRadius: "4px"
                            }}>
                                <div className="pe-2">
                                    <InfoOrangeIcon />
                                </div>
                                <div>
                                    <span style={{ fontSize: "10px" }}>{translate('report.info')}</span>
                                </div>
                            </div>

                        </Col>
                        <Col xs={12} className="mt-2" style={{overflowX: 'auto'}}>
                            <TaxReportDownloadList
                                downloads={downloadList} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Button
                            style={{ backgroundColor: "#F37F26", cursor: "pointer" }}
                            className="button btn-primary border border-0 rounded-2"
                            onClick={() => onClickRequestDownload()}
                        >
                            {translate('report.btn.downloadTaxReport')}
                        </Button>
                    </Row>
                </div>
            </Container>

        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    // account: storeState.authentication.account
    taxReport: storeState.taxReportState

})

const mapDispatchToProps = {
    requestTaxReport,
    requestDownloadTaxReport,
    requestTaxReportDownloadList
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxReportDownload);