import * as React from "react"
import { SVGProps } from "react"

const WarningIllustration = (props: SVGProps<SVGSVGElement>) => (
    <svg
    width={32}
    height={32}
    fill="#CB3A31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM18 24C18 25.1046 17.1046 26 16 26C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24ZM16 6C14.8954 6 14 6.89543 14 8V16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6Z" fill="#CD7B2E"/>
    </svg>
)

export default WarningIllustration