import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from 'react-jhipster';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <div {...props} className="brand-icon" >
    <img src="content/images/logo-mirae.png" alt="Logo" style={{cursor: "pointer"}} onClick={() => {
        {Storage.local.get("role") == "USER" ? window.location.href = "/" : window.location.href = "/log"}
    }}/>
  </div>
);

export const Brand = props => (
  <NavbarBrand className="brand-logo">
    <BrandIcon />
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);