import * as React from "react"
import { SVGProps } from "react"

const MenuRevampPersonalData = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={20}
        height={20}
        fill={props.fill}
        xmlns="http://www.w3.org/2000/svg"
        {...props}>

        <path d="M12 5C12 7.20914 10.2091 9 8 9C5.79086 9 4 7.20914 4 5C4 2.79086 5.79086 1 8 1C10.2091 1 12 2.79086 12 5Z" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 12C4.13401 12 1 15.134 1 19H15C15 15.134 11.866 12 8 12Z" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

    </svg>
)

export default MenuRevampPersonalData