import * as React from "react"
import { SVGProps } from "react"

const MenuRevampArrowUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
    width={12}
    height={7.2}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6485 7.24892C11.1799 7.71755 10.4201 7.71755 9.95147 7.24892L6 3.29745L2.04853 7.24892C1.5799 7.71755 0.820101 7.71755 0.351472 7.24892C-0.117157 6.78029 -0.117157 6.02049 0.351472 5.55186L5.15147 0.751863C5.6201 0.283233 6.3799 0.283233 6.84853 0.751863L11.6485 5.55186C12.1172 6.02049 12.1172 6.78029 11.6485 7.24892Z" fill="#0A0A0A"/>
    </svg>
)

export default MenuRevampArrowUp