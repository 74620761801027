import React from "react";
import useWindowDimensions from 'app/shared/util/screen-dimension';

export const IFrameLoading = () => {
    const { width } = useWindowDimensions();
    return (
        <>
            <div style={{ background: '#fff' }} className="clearfix container-fluid">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: width < 600 ? '800px' : '100%' }}>
                    <img
                        loading="lazy"
                        src="./content/images/logoloading.png"
                        style={{ objectFit: 'none', width: '100%', height: '100%' }}
                    />
                </div>
                <img src="./content/images/supportbymirae.png" className="centerDown" />
            </div>
        </>
    );
}

export default IFrameLoading;