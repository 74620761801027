import axios from "axios"
import EndPoint from 'app/shared/util/api-constant';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ACTION_TYPES } from "app/shared/reducers/h5-livenes";


/* H5 Liveness */
export const P_LIVENESS_DETECTION_TOKEN = "/services/hotsregistration/api/v1/face-recognition/h5-liveness-detection/token"
export const P_LIVENESS_DETECTION_RESULT = "/services/hotsregistration/api/v1/face-recognition/h5-liveness-detection/result"
// export const P_LIVENESS_DETECTION_VALIDATE = "/services/hotsregistration/api/v1/face-recognition/validate/v2"
export const P_LIVENESS_DETECTION_VALIDATE = "/services/hotsregistration/api/v1/face-recognition/h5-liveness-detection/result"


export async function checkSpoofing(file) {
    const MYURL = EndPoint();
    let formData = new FormData();
    // console.log(data)
    console.log('checkSpoofing', file)
    // formData.append("faceImage", await fetch(file).then(r => r.blob()));
    formData.append("faceImage", file);
    return await axios.post(MYURL.CHECK_SPOOFING, formData, {headers: {"Content-Type": "application/json"}})
    .then(
        response => {
            return response.data
        },
        error => {
            return { error: error.response.data.message  }
        })
    .catch(error => {
         return {catch: error}})
}

export async function performOcr(file) {
    const MYURL = EndPoint();
    const formData = new FormData();
    // formData.append("citizenship", "85")
    // formData.append("country", "112")
    formData.append("ktp_pic", file);
    // console.log(formData)
    return await axios.post(MYURL.OCR_CARD, formData, {headers: {"Content-Type": "multipart/form-data"}})
    .then(
        response => {
            return {success: response}
        },
        error => {
            return { error: error.response  }
        })
    .catch(error => {
         return {catch: error}})

}

export const livenessDetectionToken: (data) => void = (data) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.LIVENESS_DETECTION_TOKEN,
        payload: axios.post(P_LIVENESS_DETECTION_TOKEN, data)
    })  
}

export const livenessDetectionResult: (data) => void = (data) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.LIVENESS_DETECTION_RESULT,
        payload: axios.post(P_LIVENESS_DETECTION_RESULT, data)
    })
}

export const livenessDetectionValidate: (data) => void = (data) => async (dispatch, getState) => {
    // console.log('livenessDetectionValidate', data)
    await dispatch({
        type: ACTION_TYPES.LIVENESS_DETECTION_VALIDATE,
        payload: axios.post(P_LIVENESS_DETECTION_VALIDATE, data)
    })
}

export const livenessDetectionReset: () => void = () => async(dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.LIVENESS_DETECTION_RESET,
        payload: {}
    })

}