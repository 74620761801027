import React from 'react';
import {Button, ButtonProps, Spinner} from 'reactstrap';

const OrangeButton = (props: ButtonProps) => {
    return (
        <Button type="submit" disabled={props.disabled}
                className={(props.margin == null) ? "py-2 h2 text-white font-weight-bold mt-4 rounded" : "py-2 h2 text-white font-weight-bold mt-0 rounded"}
                style={{backgroundColor: '#F37F26', width: '100%'}}
            onClick={props.onClick}>
            {(props.disabled)? <Spinner color='#fff' size="sm" style={{marginRight: "8px" }}/> : <></>}
            {props.text}
        </Button>
    );
}

export default OrangeButton;