import * as React from "react"
import { SVGProps } from "react"

const NotFoundIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={332}
    height={237}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.3}
      d="M56.288 99.306c-28.075 4.984-47.918 29.435-44.66 56.261 1.735 14.21 8.77 26.827 26.867 30.45 48.01 9.653 229.238 45.99 264.844-16.97 27.76-49.065 11.216-83.037-14.566-104.302-25.781-21.264-79.169-16.849-123.352-2.294-32.856 10.85-57.287 27.639-109.133 36.855Z"
      fill="#E6E6E6"
    />
    <path
      opacity={0.45}
      d="M167.684 236.524c90.263 0 163.435-5.408 163.435-12.079 0-6.67-73.172-12.078-163.435-12.078S4.249 217.775 4.249 224.445c0 6.671 73.172 12.079 163.435 12.079Z"
      fill="#E6E6E6"
    />
    <path
      d="M76.05 56.747c10.73 0 19.427-8.698 19.427-19.427 0-10.73-8.698-19.427-19.427-19.427-10.73 0-19.427 8.698-19.427 19.427 0 10.73 8.697 19.427 19.427 19.427Z"
      fill="#FFD200"
    />
    <path
      opacity={0.15}
      d="M76.05 74.357c20.455 0 37.037-16.582 37.037-37.037S96.505.283 76.05.283 39.013 16.865 39.013 37.32 55.595 74.357 76.05 74.357Z"
      fill="#FFD200"
    />
    <path
      d="M157.625 223.887c28.685 0 51.938-23.253 51.938-51.937 0-28.685-23.253-51.938-51.938-51.938-28.684 0-51.937 23.253-51.937 51.938 0 28.684 23.253 51.937 51.937 51.937Z"
      fill="#002F48"
    />
    <path
      d="M157.625 204.643c18.056 0 32.693-14.638 32.693-32.693 0-18.056-14.637-32.694-32.693-32.694-18.056 0-32.693 14.638-32.693 32.694 0 18.055 14.637 32.693 32.693 32.693Z"
      fill="#fff"
    />
    <path
      d="M94.919 184.343c3.44 0 4.679 1.238 4.679 4.669v5.917c0 3.441-1.015 4.679-4.68 4.679h-9.49v18.017c0 3.43-1.238 4.669-4.679 4.669h-6.74c-3.43 0-4.668-1.239-4.668-4.669v-18.017H23.96c-3.43 0-4.669-1.238-4.669-4.679v-4.953a9.134 9.134 0 0 1 1.624-5.633l45.675-56.668a8.196 8.196 0 0 1 6.597-3.299H80.8c3.44 0 4.68.965 4.68 4.68v55.287h9.439ZM69.34 147.478 39.49 184.343h29.85v-36.865ZM291.331 184.343c3.441 0 4.679 1.238 4.679 4.669v5.917c0 3.441-.964 4.679-4.679 4.679h-9.49v18.017c0 3.43-1.238 4.669-4.679 4.669h-6.74c-3.43 0-4.669-1.239-4.669-4.669v-18.017h-45.39c-3.431 0-4.669-1.238-4.669-4.679v-4.953a9.126 9.126 0 0 1 1.644-5.633l45.675-56.668a8.193 8.193 0 0 1 6.597-3.299h7.562c3.441 0 4.679.965 4.679 4.68v55.287h9.48Zm-25.578-36.865-29.851 36.865h29.851v-36.865Z"
      fill="#F37F26"
    />
    <path
      d="M163.908 103.752s1.421 6.851.802 11.601a3.51 3.51 0 0 1-3.969 3.045c-2.385-.345-5.511-1.502-6.719-5.075l-2.801-5.836a6.307 6.307 0 0 1 1.959-7.004c3.593-3.309 10.068-.903 10.728 3.269Z"
      fill="#F4A28C"
    />
    <path
      d="m151.728 108.512-.913 22.949 12.677-.406-4.425-16.534-7.339-6.009Z"
      fill="#F4A28C"
    />
    <path
      d="M162.213 104.239a27.763 27.763 0 0 1-6.465.274 5.849 5.849 0 0 1 .751 6.364 4.75 4.75 0 0 1-5.481 2.558l-.944-8.953a7.098 7.098 0 0 1 2.842-6.74 24.702 24.702 0 0 1 2.811-1.816c2.446-1.34 6.415-.071 8.516-2.08a1.697 1.697 0 0 1 2.361.045c.205.208.353.465.43.746.731 2.67.751 7.003-2.75 8.922a6.475 6.475 0 0 1-2.071.68Z"
      fill="#002F48"
    />
    <path
      d="M157.219 110.583s-.365-2.68-2.354-2.233c-1.99.446-1.482 4.313 1.299 4.344l1.055-2.111ZM164.578 108.116l2.264 2.446a1.122 1.122 0 0 1-.498 1.837l-2.598.812.832-5.095Z"
      fill="#F4A28C"
    />
    <path
      opacity={0.31}
      d="M160.193 118.104a8.364 8.364 0 0 1-4.364-1.949s.67 4.151 5.745 7.724l-1.381-5.775Z"
      fill="#CE8172"
    />
    <path
      d="m150.835 131.461 12.677-.406s19.915-3.38 26.827 12.86c6.912 16.24-1.949 39.189-1.949 39.189s-7.207 24.228-37.555 21.549c0 0-25.243-1.462-28.095-36.063a33.802 33.802 0 0 0-.69-4.486c-1.158-5.41-1.827-21.823 28.785-32.643Z"
      fill="#F37F26"
    />
    <path
      opacity={0.08}
      d="M133.854 153.02s6.76.741 16.149 16.463c9.388 15.722 27.821 9.957 38.214-1.675l-19.285 25.517-21.599-1.735-11.743-31.262-1.736-7.308Z"
      fill="#0A0A0A"
    />
    <path
      d="m207.88 116.629-3.713.496 1.444 10.805 3.712-.496-1.443-10.805Z"
      fill="#FFD200"
    />
    <path
      opacity={0.08}
      d="m207.88 116.629-3.713.496 1.444 10.805 3.712-.496-1.443-10.805Z"
      fill="#0A0A0A"
    />
    <path
      d="m209.966 124.296-5.704.762 1.854 13.874 5.704-.763-1.854-13.873Z"
      fill="#002F48"
    />
    <path
      d="M202.6 94.272a12.285 12.285 0 0 0-10.239 9.02 12.282 12.282 0 1 0 10.239-9.02Zm2.781 20.766a8.683 8.683 0 0 1-8.665-4.238 8.681 8.681 0 0 1 9.733-12.748 8.676 8.676 0 0 1 4.678 13.638 8.686 8.686 0 0 1-5.705 3.348h-.041Z"
      fill="#FFD200"
    />
    <path
      d="M204.295 115.201a8.72 8.72 0 1 0 0-17.439 8.72 8.72 0 0 0 0 17.439Z"
      fill="#fff"
    />
    <path
      d="M122.415 154.725a6.268 6.268 0 0 1 5.145-4.629 6.271 6.271 0 0 1 6.274 2.924 143.75 143.75 0 0 1 12.18 24.756c7.257 19.345 42.843 6.587 55.977-38.52l7.491 4.659s-10.333 58.586-51.928 60.738c0 0-25.963 5.257-33.85-26.603 0 0-2.03-5.999-2.152-9.389l-.548-3.816a32.165 32.165 0 0 1 1.34-10.019l.071-.101Z"
      fill="#F37F26"
    />
    <path
      opacity={0.39}
      d="M122.415 154.725a6.268 6.268 0 0 1 5.145-4.629 6.271 6.271 0 0 1 6.274 2.924 143.75 143.75 0 0 1 12.18 24.756c7.257 19.345 42.843 6.587 55.977-38.52l7.491 4.659s-10.333 58.586-51.928 60.738c0 0-25.963 5.257-33.85-26.603 0 0-2.03-5.999-2.152-9.389l-.548-3.816a32.165 32.165 0 0 1 1.34-10.019l.071-.101Z"
      fill="#fff"
    />
    <path
      d="M203.889 140.393s.761-9.653 4.74-9.632c3.979.02 13.327 7.166-1.106 11.885l-3.634-2.253Z"
      fill="#F4A28C"
    />
    <path
      d="M307.642 66.694a6.864 6.864 0 0 0-6.861-6.861c-.371 0-.741.03-1.106.091a9.238 9.238 0 0 0-8.12-4.872h-.335a10.983 10.983 0 1 0-21.315 0h-.335a9.261 9.261 0 0 0-6.743 2.599 9.25 9.25 0 0 0-2.821 6.653 9.25 9.25 0 0 0 2.821 6.653 9.256 9.256 0 0 0 6.743 2.598h32.104v-.06a6.868 6.868 0 0 0 5.968-6.801ZM40.91 134.608a5.886 5.886 0 0 0-5.886-5.887 7.105 7.105 0 0 0-.944.081 7.93 7.93 0 0 0-6.994-4.172h-.284a9.418 9.418 0 0 0-16.563-8.088 9.42 9.42 0 0 0-1.707 8.088h-.284a7.948 7.948 0 0 0-5.795 2.22 7.942 7.942 0 0 0-1.795 8.815 7.927 7.927 0 0 0 4.466 4.309 7.93 7.93 0 0 0 3.124.521h27.537v-.051a5.9 5.9 0 0 0 5.126-5.836Z"
      fill="#E6E6E6"
    />
  </svg>
);

export default NotFoundIllustration
