import React, { useEffect, useRef, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
// import { ModalRegisterFormCons, ModalTakePictureCons } from "app/shared/util/modal-constants";
import { Modal, ModalBody } from "reactstrap";
import 'react-html5-camera-photo/build/css/index.css';
import useWindowDimensions from 'app/shared/util/screen-dimension';
import CloseIcon from 'app/component/close-icon';

function ModalTakePictureSelfie(props) {

    const { width } = useWindowDimensions();
    const [valueOnLoad, setValueOnLoad] = useState<Boolean>(false);
    const [count, setCount] = useState(1);

    const handleTakePhoto = (dataUri) => {
        props.closeTakePictSelfie(dataUri)
    }

    const handleOnLoad = (e) => {
        
        if (valueOnLoad == false) {
            setValueOnLoad(true)
        } else {
            setValueOnLoad(false)
            props.closeTakePictSelfie('dataUri');
        }
    }

    const closeModalSelfie = () => {
        props.closeModalSelfie()
    }

    return (
        <Modal
            modalClassName={"d-flex align-items-center"}
            isOpen={props.isOpenSelfie}
            style={{
                minWidth: width < 600 ? '95%' : '700px',
                height: width < 600 ? '500px' : '700px'
            }}>
            <ModalBody>
                <div className="col-sm-12 d-flex justify-content-end">
                    <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={closeModalSelfie}>
                        <CloseIcon />
                    </button>
                </div>
                <div className={"d-flex justify-content-center align-items-center"}
                    style={{ height: width < 600 ? '500px' : '700px' }}>
                    <iframe
                        id={"iframe_id"}
                        allow="camera *;microphone *"
                        style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
                        src={props.livenessUrl}
                        onLoad={(e) =>
                            handleOnLoad(e)
                        }
                    >
                    </iframe>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalTakePictureSelfie;