import * as React from "react"
import { SVGProps } from "react"

const CloseAuditTrail = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={32}
        height={32}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86862 6.86902C7.49346 6.24418 8.50653 6.24418 9.13136 6.86902L16 13.7376L22.8686 6.86902C23.4935 6.24418 24.5065 6.24418 25.1314 6.86902C25.7562 7.49386 25.7562 8.50692 25.1314 9.13176L18.2627 16.0004L25.1314 22.869C25.7562 23.4939 25.7562 24.5069 25.1314 25.1318C24.5065 25.7566 23.4935 25.7566 22.8686 25.1318L16 18.2631L9.13136 25.1318C8.50653 25.7566 7.49346 25.7566 6.86862 25.1318C6.24378 24.5069 6.24378 23.4939 6.86862 22.869L13.7373 16.0004L6.86862 9.13176C6.24378 8.50692 6.24378 7.49386 6.86862 6.86902Z" fill="#404040"/>
    </svg>

)

export default CloseAuditTrail