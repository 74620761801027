import axios from 'axios';

import { SUCCESS, REQUEST, FAILURE } from 'app/shared/reducers/action-type.util';

import { stat } from 'fs';

export const ACTION_TYPES = {
    TAXREPORT_REQUEST_DOWNLOAD: 'taxReport/REQUEST_DOWNLOAD', // TAXREPORT_REQUEST_DOWNLOAD
    TAXREPORT_DOWNLOAD_LIST: 'taxReport/DOWNLOAD_LIST', // TAXREPORT_DOWNLOAD_LIST
};

const initialState = {
    loading: false,
    responseCode: '',
    responseMessage: '',
    downloadList: [],

};

export type TaxReportState = Readonly<typeof initialState>

export default (state: TaxReportState = initialState, action): TaxReportState => {

    switch (action.type) {
        case REQUEST(ACTION_TYPES.TAXREPORT_REQUEST_DOWNLOAD):

            return {
                ...state,
                loading: true
            }

        case SUCCESS(ACTION_TYPES.TAXREPORT_REQUEST_DOWNLOAD):

            const data = action.payload?.data;

            console.log(data);

            return {
                ...state,
                loading: false,
                responseCode: data.responseCode,
                responseMessage: data.responseMessage

            }

        case FAILURE(ACTION_TYPES.TAXREPORT_REQUEST_DOWNLOAD):

            return {
                ...state,
                loading: false,
            }
        case REQUEST(ACTION_TYPES.TAXREPORT_DOWNLOAD_LIST):

            return {
                ...state,
                loading: true
            }

        case SUCCESS(ACTION_TYPES.TAXREPORT_DOWNLOAD_LIST):

            const data_list = action.payload?.data;
            // console.log('testing', data_list)

            // console.log(data);

            return {
                ...state,
                loading: false,
                downloadList: data_list
                // responseCode: data.responseCode,
                // responseMessage: data.responseMessage

            }

        case FAILURE(ACTION_TYPES.TAXREPORT_DOWNLOAD_LIST):

            return {
                ...state,
                loading: false,
            }

        default:
            return state;

    }

}