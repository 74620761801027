import FailedIllustration from "app/component/failed-illustration";
import SuccessIllustration from "app/component/success-illustration";
import React from "react"
import { Modal, ModalBody } from "reactstrap";
import TaxInformation from "./tax-information";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";
import OrangeButton from "app/shared/layout/my-button/orange-button";
import { translate } from "react-jhipster";

const ModalPhoneLayout = ({
    isOpenModalOtpInputPhone,
    getModalIllustrationStatus,
    getModalTitleMessage,
    getModalDescMessage,
    onClick
 }) => {

    function modalOnClick() {
        onClick()
    }

    return (
        <Modal style={{ top: '25%' }} isOpen={isOpenModalOtpInputPhone}>
            <ModalBody>
                <div className="text-center mb-4">
                    {getModalIllustrationStatus ? (
                        <img src="./content/images/success-download-tax.svg" />
                    ) : (
                        <FailedIllustration />
                    )}
                </div>
                <p
                    style={{ fontSize: '20px' }}
                    className="text-center mb-2 font-m-500"
                >
                    {getModalIllustrationStatus ? (
                        translate('report.titleSuccesDownload')
                    ) : getModalTitleMessage}
                </p>
                <p
                    style={{ fontSize: '14px' }}
                    className="text-center mb-3 font-r-400"
                >
                    {getModalIllustrationStatus ? (
                        translate('report.descSuccesDownload')
                    ) : getModalDescMessage}
                    {/* {getModalDescMessage} */}
                </p>
                {/* <TaxInformation /> */}
                <div
                    className="d-flex justify-content-center"
                    onClick={() => modalOnClick()}
                >
                    
                    {getModalIllustrationStatus ? (
                        <OrangeButton text={translate('report.btn.backTo')} width="20%" />
                    ) : (
                        <DarkPurpleButton text="OK" width="20%" />
                    )}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalPhoneLayout;


