import * as React from "react"
import {SVGProps} from "react"

const InfoOrangeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
              d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="#F58220" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        />

    </svg>
)

export default InfoOrangeIcon
