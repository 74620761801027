import React, { useEffect, useRef, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
// import { ModalRegisterFormCons, ModalTakePictureCons } from "app/shared/util/modal-constants";
import { Modal, ModalBody } from 'reactstrap';
import 'react-html5-camera-photo/build/css/index.css';
import useWindowDimensions from 'app/shared/util/screen-dimension';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    console.log('Windows Phone');
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    console.log('Android');
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    console.log('iOS');
    return 'iOS';
  }
  return 'unknown';
}

function ModalTakePicture(props) {
  const [isMobile, setIsMobile] = useState(false);
  const [mobileType, setMobileType] = useState('');

  useEffect(() => {
    setIsMobile(getMobileOperatingSystem() != "unknown")
    setMobileType(getMobileOperatingSystem());
  }, []);

  useEffect(() => {
      console.log(isMobile)
      if(isMobile){
          console.log('go here')
          navigator.mediaDevices.enumerateDevices().then(e => {
              window.maindevice = e.filter(input => input.kind == "videoinput" && input.label.includes("0"))
          })
      }
  },[isMobile])

  function initCameraMain() {
      if(isMobile && mobileType != "iOS" && props.fileType == "ktp") {
          setTimeout(() => {
              document.querySelector("video").srcObject.getTracks().forEach(track=> {track.stop()})
              navigator.mediaDevices.getUserMedia({
                  video: {
                      deviceId: { exact: window.maindevice[0].deviceId },
                  },
              }).then(stream => {
                  console.log(stream)
                  document.querySelector("video").srcObject = stream
              })
          }, 100);
      }
  }

  const { width } = useWindowDimensions();
  const [stream, setStream] = useState(null);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [errorMediaBrowser, setErrorMediaBrowser] = useState('');

  const handleTakePhoto = (dataUri) => {
    props.closeTakePict(dataUri, props.fileType);
    // Stop the stream
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const handleTakePhotoKTP = (dataUri) => {
    props.closeTakePict(dataUri, props.fileType);
    // Stop the stream
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setErrorMediaBrowser('');
  };

  useEffect(() => {
    if (props.fileType == 'ktp') {
      const requestCameraPermission = async () => {
        try {
          if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
            console.log('mediaStream', mediaStream);
            setStream(mediaStream);
          } else {
            setErrorMediaBrowser(
              'navigator.mediaDevices.getUserMedia is not supported in this browser.'
            );
          }
        } catch (error) {
          console.error('Error accessing camera:', error);
          if (
            error.name === 'NotAllowedError' ||
            error.name === 'PermissionDeniedError'
          ) {
            setPermissionDenied(true);
          }
        }
      };
      // Call the function to request camera permission
      // requestCameraPermission();
      // Cleanup function to stop the camera when the component unmounts
      return () => {
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      };
    }
  }, [props.isOpen]);

  const handleDynamicCamera = () => {
    if (props.fileType == 'ktp') {
      return (
        <>
          {permissionDenied ? (
            <p>
              {errorMediaBrowser == ''
                ? 'Camera permission denied. Please allow access to the camera in your browser settings.'
                : errorMediaBrowser}
            </p>
          ) : (
            <Camera
              // idealResolution = {{width: 40, height: 30}}
              idealFacingMode={FACING_MODES.ENVIRONMENT} // show only primary camera
              // imageCompression={0.97}
              imageCompression={0.8}
              isImageMirror={false}
              isSilentMode={true}
              isDisplayStartCameraError={true}
              isFullscreen={false}
              sizeFactor={1}
              // isDisplayStartCameraError={true}
              // sizeFactor={0.5}
              onTakePhoto={(dataUriKTP) => {

                if(isMobile && mobileType != "iOS" && props.fileType == "ktp" ){
                  // stop track here
                  document.querySelector("video").srcObject.getTracks().forEach(track=> {track.stop()})
              }
                handleTakePhotoKTP(dataUriKTP);
              }}
              onCameraError={(error) => {
                console.log('onCameraError', error.message);
              }}
              onCameraStart={() => {
                initCameraMain()
              }}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <Camera
            // idealResolution = {{width: 40, height: 30}}
            // idealFacingMode={mobileType == "iOS" && props.fileType == "ktp" && FACING_MODES.ENVIRONMENT} // show only primary camera
            imageCompression={0.97}
            isImageMirror={true}
            isSilentMode={false}
            isDisplayStartCameraError={true}
            isFullscreen={false}
            sizeFactor={1}
            onCameraStart={() => {
              // initCameraMain()
            }}
            onCameraStop={() => {}}
            onTakePhoto={(dataUri) => {
              // if(isMobile && mobileType != "iOS" && props.fileType == "ktp" ){
              //     // stop track here
              //     document.querySelector("video").srcObject.getTracks().forEach(track=> {track.stop()})
              // }

              handleTakePhoto(dataUri);
              // console.log(dataUri)
            }}
            onCameraError={(error) => {
              console.log(error.message);
            }}
          />
        </>
      );
    }
  };

  return (
    <Modal
      modalClassName={'d-flex align-items-center'}
      style={{ minWidth: width < 600 ? '' : '800px' }}
      isOpen={props.isOpen}
    >
      <ModalBody>
        <div className={'d-flex align-items-center'}>
          {handleDynamicCamera()}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalTakePicture;
