import React from "react"
import InfoOrangeIcon from "app/component/info-orange-icon";
import NavBackIcon from "app/component/nav-back-icon";
import OrangeButton from "app/shared/layout/my-button/orange-button";
import Select from 'react-select';
import { Col, Row } from "reactstrap";
import LANG from 'app/shared/util/lang-constant';

const ModalChooseTax = ({
    onBackClick,
    onSelectedYear,
    onSelectedYpId,
    onDownloadClick,
    getSelectedYpId,
    getYpId,
    getSelectedYear,
    getYears,
    loadingButton
}) => {
  return (
    <div
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        className="col-md-4"
      >
        <Row className="nav-title">
          <div className="media-body d-flex justify-content-left">
            <div
              style={{ marginRight: 12 }}
              className="d-flex align-items-center"
            >
              <NavBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {onBackClick()
                //   setPage(Page.TAX_DOWNLOAD_REQUEST)
                }}
              />
            </div>
            <div className="font-r-400 text-black">
              {LANG().PageTitle.taxReport}
            </div>
          </div>
        </Row>

        <Row>
          <div>YP ID:</div>
        </Row>

        <Row className={'mb-4'}>
          <Select
            name={'sYpId'}
            className="basic-single"
            placeholder={''}
            value={getSelectedYpId}
            options={getYpId.sort((a, b) => a.label.localeCompare(b.label))}
            styles={{
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                border: 0,
                background: isFocused
                  ? '#f8dbc4'
                  : isSelected
                    ? '#F37F26'
                    : undefined,
                zIndex: 1,
              }),
            }}
            onChange={(e) => {
              // console.log('testing ==> ', e.label + "===" + e.value);
              onSelectedYpId({ label: e.label, value: e.value })
            //   setSelectedYpId({ label: e.label, value: e.value });
            }}
          />
        </Row>

        <Row>
          <div>{LANG().FORM.TAX_YEAR.LABEL}</div>
        </Row>

        <Row>
          <div className={'col-lg-8 col-md-12 mb-3'}>
            <Select
              name={'sYears'}
              className="basic-single"
              placeholder={''}
              value={getSelectedYear}
              options={getYears.sort((a, b) => a.label.localeCompare(b.label))}
              styles={{
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  border: 0,
                  background: isFocused
                    ? '#f8dbc4'
                    : isSelected
                      ? '#F37F26'
                      : undefined,
                  zIndex: 1,
                }),
              }}
              onChange={(e) => {
                onSelectedYear({ label: e.label, value: e.value })
                // setSelectedYear({ label: e.label, value: e.value });
              }}
            />
          </div>
          <div className={'col-lg-4 col-md-12'}>
            <OrangeButton
              margin={0}
              text={LANG().BUTTON.DOWNLOAD}
              disabled={loadingButton}
              onClick={() => {onDownloadClick()
                // handleDownloadTaxReport();
              }}
            />
          </div>
        </Row>

        <Row
          style={{
            backgroundColor: '#FFF4EB',
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '8px',
            padding: '8px',
            borderRadius: '4px',
          }}
        >
          <Col style={{ maxWidth: '30px' }}>
            <div>
              <InfoOrangeIcon />
            </div>
          </Col>
          <Col>
            <Row>
              <div>
                <span style={{ fontSize: '10pt', fontWeight: '900' }}>
                  Informasi
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  {LANG().TAX_INFORMATION}
                </span>
                <br />
                <br />
                <span style={{ fontSize: '9pt', fontWeight: '600' }}>
                  Customer Service
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  {LANG().PHONE} &nbsp;
                  <a href="tel:021150350">(021) 150 350</a>
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  Email : &nbsp;
                  <a
                    href="mailto:cs@miraeasset.co.id"
                    title="mailto:cs@miraeasset.co.id"
                  >
                    cs@miraeasset.co.id
                  </a>
                </span>
                <br />
                <span style={{ fontSize: '9pt' }}>
                  WhatsApp :{' '}
                  <a href="https://wa.me/6281119009000">+6281119009000</a>
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
  )
};

export default ModalChooseTax;
