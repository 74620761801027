import axios from 'axios';
import { getBasePath, Storage } from 'react-jhipster';

import { SERVER_API_URL } from 'app/config/constants';
import LANG from "app/shared/util/lang-constant";
import {toast} from "react-toastify";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    return config
  };
  const onResponseSuccess = response => {
    return response
  };

  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if(status === 401 || status === 403){
      if(Storage.local.get("isLoggedIn", "false") == "true"){
        onUnauthenticated()
      }
    }

    // if(status === 401 || status === 403){
    //   if(Storage.local.get("isLoggedIn", "false") == "true"){
    //     logoutFunction(err)
    //   }
    // }

    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);

  const logoutFunction = (err: any) => {
    axios.post('auth/logout',
        {},
        {headers:{"Content-Type": "application/json"}}).then(
        res => {
          if(res.status == 200) {

            console.log('logout interceptor')
            Storage.local.set("isLoggedIn", "false")
            Storage.local.remove("username");
            Storage.local.remove("isEmployee");
            Storage.local.remove("lastTimeStamp");
            Storage.local.remove("role");
            window.location.href = "/"

            return Promise.reject(err);
          }
        }
    )
  }
};

export default setupAxiosInterceptors;
