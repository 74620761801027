import './login.scss';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';

import { Row, Col } from 'reactstrap';
import { AvForm, AvField} from 'availity-reactstrap-validation';
import DarkPurpleButton from 'app/shared/layout/my-button/dark-purple-button';
import { useFormik } from 'formik';
import LANG from "app/shared/util/lang-constant";
import GeneralSettingConstant from "app/shared/util/general-settings-constant";

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const Loginx = (props: ILoginProps) => {

  const handleLogin = (username, password, role) => props.login(username, password, role);

  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };

  const icPassEye = "fa fa-fw fa-eye field-icon";
  const icSlashPassEye = "fa fa-eye fa-eye-slash";
  const [getIcTogglePassword, setIcTogglePassword] = useState(icSlashPassEye);
  const [getTypeTogglePassword, setTypeTogglePassword] = useState("password");

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const loginState = useFormik({
    initialValues: {   
        username: "",
        password: "",
    },
    onSubmit: values => {
      const bodyJson = JSON.parse(JSON.stringify(values, null, 2));
      handleLogin(bodyJson['username'], bodyJson['password'], GeneralSettingConstant().ROLE_ADMIN);
    }
  });

  const togglePassword = () => {
    if(getIcTogglePassword == icSlashPassEye){
        setIcTogglePassword(icPassEye);
        setTypeTogglePassword("text");
    } else {
      setIcTogglePassword(icSlashPassEye);
        setTypeTogglePassword("password");
    }
  }

    return (
    <>
    <div style={{background: '#fff'}} className="mt-lg-5 mt-md-5 py-5 container">

      <AvForm onSubmit={loginState.handleSubmit}>
          <Row>
              <Col md="6" className="pb-5 px-3 mr-5">

                  {/*Small device*/}
                  <img src="../../content/images/kyc_img_frontwebdisplay.png" width={300} className="d-block d-sm-block d-md-block d-lg-none d-xl-none" />
                  {/*Small device*/}

                  {/*Large device*/}
                  <img src="../../content/images/kyc_img_frontwebdisplay.png" width={500} className="d-none d-sm-none d-md-none d-lg-block d-xl-block" />
                  {/*Large device*/}

                  <div className='my-4'>
                  <div className='font-weight-bold' style={{fontSize:"24pt"}}>
                      {LANG().LOGIN_TITLE_TAGLINE}
                  </div>
                  <p className='brown-text' style={{fontSize:"16pt"}}>
                      {LANG().LOGIN_DESC_TAGLINE}
                  </p>  
                  </div>
              </Col>
              
              <Col md="5" className="card py-5 px-4 login-box">
                <Row style={{paddingLeft:40, paddingRight:40}}>
                <Col md="12">
                  <br></br><br></br>
                  <AvField
                    name="username"
                    label={LANG().FORM.USERNAME.LABEL}
                    value={loginState.values.username}
                    onChange={loginState.handleChange}
                    // placeholder={LANG().FORM.USERNAME.PH}
                    validate={{required: {value: true, errorMessage: LANG().FORM.ERROR.REQUIRED}}}
                    autoFocus
                  />
                  <Row>
                  <div className="input-title">
                    <div className="input-id-title">{LANG().FORM.PASSWORD.LABEL}</div>
                  </div>
                  <Col className='mb-2'>
                    <input
                      name="password"
                      type={getTypeTogglePassword}
                      value={loginState.values.password}
                      onChange={loginState.handleChange}
                      className="form-control"
                      // placeholder={LANG().FORM.PASSWORD.PH}
                    />
                  </Col>
                  <Col onClick={togglePassword} style={{cursor:"pointer", maxWidth:"10px", marginTop:"8px", marginBottom:"auto", marginLeft:"-50px"}}>
                        <span className={getIcTogglePassword}></span>
                  </Col>
                  </Row>

                </Col>
                </Row>
          
                <Row style={{marginLeft:40, marginRight:40, marginTop:10}}>
                  <DarkPurpleButton text={LANG().BUTTON.SIGNIN} />
                </Row>

              </Col>
          </Row>
         
      </AvForm>
   </div>
   </>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Loginx);
