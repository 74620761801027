import axios from 'axios';
import { Storage } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { setLocale } from 'app/shared/reducers/locale';
import GeneralSettingConstant from "app/shared/util/general-settings-constant";
import {toast} from "react-toastify";
import LANG from "app/shared/util/lang-constant";

import EndPoint from 'app/shared/util/api-constant';
import MyEncrypt from "app/shared/util/security-encrypt";
import MySecKey from "app/shared/util/security-key";

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  GET_INFO_AFTER_LOGIN: 'authentication/GET_INFO_AFTER_LOGIN',
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  username: (null as unknown) as string
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_INFO_AFTER_LOGIN):
      return {
        ...state,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
      };
      case FAILURE(ACTION_TYPES.GET_SESSION):
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
    case SUCCESS(ACTION_TYPES.LOGIN):

      let data = JSON.parse(action.payload['config']['data']);

      Storage.local.set('username',  data['username']);
      if(data['isEmployee'] != null) {
        Storage.local.set('role', GeneralSettingConstant().ROLE_USER)
      } else {
        Storage.local.set('role', GeneralSettingConstant().ROLE_ADMIN)
      }

      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true
      };
    case SUCCESS(ACTION_TYPES.LOGOUT):
      Storage.local.remove("username");
      Storage.local.remove("isEmployee");
      Storage.local.remove("lastTimeStamp");
      Storage.local.set("isLoggedIn", "false");

      if(Storage.local.get("role") == "ADMIN"){
        window.location.href = "/log"
      } else {
        window.location.href = "/"
      }

      return {
        ...initialState,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      (isAuthenticated)? Storage.local.set("isLoggedIn", "true") : sessionStorage.setItem("isLoggedIn", "false")
      const cAccount = {...action.payload.data, firstName: null};
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: {...state.account, cAccount},
      };
    }
    case SUCCESS(ACTION_TYPES.GET_INFO_AFTER_LOGIN): {
      return {
        ...state,
        loading: false,
        account: {
          ...state.account, 
          firstName: action.payload.data.acntNm, 
          acntType: action.payload.data.acntType,
          isUpdateData: action.payload.data.isUpdateData,
          updateDataDate: action.payload.data.updateDataDate,
          custTrnd: action.payload.data.custTrnd,
          ctznCd: action.payload.data.ctznCd,
          otherType: action.payload.data.otherType
        },
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      Storage.local.set("isLoggedIn", "false")
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession: () => void = () => async ( dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('services/uaadocservice/api/account'),
  });

  // const { account } = getState().authentication;
  // if (account && account.langKey) {
  //   const langKey = Storage.local.get('locale', account.langKey);

  //   await dispatch(setLocale(langKey));
  // }

  await dispatch(getInfoAfterLogin());

};

export const login: (username: string, password: string, role: string) => void = (username, password, role) => async (
  dispatch,
  getState
) => {

  //"isEmployee": false is to validate that the login user is must be not from employer
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(
        (role == GeneralSettingConstant().ROLE_USER)? "auth/login" : "auth/admin-kyc-auth",
        (role == GeneralSettingConstant().ROLE_USER)? {username, password, "isEmployee": "false"} : {username, password}
    ),
    username: username
  });

  await dispatch(getSession());
};

export const getInfoAfterLogin: () => void = () => async (
  dispatch,
  getState
) => {

  const MYURL = EndPoint();

  await dispatch({
    type: ACTION_TYPES.GET_INFO_AFTER_LOGIN,
    payload: axios.post(
        MYURL.GET_USER_INFO,
        {
          userId: Storage.local.get('username'),
          value: "info",
          key: MyEncrypt(({code: MySecKey(), timestamp: (new Date()).toISOString(), id: Storage.local.get('username')})),
          timestamp: (new Date()).toISOString()
        },
        {headers: {"Content-Type": "application/json"}}
    )
  });

  Storage.local.set('locale', getState()?.authentication?.account?.ctznCd == "85" ? "ID" : "EN");
  await dispatch(setLocale(getState()?.authentication?.account?.ctznCd == "85" ? "in" : "en"));
};

export const logout: () => void = () => async dispatch => {

  await dispatch({
    type: ACTION_TYPES.LOGOUT,
    payload: axios.post('auth/logout', {}),
  });

  // // fetch new csrf token
  // dispatch(getSession());
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};
