import React, { useEffect, useState } from 'react'
import TaxReportDownloadItem from './tax-report-download-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-jhipster';

const downloads = [
    { name: 'File 1', url: 'https://example.com/file1.zip' },
    { name: 'File 2', url: 'https://example.com/file2.zip' },
    // Add more download items as needed
];

const TaxReportDownloadList = ({ downloads }) => {
    let [downloadAvail, setDownloadAvail] = useState(true);

    useEffect(() => {
        let checkIfDownload = downloads.find(data => {console.log('dataexpired===>',data.expired); return data.expired === false});
        setDownloadAvail(checkIfDownload)
    }, [downloads])

    useEffect(() => {
        console.log('downloadAvail; bool==>', downloadAvail)
    },[downloadAvail])

    return (
        <ul className="list-group" style={{flexWrap:'wrap', minWidth: '600px'}}>
            <li className="list-group-item border border-top-0 border-end-0 border-start-0">

                <Container fluid className='p-0'>
                    <Row >
                        {/* <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.accountType')}</b>
                            </span>
                        </Col> */}
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.fileType')}</b>
                            </span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.ypId')}</b>
                            </span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.year')}</b>
                            </span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.status')}</b>
                            </span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.expiredAt')}</b>
                            </span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <span style={{ fontSize: '16px', color: '#404040'}}>
                                <b>{translate('report.table.download')}</b>
                            </span>
                        </Col>
                    </Row>
                </Container>
            </li>

            {downloads.map((download, index) => (
                <TaxReportDownloadItem key={index} {...download} />
            ))}
            <li className='list-group-item border border-bottom-0 border-end-0 border-start-0'>
                {!downloadAvail && <div className='d-flex justify-content-center text-secondary'>
                    <p>{translate('report.empty')}</p>
                </div>}
            </li>
        </ul>
    );
};


export default TaxReportDownloadList