import * as React from "react"
import { SVGProps } from "react"

const MenuRevampArrowDown = (props: SVGProps<SVGSVGElement>) => (
    <svg 
    width={12}
    height={7.2}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.351472 0.751863C0.820101 0.283233 1.5799 0.283233 2.04853 0.751863L6 4.70333L9.95147 0.751863C10.4201 0.283233 11.1799 0.283233 11.6485 0.751863C12.1172 1.22049 12.1172 1.98029 11.6485 2.44892L6.84853 7.24892C6.3799 7.71755 5.6201 7.71755 5.15147 7.24892L0.351472 2.44892C-0.117157 1.98029 -0.117157 1.22049 0.351472 0.751863Z" fill="#0A0A0A"/>
    </svg>
)

export default MenuRevampArrowDown