import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
}

export const toDateHots = (date: String) => {
    if(date != "" && date != null && date.length == 8){
        const year = +date.substring(0, 4);
        const month = +date.substring(4, 6);
        const day = +date.substring(6, 8);
        return new Date(year, month - 1, day);
    } else {
        return null
    }
}

export const toStringHots = (date: Date) => {
    if(dateIsValid) {
        return moment(date).format("YYYY-MM-DD")
    } else {
        return null
    }
}