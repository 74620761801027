import React from 'react';
// import {} from FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { head } from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-jhipster';

const TaxReportDownloadItem = ({ name, year, downloadLink, expired, accountNo, linkExpiredDate, status }) => {

    function formattedDate(dateString){
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Define options for formatting
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        };


        // Create a formatter
        const formatter = new Intl.DateTimeFormat('en-GB', options);

        // Format the date
        return formatter.format(date);
    }
    

    return (
        <li className="list-group-item">
            <div className={`d-flex flex-row ${expired && 'text-secondary'}`} >
                <Container fluid className='p-0'>
                    <Row >
                        <Col xs={2} className='d-flex justify-content-center'>
                            Tax Report
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            {accountNo}
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            {year}
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            {status == "failed" && 
                            <span style={{color: 'red'}}>
                                {translate('report.table.failed')}
                            </span>}
                            {status == "expired" &&
                            <span style={{color: 'red'}}>
                                {translate('report.table.expired')}
                            </span>
                            }
                            {status == "wait" && 
                            <span style={{color: '#4880EE'}}>
                                {translate('report.table.waiting')}
                            </span>}
                            {status == "ready" && 
                            <span style={{color: '#4880EE'}}>
                                {translate('report.table.ready')}
                            </span>}
                            {/* {status == undefined && <span>laskjdfj</span>} */}
                            {status == undefined && expired && <span style={{color: 'red'}}>
                                {translate('report.table.expired')}
                            </span>}
                            
                            {status == undefined && !expired && <span style={{color: '#4880EE'}}>
                                {translate('report.table.ready')}
                            </span>}
                            
                            
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center text-center'>
                            <span>{formattedDate(linkExpiredDate)}</span>
                        </Col>
                        <Col xs={2} className='d-flex justify-content-center'>
                            <div className='d-flex justify-content-end'>
                                
                                {!expired && status != "wait" && status != "failed" ? <>
                                    <FontAwesomeIcon icon={faDownload} color='#F58220' />
                                    <a className={`ps-2`} style={{color: '#F58220'}} href={downloadLink} download target="_blank"  >
                                        <span style={{color: '#F58220'}}>{translate('report.table.downloadReport')}</span>
                                    </a>
                                </> : <></>}
                                {/* {!expired ? <a className={`ps-2 ${expired && 'text-secondary'} `} href={!expired? downloadLink:''} download target="_blank"  >
                                    <span style={{color: '#74788d !important'}}>{translate('report.table.downloadReport')}</span>
                                </a>: <div className={`ps-2 ${expired && 'text-secondary'} `}>
                                        <span style={{color: '#74788d !important'}}>{translate('report.table.downloadReport')}</span>
                                    </div>} */}
                                
                                {/* <img src="../../content/images/upload.png"/> */}
                            </div>
                        </Col>

                    </Row>

                </Container>
            </div>

        </li>
    );
};

export default TaxReportDownloadItem;