import WarningIllustration from "app/component/warning-illustration";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";
import React from "react"
import { Modal, ModalBody } from "reactstrap";

const ModalTaxMain = ({
    isOpenModalTaxMain,
    getModalTitleMessage,
    getModalDescMessage,
    onClickModal
}) => {
    return(
        <Modal style={{ top: '25%' }} isOpen={isOpenModalTaxMain}>
        <ModalBody>
          <div className="text-center mb-4">
            <WarningIllustration />
          </div>
          <p
            style={{ fontSize: '20px' }}
            className="text-center mb-2 font-m-500"
          >
            {getModalTitleMessage}
          </p>
          <p
            style={{ fontSize: '14px' }}
            className="text-center mb-2 font-r-400"
          >
            {getModalDescMessage}
          </p>
          <div
            className="d-flex justify-content-center"
            onClick={() => onClickModal()}
          >
            <DarkPurpleButton text="OK" width="20%" />
          </div>
        </ModalBody>
      </Modal>
    )
}

export default ModalTaxMain