import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import {Row} from "reactstrap";
// import EndPoint from "app/shared/util/api-constant"


type AppProps = {
    uid: String;
    isVerified: boolean;
}

//TODO add audit trail value
const CacheMap = {
    "Account": {
        "User ID": "userId",
        "YP ID": "ypId"
    },
    "Data pribadi": {                            
        "Nama lengkap": "investorFullName",
        "No KTP": "investorCidNo",
        "Masa berlaku KTP": "investorCidExp",
        "No KITAS": "investorKitasNo",
        "Masa berlaku KITAS": "investorKitasExp",
        "No Paspor": "investorPassNo",
        "Masa berlaku Paspor": "investorPassExp",
        "Tanggal lahir": "investorBirthDate",
        "Tempat lahir": "investorBirthPlace",
        "Jenis kelamin": "investorSex",
        "Status pernikahan": "investorMartialStatus",
        "Nama pasangan": "investorSpouseName",
        "No KTP pasangan": "investorSpouseCidNo",
        "Tanggal lahir pasangan": "investorSpouseBirthDate",
        "No telpon pasangan": "investorSpouseCntcNo",
        "Nama ibu kandung": "investorMothersMaidenName",
        "Jumlah tanggungan": "investorDependencyCount",
        "Perjanjian pisah harta": "investorSeperateAggreement",
        "No NPWP": "investorNPWPNumber",
        "Tanggal registrasi NPWP": "investorNPWPRegistrationDate",
        "No telp. rumah": "investorHomePhone",
        "No HP": "investorMobilePhone",
        "Email": "investorEmail",
        "No Fax": "investorFax",
        "Pendidikan terakhir": "investorEducationalBackground"
    },
    "Alamat KTP": {
        "Alamat": "investorAddress",
        "Negara": "investorCountryName",
        "Provinsi": "investorPrpsNm",
        "Kota": "investorCityNm",
        "Kecamatan": "investorDistrictNm",
        "Kelurahan": "investorUrbanNm",
        "Kodepos": "investorPostalCode"
    },
    "Alamat Domisili": {
        "Alamat": "investorOtherAddress",
        "Negara": "investorOtherCountryName",
        "Provinsi": "investorOtherPrpsNm",
        "Kota": "investorOtherCityNm",
        "Kecamatan": "investorOtherDistrictNm",
        "Kelurahan": "investorOtherUrbanNm",
        "Kodepos": "investorOtherPostalCode"
    },
    "Data pekerjaan": {
        "Pekerjaan": "investorOccupation",
        "Jenis usaha": "investorOtherJob",
        "Nama perusahaan": "investorOfficeName",
        "Bidang usaha": "investorEntpType",

        "Alamat perusahaan": "investorWorkAddress",
        "Negara": "investorWorkCountryName",
        "Provinsi": "investorWorkPrpsNm",
        "Kota": "investorWorkCityNm",
        "Kecamatan": "investorWorkDistrictNm",
        "Kelurahan": "investorWorkUrbanNm",
        "Kodepos": "investorWorkPostalCode",

        "Telpon kantor": "investorWorkHomePhone",
        "Fax": "investorWorkFax",
        "Jabatan": "investorJobRole",
        "Lama bekerja (tahun)": "investorWorkYear",
        "Lama bekerja (bulan)": "investorWorkMonth",

        "Pemilik asset": "assetOwner",
        "Sumber dana": "investorFundSourceDetl",
        "Pendapatan per bulan": "investorIncomePerAnnum",

        "Pemberi manfaat": "iBenfOwnerNm",
        "Tanggal lahir pemberi manfaat": "iBenfOwnerDob"
    }
    /*"Other Information":{
        "Approved Date": "approvedDate",
        "Approved By": "approvedBy",

        "Last Updated Date": "lastUpdatedDate",
        "Last Updated By": "lastUpdatedBy"
    }*/

}

const CdToText = {
    "investorMartialStatus": {
        "1": "Belum Menikah",
        "2": "Menikah",
        "3": "Janda",
        "4": "Duda"
    }, 
    "investorSeperateAggreement": {
        "1": "Ya",
        "2": "Tidak"
    }, 
    "investorEducationalBackground": {
        "1": "Lainnya",
        "2": "SD",
        "3": "SMP",
        "4": "SMA/D1/D2",
        "5": "D3",
        "6": "S1",
        "7": "S2",
        "8": "S3"
    }, 
    "investorOccupation": {
        "06": "Pegawai Negeri Sipil",
        "03": "Pegawai Swasta",
        "07": "TNI/POLRI",
        "10": "Wirausaha",
        "01": "Pelajar/Mahasiswa",
        "05": "Ibu Rumah Tangga",
        "08": "Pensiunan",
        "99": "Lainnya"
    },
    "investorIncomePerAnnum": {
        "01": "< Rp 1 Juta",
        "02": "Rp 1 Juta - Rp 4 Juta",
        "03": "Rp 4 Juta - Rp 9 Juta",
        "04": "Rp 9 Juta - Rp 40 Juta",
        "05": "Rp 40 Juta - Rp 90 Juta",
        "06": "> Rp 90 Juta"
    },
    "assetOwner": {
        "1": "Milik sendiri",
        "2": "Bukan milik sendiri"
    }
}
    
const ToDecodeFields = [
    "investorMartialStatus", "investorSeperateAggreement", "investorEducationalBackground", 
    "investorOccupation", "investorIncomePerAnnum", "assetOwner"
]

const cdToAccType: (key: string) => string = function(key: string) {
    switch(parseInt(key)) {
        case 1: return "(Regular)"
        case 2: return "(Day Trading)" 
        case 3: return "(Margin Trading)" 
        case 4: return "(Syariah)"
        default: return key; 
    } 
}

const isEmpty: (key: any) => boolean = function(key: any) {
    return (key == null || key == undefined || key == "");
}

const LogAccUpdatePrev = (props: AppProps) => {

    // const MYURL = EndPoint();

    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isLoadingPageFailed, setLoadingPageFailed] = useState(false);

    const [getPreviewImageKTP, setPreviewImageKTP] = useState(null);
    const [getPreviewImageKITAS, setPreviewImageKITAS] = useState(null);
    const [getPreviewImagePaspor, setPreviewImagePaspor] = useState(null);
    const [getPreviewImageNPWP, setPreviewImageNPWP] = useState(null);
    const [getPreviewImageSelfie, setPreviewImageSelfie] = useState(null);

    const [getChangedData, setChangedData] = useState([])

    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    const [getBioCache, setBioCache] = useState(null)
    const [getBioCacheFormatted, setBioCacheFormatted] = useState(null)

    const [hideField, setHideField] = useState({})

    const [isReuploadKTP, setReuploadKTP] = useState<boolean>(false)


    useEffect(() => {
        handlePreviewData()
    }, []);

    useEffect(() => {
    }, [getBioCache]);

    useEffect(() => {
    }, [hideField]);



    const handlePreviewData = () => {
        Axios.get("/services/customerservice/api/v1/bio/admin/account/" + (props.isVerified ? "preview?ypId=" : "unverified/preview?uid=" ) + props.uid,
            {headers: {"Content-Type": "application/json"}}).then(
            res => {
                if (res.status == 200) {
                    let cache = JSON.parse(res.data.cache);
                    let cacheFormData = JSON.parse(cache["cache"])
                    setBioCache(cacheFormData);
                    let cacheFormatted = {}
                    Object.keys(CacheMap).map(v1 => {
                        cacheFormatted[v1] = {}
                        Object.keys(CacheMap[v1]).map(v2 => {
                            //test KYC-292
                            cacheFormatted[v1][v2] = cacheFormData[CacheMap[v1][v2]]
                        })
                    })
                    // setBioCacheFormatted(cacheFormatted);
                    setBioCacheFormatted({
                        ...cacheFormatted,
                        /*"Other Information": {
                            ...cacheFormatted["Other Information"],
                            "Approved Date": props.auditTrailData["Approved Date"],
                            "Approved By": props.auditTrailData["Approved By"],

                            "Last Updated Date": props.auditTrailData["Last Updated Date"],
                            "Last Updated By": props.auditTrailData["Last Updated By"]
                        },*/
                        "Account": {
                            ...cacheFormatted["Account"],
                            "YP ID": cacheFormData["ypId"] == null ? "null" : cacheFormData["ypId"].map(item => (item.split("-")[0] + " " + cdToAccType(item.split("-")[1]))).join(", ")
                        },
                        "Data pribadi":{
                            ...cacheFormatted["Data pribadi"],
                            "No KTP": (!isEmpty(cacheFormatted["Data pribadi"]["No KITAS"])) ? null : cacheFormatted["Data pribadi"]["No KTP"],
                            "Masa berlaku KTP": (!isEmpty(cacheFormatted["Data pribadi"]["No KITAS"])) ? null : cacheFormatted["Data pribadi"]["Masa berlaku KTP"],
                        }
                        // "Alamat KTP": {
                        //     ...cacheFormatted["Alamat KTP"],
                        //     "Negara": cacheFormData["investorCountryName"] + " (" + cacheFormData["investorCountryCode"] + ")",
                        //     "Provinsi": cacheFormData["investorPrpsNm"] + " (" + cacheFormData["investorPrpsCd"] + ")",
                        //     "Kota": cacheFormData["investorCityNm"] + " (" + cacheFormData["investorCityCd"] + ")",
                        //     "Kecamatan": cacheFormData["investorDistrictNm"] + " (" + cacheFormData["investorDistrictCd"] + ")",
                        //     "Kelurahan": cacheFormData["investorUrbanNm"] + " (" + cacheFormData["investorUrbanCd"] + ")"
                        // },
                        // "Alamat Domisili": {
                        //     ...cacheFormatted["Alamat Domisili"],
                        //     "Negara": cacheFormData["investorOtherCountryName"] + " (" + cacheFormData["investorOtherCountryCode"] + ")",
                        //     "Provinsi": cacheFormData["investorOtherPrpsNm"] + " (" + cacheFormData["investorOtherPrpsCd"] + ")",
                        //     "Kota": cacheFormData["investorOtherCityNm"] + " (" + cacheFormData["investorOtherCityCd"] + ")",
                        //     "Kecamatan": cacheFormData["investorOtherDistrictNm"] + " (" + cacheFormData["investorOtherDistrictCd"] + ")",
                        //     "Kelurahan": cacheFormData["investorOtherUrbanNm"] + " (" + cacheFormData["investorOtherUrbanCd"] + ")"
                        // },
                        // "Data pekerjaan": {
                        //     ...cacheFormatted["Data pekerjaan"],
                        //     "Pekerjaan": cacheFormData["investorOccupationDetl"] + " (" + cacheFormData["investorOccupation"] + ")"

                        //     "Negara": cacheFormData["investorWorkCountryName"] + " (" + cacheFormData["investorWorkCountryCode"] + ")",
                        //     "Provinsi": cacheFormData["investorWorkPrpsNm"] + " (" + cacheFormData["investorWorkPrpsCd"] + ")",
                        //     "Kota": cacheFormData["investorWorkCityNm"] + " (" + cacheFormData["investorWorkCityCd"] + ")",
                        //     "Kecamatan": cacheFormData["investorWorkDistrictNm"] + " (" + cacheFormData["investorWorkDistrictCd"] + ")",
                        //     "Kelurahan": cacheFormData["investorWorkUrbanNm"] + " (" + cacheFormData["investorWorkUrbanCd"] + ")",

                        //     "Lama bekerja": cacheFormData["investorWorkYear"] + " tahun " + cacheFormData["investorWorkMonth"] + " bulan",

                        //     "Sumber dana": cacheFormData["investorFundSource"] + " (" + cacheFormData["investorFundSourceDetl"] + ")"
                        // }
                    });
                    (cache["ktpPic"] == null) ? setPreviewImageKTP(null) : setPreviewImageKTP('data:image/png;base64,' + cache["ktpPic"]);
                    (cache["passPic"] == null) ? setPreviewImagePaspor(null) : setPreviewImagePaspor('data:image/png;base64,' + cache["passPic"]);
                    (cache["kitasPic"] == null) ? setPreviewImageKITAS(null) : setPreviewImageKITAS('data:image/png;base64,' + cache["kitasPic"]);
                    (cache["npwpPic"] == null) ? setPreviewImageNPWP(null) : setPreviewImageNPWP('data:image/png;base64,' + cache["npwpPic"]);
                    (cache["selfiePic"] == null) ? setPreviewImageSelfie(null) : setPreviewImageSelfie('data:image/png;base64,' + cache["selfiePic"]);
                    if(res.data["diff"]) { setChangedData(JSON.parse(res.data.diff)) }
                    setReuploadKTP(res.data["isReuploadKTP"] == true);
                    setLoadingPage(false);
                } else {
                    // setModalIllustrationStatus(false);
                    // setOpenModalOtpSendPhone(true);
                    // setModalTitleMessage(LANG().FAILED);
                    // setModalDescMessage("" + res.data.message);
                    setLoadingPage(false);
                    setLoadingPageFailed(true);
                }
            }
        ).catch(
            res => {
                setLoadingPage(false);
                setLoadingPageFailed(true);
                // setLoadingButtonNextMain(false)
                // setModalIllustrationStatus(false);
                // setOpenModalOtpSendPhone(true);
                // setModalTitleMessage(LANG().FAILED);
                // setModalDescMessage("" + res.response.data.message);
            });
    }

    const handleSetKTPError = () => {

        Axios.get("/services/customerservice/api/v1/bio/admin/account/set-ktp-error?userId=" + getBioCache["userId"],
            {headers: {"Content-Type": "application/json"}}).then(
            res => {
                if (res.status == 200) {
                    setReuploadKTP(true);
                } else {
                    // setLoadingPage(false);
                    // setLoadingPageFailed(true);
                }
            }
        ).catch(
            res => {
                // setLoadingPage(false);
                // setLoadingPageFailed(true);
                // setLoadingButtonNextMain(false)
                // setModalIllustrationStatus(false);
                // setOpenModalOtpSendPhone(true);
                // setModalTitleMessage(LANG().FAILED);
                // setModalDescMessage("" + res.response.data.message);
            });
    }

    const translateIfNeeded = (key, value) => {
        
    }


    const confirmationLayout = <div style={{paddingLeft:"32px", paddingRight:"32px", paddingBottom:"32px"}}>
        <div style={{width: '100%'}} className="col-md-6 col-lg-4">
            <Row>
                <div 
                    className={"titleReview"} 
                    style={{cursor: 'pointer'}}
                    onClick={() => { 
                        setHideField({...hideField, "pics": ((hideField["pics"] == null) ? true : !hideField["pics"]) }); 
                    }} 
                >
                    Uploaded
                </div>
            </Row>
            {!hideField["pics"] && <Row>
                <div style={{display: 'flex', backgroundColor: '#FFF4EB', overflowX: 'scroll', msOverflowX: 'scroll'}}>
                {[
                    {"title": "KTP" , "pic": getPreviewImageKTP},
                    {"title": "Paspor" , "pic": getPreviewImagePaspor},
                    {"title": "KITAS" , "pic": getPreviewImageKITAS},
                    {"title": "NPWP" , "pic": getPreviewImageNPWP},
                    {"title": "Selfie" , "pic": getPreviewImageSelfie},
                ].map(v => (v['pic'] != null) &&

                    <div style={{display: 'flex', flexDirection: 'column', padding: '10px'}}>

                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '25.5px'}} >
                        <div className={"titleContent"}>{v['title']}</div>
                            {v['title'] == "KTP" && (isReuploadKTP ?
                                <div style={{color: 'red'}}>Re-upload requested</div> :
                                true ? <div>{" ."}</div> : <div
                                    style={{background: '#0F2D3C', color: 'white', borderRadius: '3px', margin: '2px', padding: '1px 5px', cursor: 'pointer'}}
                                    onClick={() => {
                                        handleSetKTPError();
                                    }}
                                >Re-upload Notify</div>
                            )}
                        </div>

                        <img className={"previewImageContainer"} src={v['pic']} width={323} height={204} alt={"Image"}/>
                    </div>
                )}
                </div>
            </Row>}

            {(getBioCacheFormatted != null) && Object.keys(getBioCacheFormatted).map(v1 => <>
                <Row>
                    <div 
                        className={"titleReview"}
                        onClick={() => {
                            let newField = hideField;
                            newField[v1] = ((hideField[v1] == null) ? true : !hideField[v1]);
                            setHideField({...hideField, ...newField}); 
                        }} 
                        style={{cursor: 'pointer'}}
                    >{v1}</div>
                </Row>
                {!hideField[v1] && <Row 
                    className={"reviewContainer"}
                >
                    {Object.keys(getBioCacheFormatted[v1]).map(v2 => 

                        <div className={"col-md-6 mb-2"} style={isEmpty(getBioCacheFormatted[v1][v2]) ? {color: 'lightGray'} : null}>
                            <Row>
                                <div>
                                    <div className={"titleContent"}>{v2}</div>
                                    <span className={"updatedText"}>
                                        {getChangedData == null ? "" : 
                                            getChangedData.map(v => v["key"]).indexOf(CacheMap[v1][v2]) > -1 ? 
                                            ("*Updated from " + (
                                                (CacheMap[v1][v2] == "investorOccupation" && (getChangedData[getChangedData.map(v => v["key"]).indexOf(CacheMap[v1][v2])][props.isVerified ? "new" : "prev"])?.trim() == "99") ? getBioCache["investorOccupationDetl"] :
                                                ToDecodeFields.includes(CacheMap[v1][v2]) ?
                                                    CdToText[CacheMap[v1][v2]][(getChangedData[getChangedData.map(v => v["key"]).indexOf(CacheMap[v1][v2])][props.isVerified ? "new" : "prev"])?.trim()] : 
                                                    (getChangedData[getChangedData.map(v => v["key"]).indexOf(CacheMap[v1][v2])][props.isVerified ? "new" : "prev"])
                                            )) : ""
                                        }
                                    </span>
                                </div>
                            </Row>
                            <Row>
                                <div className={"titleContentReview"}>
                                    {isEmpty(getBioCacheFormatted[v1][v2]) ? "null" : 
                                        (CacheMap[v1][v2] == "investorOccupation" && getBioCacheFormatted[v1][v2]?.trim() == "99") ? getBioCache["investorOccupationDetl"] :
                                        ToDecodeFields.includes(CacheMap[v1][v2]) ?
                                             CdToText[CacheMap[v1][v2]][getBioCacheFormatted[v1][v2]?.trim()] : 
                                        getBioCacheFormatted[v1][v2]}
                                </div>
                            </Row>
                        </div>

                    )}
                </Row>}
            </>)}

        </div>
    </div>

    const mainPage = () => confirmationLayout;

    return (isLoadingPage ?  <>loading</> : (isLoadingPageFailed ? <>Loading gagal, silakan coba kembali</> : mainPage()));
}

export default LogAccUpdatePrev;