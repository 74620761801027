import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import SuccessIllustration from "./success-illustration";
import FailedIllustration from "./failed-illustration";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";

const  ModalRevamp = (props) => {


    const {
        isOpen,
        status,
        title,
        description,
        onClose
    } = props


    const handleOnClose = () => {
        onClose()
    }

    return(
        <Modal style={{top: "25%"}} isOpen={isOpen}>
            <ModalBody>
                <div className="text-center mb-4">{status ? <SuccessIllustration/> :
                    <FailedIllustration/>}</div>
                <p style={{fontSize: "20px"}} className="text-center mb-2 font-m-500">{title}</p>
                <p style={{fontSize: "14px"}} className="text-center mb-2 font-r-400">{description}</p>
                <div className="d-flex justify-content-center" onClick={handleOnClose}>
                    <DarkPurpleButton text='OK' width="20%"/>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalRevamp