import * as React from "react"
import { SVGProps } from "react"

const RadioDeselectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg 
    width={24} 
    height={24}
    fill= "#9E9E9E"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path d="M11 20.002C16.524 20.002 21.002 15.524 21.002 10.001C21.002 4.47702 16.524 -0.000976562 11 -0.000976562C5.47605 -0.000976562 0.998047 4.47702 0.998047 10.001C0.998047 15.524 5.47605 20.002 11 20.002V20.002ZM11 18.502C8.74544 18.502 6.58318 17.6064 4.98893 16.0121C3.39469 14.4179 2.49905 12.2556 2.49905 10.001C2.49905 7.74642 3.39469 5.58416 4.98893 3.98991C6.58318 2.39566 8.74544 1.50002 11 1.50002C13.2548 1.50002 15.4172 2.39571 17.0115 3.99006C18.6059 5.5844 19.5015 7.74679 19.5015 10.0015C19.5015 12.2563 18.6059 14.4187 17.0115 16.013C15.4172 17.6073 13.2548 18.503 11 18.503V18.502Z"/>
    </svg>
)

export default RadioDeselectIcon