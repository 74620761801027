import React from "react"
import { Button, Col, Modal, ModalBody } from "reactstrap";

const ModalConfirmation = ({
    isOpenModalConfirmation,
    getModalDescMessage,
    onConfirm,
    onCancel
}) => {
  return (
    <Modal style={{ top: '25%' }} isOpen={isOpenModalConfirmation}>
        <ModalBody
          className={'row d-flex align-items-center'}
          style={{ minHeight: '120px' }}
        >
          <p
            style={{ fontSize: '14px' }}
            className="text-center mb-2 mt-3 font-r-400"
          >
            {getModalDescMessage}
          </p>
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              style={{
                backgroundColor: '#0F2D3C',
                width: '80px',
                border: '0px',
              }}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <div style={{ width: '10px' }} />
            <Button
              style={{
                backgroundColor: '#F37F26',
                width: '80px',
                border: '0px',
              }}
              onClick={() =>onConfirm()}
            >
              Ok
            </Button>
          </Col>
        </ModalBody>
      </Modal>
  )
};

export default ModalConfirmation;
