import * as React from "react"
import {SVGProps} from "react"

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13 1L1 13M1 1L13 13" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>);

export default CloseIcon