import axios from 'axios';

import { SUCCESS, REQUEST, FAILURE } from 'app/shared/reducers/action-type.util';
import { stat } from 'fs';

export const ACTION_TYPES = {
    LIVENESS_DETECTION_TOKEN: 'liveness/DETECTION_TOKEN', // LIVENESS_DETECTION_TOKEN
    LIVENESS_DETECTION_RESULT: 'liveness/DETECTION_RESULT', // LIVENESS_DETECTION_RESULT
    LIVENESS_DETECTION_VALIDATE: 'liveness/DETECTION_VALIDATE', // LIVENESS_DETECTION_VALIDATE
    LIVENESS_DETECTION_RESET: 'liveness/DETECTION_RESET', // LIVENESS_DETECTION_RESET
};

const initialState = {
    loading: false,
    livenessUrl: '',
    livenessSignatureId: '',
    dataUri: '',
    dataScore: '',
    code: '',
    message: '',
    actionType: '',
    reqStatus: 1,
};

export const API_REQUEST = 1;
export const API_SUCCESS = 2;
export const API_FAILURE = 3;

export type LivenessState = Readonly<typeof initialState>

export default (state: LivenessState = initialState, action): LivenessState => {
    // const payload = action.payload;
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LIVENESS_DETECTION_TOKEN):
            return {
                ...state,
                loading: true,
                livenessUrl: '',
                livenessSignatureId: '',
                actionType: ACTION_TYPES.LIVENESS_DETECTION_TOKEN,
                reqStatus: API_REQUEST
            }
        case SUCCESS(ACTION_TYPES.LIVENESS_DETECTION_TOKEN):
            const data_token = action.payload?.data;
            console.log(data_token);
            return {
                ...state,
                loading: false,
                livenessUrl: data_token.data.url,
                livenessSignatureId: data_token.data.signatureId,
                code: data_token.code,
                message: data_token.message,
                actionType: ACTION_TYPES.LIVENESS_DETECTION_TOKEN,
                reqStatus: API_SUCCESS
            }
        case FAILURE(ACTION_TYPES.LIVENESS_DETECTION_TOKEN):
            return {
                ...state,
                loading: false,
                actionType: ACTION_TYPES.LIVENESS_DETECTION_TOKEN,
                reqStatus: API_FAILURE
            }
        case REQUEST(ACTION_TYPES.LIVENESS_DETECTION_RESULT):
            return {
                ...state,
                loading: true,
                code: '',
                message: '',
                dataUri: '',
                actionType: ACTION_TYPES.LIVENESS_DETECTION_RESULT,
                reqStatus: API_REQUEST
            }
        case SUCCESS(ACTION_TYPES.LIVENESS_DETECTION_RESULT):
            console.log("---LIVENESS DETECTION RESULT---")
            console.log(action.payload.data)
            const data_result = action.payload?.data;
            return {
                ...state,
                loading: false,
                code: data_result.code,
                message: data_result.message,
                dataUri: data_result?.data?.image,
                actionType: ACTION_TYPES.LIVENESS_DETECTION_RESULT,
                reqStatus: API_SUCCESS
            }
        case FAILURE(ACTION_TYPES.LIVENESS_DETECTION_RESULT):
            return {
                ...state,
                loading: false,
                code: '',
                message: '',
                dataUri: '',
                actionType: ACTION_TYPES.LIVENESS_DETECTION_RESULT,
                reqStatus: API_FAILURE
            }

        case REQUEST(ACTION_TYPES.LIVENESS_DETECTION_VALIDATE):
            return {
                ...state,
                loading: true,
                code: '',
                message: '',
                dataUri: '',
                dataScore: '',
                actionType: ACTION_TYPES.LIVENESS_DETECTION_VALIDATE,
                reqStatus: API_REQUEST
            }
        case SUCCESS(ACTION_TYPES.LIVENESS_DETECTION_VALIDATE):
            console.log("---LIVENESS DETECTION VALIDATE---")
            console.log(action.payload.data)
            const data_validate = action.payload?.data;
            return {
                ...state,
                loading: false,
                code: data_validate.code,
                message: data_validate.message,
                dataUri: data_validate?.data?.image,
                dataScore: data_validate?.data?.score,
                actionType: ACTION_TYPES.LIVENESS_DETECTION_VALIDATE,
                reqStatus: API_SUCCESS
            }
        case FAILURE(ACTION_TYPES.LIVENESS_DETECTION_VALIDATE):
            return {
                ...state,
                loading: false,
                code: '',
                message: '',
                dataUri: '',
                dataScore: '',
                actionType: ACTION_TYPES.LIVENESS_DETECTION_VALIDATE,
                reqStatus: API_FAILURE
            }

        case ACTION_TYPES.LIVENESS_DETECTION_RESET:
            console.log('liveness reset success 2')
            return {
                loading: false,
                livenessUrl: '',
                livenessSignatureId: '',
                dataUri: '',
                code: '',
                message: '',
                actionType: '',
                reqStatus: 1,
            }
        default:
            return state;

    }
}
