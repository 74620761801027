import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DarkPurpleButton from "app/shared/layout/my-button/dark-purple-button";
import {Button, ButtonProps, Spinner} from 'reactstrap';
import Axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import LANG from "app/shared/util/lang-constant";
import PageConstUtil from "app/shared/util/page-constants";
import {Link} from "react-router-dom";
import NavBackIcon from "app/component/nav-back-icon";

import LogAccUpdatePrev from "./log-acc-update-preview";
import LogAuditTrailPreview from "app/modules/log/log-audit-trail-preview";
import LogAuditTrailPreviewV2 from './log-audit-trail-preview-v2';


type AppProps = {
    onBack: () => void;
}

type StatFilterProps = {
    cState: String;
    ownState: String;
    onSelect: () => void;
}

const NumFields = [
    "YP_ID", "ACCOUNTTAXCODE", "INVESTORKTPNUMBER", "INVESTORKTPEXPIREDDATE",
    "INVESTORNPWPREGISTRATIONDATE", "INVESTORPASSPORTEXPIREDDATE", "INVESTORKITASSKDEXPIREDDATE",
    "INVESTORBIRTHDATE", "INVESTORPOSTALCODE", "INVESTOROTHERPOSTALCODE",
    "INVESTORSEX", "INVESTORMARITALSTATUS", "INVESTOREDUCATIONALBACKGROUND", "INVESTOROCCUPATION", "INVESTORINCOMEPERANNUM",
    "INVESTORFUNDSOURCE", "INVESTORINVESTMENTOBJECTIVE", "ASSETOWNER",
    "INVESTORHOMEPHONE", "INVESTORMOBILEPHONE", "INVESTOROTHERHOMEPHONE", "INVESTOROTHERMOBILEPHONE"
]

const Phones = [
    "INVESTORHOMEPHONE", "INVESTORMOBILEPHONE", "INVESTOROTHERHOMEPHONE", "INVESTOROTHERMOBILEPHONE"
]

const ToBeCleaned = [ "INVESTOROCCUPATIONTEXT", "INVESTORFUNDSOURCETEXT" ]

const CBestMap = {
    "YP_ID": "yp_id",
    "FLAG": "flag",
    "ACNT_TYPE": "acnt_type",
    "ACTION": "Action",
    "INVESTORTYPE": "Investor Type",
    "INVESTORCLIENTTYPE": "Client Type",
    "ACCOUNTLOCALCODE": "Acnt Local Code",
    "ACCOUNTCLIENTCODE": "Acnt Client CD",
    "ACCOUNTTAXCODE": "Tax Code",
    "INVESTORFIRSTNAME": "First Name",
    "INVESTORMIDDLENAME": "Middle Name",
    "INVESTORLASTNAME": "Last Name",
    "INVESTORNATIONALITY": "Nationality",
    "INVESTORKTPNUMBER": "KTP Number",
    "INVESTORKTPEXPIREDDATE": "KTP Expr Date",
    "INVESTORNPWPNUMBER": "NPWP Number",
    "INVESTORNPWPREGISTRATIONDATE": "NPWP Reg Date",
    "INVESTORPASSPORTNUMBER": "Passport Number",
    "INVESTORPASSPORTEXPIREDDATE": "Passport Expr Date",
    "INVESTORKITASSKDNUMBER": "KITAS Number",
    "INVESTORKITASSKDEXPIREDDATE": "KITAS Expr Date",
    "INVESTORBIRTHPLACE": "Place of Birth",
    "INVESTORBIRTHDATE": "Birthday",
    "INVESTORADDRESS1": "Address1",
    "INVESTORADDRESS2": "Address2",
    "INVESTORADDRESS3": "Address3",
    "INVESTORCITY": "City",
    "INVESTORPROVINCE": "Province",
    "INVESTORPOSTALCODE": "Postal Code",
    "INVESTORCOUNTRY": "Country",
    "INVESTORHOMEPHONE": "Home Phone",
    "INVESTORMOBILEPHONE": "Mobile",
    "INVESTOREMAIL": "Email",
    "INVESTORFAX": "Home Fax",
    "INVESTOROTHERADDRESS1": "Other Addr1",
    "INVESTOROTHERADDRESS2": "Other Addr2",
    "INVESTOROTHERADDRESS3": "Other Addr3",
    "INVESTOROTHERCITY": "Other City",
    "INVESTOROTHERPROVINCE":  "Other Province",
    "INVESTOROTHERPOSTALCODE":  "Other Postal Code",
    "INVESTOROTHERCOUNTRY":  "Other Country",
    "INVESTOROTHERHOMEPHONE": "Home Phone2",
    "INVESTOROTHERMOBILEPHONE":  "Mobile2",
    "INVESTOROTHEREMAIL":   "Email2",
    "INVESTOROTHERFAX": "Home Fax2",
    "INVESTORSEX": "Gender",
    "INVESTORMARITALSTATUS":  "Marital Status",
    "INVESTORSPOUSENAME": "Spouse Name",
    "INVESTORHEIRNAME": "Heritor Name",
    "INVESTORHEIRRELATION": "Heritor Relation",
    "INVESTOREDUCATIONALBACKGROUND": "Edu Background",
    "INVESTOROCCUPATION": "Occupation",
    "INVESTOROCCUPATIONTEXT": "Occupation Text",
    "INVESTORNATUREOFBUSINESS": "Nature of Business",
    "INVESTORINCOMEPERANNUM": "Income Level",
    "INVESTORFUNDSOURCE": "Source of Fund",
    "INVESTORFUNDSOURCETEXT": "Source of Fund Text",
    "ACCOUNTDESCRIPTION": "Acnt Description",
    "INVESTORBANKACCOUNTNAME1": "Bank Acnt Nm1",
    "INVESTORBANKACCOUNTNUMBER1": "Bank Acnt No1",
    "INVESTORBANKACCOUNTBICODE1": "Bank Acnt BIC CD1",
    "INVESTORBANKACCOUNTHOLDERNAME1": "Bank Acnt Holder1",
    "INVESTORBANKACCOUNTCURRENCY1": "Bank Acnt Curr1",
    "INVESTORBANKACCOUNTNAME2": "Bank Acnt Nm2",
    "INVESTORBANKACCOUNTNUMBER2": "Bank Acnt No2",
    "INVESTORBANKACCOUNTBICODE2": "Bank Acnt BIC CD2",
    "INVESTORBANKACCOUNTHOLDERNAME2": "Bank Acnt Holder2",
    "INVESTORBANKACCOUNTCURRENCY2": "Bank Acnt Curr2",
    "INVESTORBANKACCOUNTNAME3": "Bank Acnt Nm3",
    "INVESTORBANKACCOUNTNUMBER3": "Bank Acnt No3",
    "INVESTORBANKACCOUNTBICODE3": "Bank Acnt BIC CD3",
    "INVESTORBANKACCOUNTHOLDERNAME3": "Bank Acnt Holder3",
    "INVESTORBANKACCOUNTCURRENCY3": "Bank Acnt Curr3",
    "INVESTORINVESTMENTOBJECTIVE" : "Investment Object",
    "INVESTORMOTHERSMAIDENNAME": "Mother Name",
    "DIRECTSID": "Direct SID",
    "ASSETOWNER": "Asset Owner"
}

const HotsStatMap = {
    "00": "Confirm Before (new)",
    "01": "Prime",
    "02": "Good",
    "03": "Active",
    "04": "Active Watch",
    "05": "Mistake",
    "06": "Special",
    "07": "Problem",
    "08": "BLOCK",
    "09": "Dormancy (new)",
    "99": "CLOSED"
}


const LogAccUpdate = (props: AppProps) => {

    const [isBegin, setBegin] = useState(true);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    const [isShowPendingTable, setShowPendingTable] = useState<boolean>(false)
    const [isDownloadAllData, setDownloadAllData] = useState<boolean>(false)

    const [loadingApprove, setLoadingApprove] = useState<boolean>(false)
    const [loadingDownloadCbest, setLoadingDownloadCbest] = useState<boolean>(false)
    const [loadingDownloadRec, setLoadingDownloadRec] = useState<boolean>(false)

    const [isOpenModal, setOpenModal] = useState(false)
    const [isOpenModalConfToApprove, setOpenModalConfToApprove] = useState({type: "",isOpen: false})
    const [isOpenModalDataPreview, setOpenModalDataPreview] = useState(false)
    const [isOpenModalAuditTrail, setOpenModalAuditTrail] = useState(false)
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false)
    const [rejectNotes, setRejectNotes] = useState("")
    const [isKtpErr, setIsKtpErr] = useState(false)

    const [getModalTitleMessage, setModalTitleMessage] = useState("")
    const [getModalDescMessage, setModalDescMessage] = useState("")

    const [getTableData, setTableData] = useState(null)
    const [getTableOffline, setTableOffline] = useState(null)

    const [filterKey, setFilterKey] = useState("ypId")
    const [getSearchKeyword, setSearchKeyword] = useState("")

    const [statFilter, setStatFilter] = useState("")

    const [ypForExport, setYpForExport] = useState("")


    const [adminNoteData, setAdminNoteData] = useState({})
    const [isOpenModalAdminNote, setOpenModalAdminNote] = useState(false)

    const [checkedYp, setCheckedYp] = useState([])
    const [checkedYpOff, setCheckedYpOff] = useState([])

    const [toPreview, setToPreview] = useState({uid: "", ypId: "", isVerified: true})

    // paginator
    // const [pageNum, setPageNum] = useState(1);
    // const [pageSize, setPageSize] = useState(20);
    const [pageNumSize, setPageNumSize] = useState({pageNum: 1, pageSize: 20});
    const [dataCount, setDataCount] = useState(0);

    // const [pageNumOf, setPageNumOf] = useState(1);
    // const [pageSizeOf, setPageSizeOf] = useState(20);
    const [pageNumSizeOf, setPageNumSizeOf] = useState({pageNumOf: 1, pageSizeOf: 20});
    const [dataCountOf, setDataCountOf] = useState(0);

    const [auditTrailData, setAuditTrailData] = useState([])

    // useEffect(() => { }, [checkedYp]);
    useEffect(() => { 
        if (isBegin) {
            setBegin(false);
        } else {
            showTable(isShowPendingTable);             
        }
    }, [pageNumSize, pageNumSizeOf]);


    const keyToTitle: (key: String) => String = function(key: String) {
        switch(key) {
            case "ypId": return "YP ID"
            case "status": return "Status"
            case "timestamp": return "Waktu"
            case "name": return "Nama Nasabah"
            case "acntType": return "Tipe Akun"
            case "email": return "Email"
            case "sid": return "SID"
            case "sre001": return "SRE 001"
            case "username": return "User ID"
            case "userId": return "User ID"
            case "hotsStatus": return "Hots Status"
            case "adminNotes": return "Admin Notes"
            case "rejectCount": return "Reject N"
            default: return key;
        }
    }

    const toDateTime = (secs) => {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    const cdToStatus: (key: String) => String = function(key: String) {
        switch(key) {
            case "v": return "on-verif"
            case "p": return "pending"
            case "s": return "approved"
            case "r": return "rejected"
            default: return key;
        }
    }

    const cdToAccType: (key: string) => string = function(key: string) {
        switch(parseInt(key)) {
            case 1: return "Regular"
            case 2: return "Day Trading"
            case 3: return "Margin Trading"
            case 4: return "Syariah"
            default: return key;
        }
    }

    const showTable = (isPending: boolean) => {

        if(!isPending && (endDate == null || startDate == null)){
            setModalIllustrationStatus(false);
            setOpenModal(true);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage(LANG().INVALID_DATE_RANGE);
            return
        }

        const bodyApi = {
            from:moment(startDate).format("YYYY-MM-DD"),
            to:moment(endDate).format("YYYY-MM-DD"),
            ...pageNumSize,
            ...pageNumSizeOf
        };

        setLoadingButton(true)
        setTableData(null)
        setTableOffline(null)

        Axios.post("/services/customerservice/api/v2/bio/admin/account/list" + (isPending ? "/pending/paginated" : "/paginated"),
        // Axios.post("http://localhost:8063/api/v1/bio/admin/account/list" + (isPending ? "/pending/paginated" : "/paginated"),
            bodyApi,
            {headers:{"Content-Type": "application/json"}}).then(
            res => {
                setLoadingButton(false)
                if(res.status == 200) {
                    if(isPending) {
                        setTableData(res.data?.count < 1 ? null : JSON.parse(res.data.data));
                        setDataCount(res.data?.count);
                    } else {
                        setDataCount(res.data["online"]?.count);
                        setDataCountOf(res.data["offline"]?.count);

                        const dataOnline = JSON.parse(res.data["online"]?.data)

                        setTableData(dataOnline);

                        setTableOffline(res.data["offline"]?.count < 1 ? null : JSON.parse(res.data["offline"]?.data)?.map(v => ({
                            "ypId": v["acntNo"],
                            "status": "p",
                            "timestamp": v["updateDataDate"],
                            "name": v["acntNm"],
                            "acntType": v["acntType"],
                            "email": "-",
                            "sid": v["sid"],
                            "sre001": v["kseiAcntNo"],
                            "username": v["userId"],
                            "hotsStatus": v["acntStatSect"]
                        })));
                    }

                } else {
                    setTableData(null);
                    setTableOffline(null);
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.data.message);
                }
            }
        ).catch(
            res => {
                console.log(res);
                setTableData(null);
                setTableOffline(null);
                setLoadingButton(false)
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                if (res.response?.status === 400) {
                    setModalDescMessage(LANG().LOG_NOT_FOUND);
                } else if (res.response?.status == 405) {
                    setModalDescMessage("Metode permintaan tidak didukung untuk URL ini.");
                } else {
                    setModalDescMessage("" + res.response?.data.message);
                }
            });
    }

    const approveAccount = (actType: String, rejectNotes: String) => {

        if(checkedYp?.length < 1){
            setModalIllustrationStatus(false);
            setOpenModal(true);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage("Tidak ada akun terpilih");
            return
        }

        setLoadingApprove(true)

        Axios.post(("/services/customerservice/api/v2/bio/admin/account/" + ((actType == "KYC-APPROVE") ? "verify" : ("f2/" + ((actType == "KSEI-APPROVE") ? "approve" : "reject"))) + "?isKtpErr=" + (isKtpErr ? "true" : "false") + "&&rejectNotes=" + rejectNotes),

            getTableData?.filter(v => (checkedYp.includes(v['ypId']) && !((actType?.substring(0, 3) == "KYC") && (v['status'] != 'v')) && !((actType?.substring(0, 4) == "KSEI") && (v['status'] != 'p')) )).map(v => (
                {...v,
                    "timestamp": null,
                    "approvedDate": null,
                    "rejectedDate": null,
                    "lastUpdatedDate": null,
                    "createdDate": null,
                    "approvalHistories": null,
                }
            )),

            {headers:{"Content-Type": "application/json"}}).then(
            res => {
                setLoadingApprove(false)
                if(res.status == 200) {
                    setOpenModalConfToApprove({type: "", isOpen: false});
                    showTable(isShowPendingTable);
                } else {
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.status == 400 ? res.data.message
                        : "Terjadi kesalahan pada sistem, silakan coba kembali");
                }
            }
        ).catch(
            res => {
                setLoadingApprove(false);
                setOpenModalConfToApprove({type: "", isOpen: false});
                showTable(isShowPendingTable);
                setLoadingButton(false)
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                if (res.response.status === 400) {
                    setModalDescMessage(LANG().LOG_NOT_FOUND);
                } else if (res.response.status == 405) {
                    setModalDescMessage("Metode permintaan tidak didukung untuk URL ini.");
                } else {
                    setModalDescMessage("" + res.response.data.message);
                }
            });
    }

    // const download_shown_table = (table_id = 'shown-table', separator = ',') => {
    //     var rows = document.querySelectorAll('table#' + table_id + ' tr');
    //     var rowsOf = document.querySelectorAll('table#' + 'shown-table-offline' + ' tr');

    //     var csv = [];

    //     let nums = [1];

    //     for (var i = 0; i < rows.length; i++) {
    //         var row : any = [], cols: any = rows[i].querySelectorAll('td, th');
    //         for (var j = 1; j < (cols.length - 2); j++) {
    //             var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
    //             data = data.replace(/"/g, '""');
    //             row.push((nums.includes(i) ? '=' : '') + '"' + data + '"');
    //         }
    //         csv.push(row.join(separator));
    //     }
    //     var csv_string = 'sep=,' + '\r\n\n' + csv.join('\n');

    //     var filename = 'export_' + "table_id" + '_' + new Date().toLocaleDateString() + '.csv';
    //     var link = document.createElement('a');
    //     link.style.display = 'none';
    //     link.setAttribute('target', '_blank');
    //     link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    //     link.setAttribute('download', filename);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     setLoadingDownloadRec(false);
    // }

    const download_shown_table = () => {
        download_rec_data("2");
    }

    const download_all_data = () => {
        download_rec_data("/all");
    }

    const download_rec_data = (path = "") => {

        Axios.post("/services/customerservice/api/v1/bio/admin/account/list" + path,
            {
                from:moment(startDate).format("YYYY-MM-DD"),
                to:moment(endDate).format("YYYY-MM-DD"),
            },
            {headers:{"Content-Type": "application/json"}}).then(
            async res => {
                setLoadingDownloadRec(false)
                if(res.status == 200) {
                    let d = res.data["online"]?.map(v => ({
                        "YP ID": v["ypId"],
                        "Online Admin Status": cdToStatus(v["status"]),
                        "Time": moment(toDateTime(v["timestamp"]["epochSecond"])).format("DD-MM-YYYY HH:mm:ss"),
                        "Name": v["name"],
                        "Account Type": cdToAccType(v["acntType"]),
                        "Email": v["email"],    
                        "SID": v["sid"],
                        "SRE": v["sre001"],
                        "User ID": v["username"],    
                        "Hots Status": HotsStatMap[v["hotsStatus"]],
                        "Admin Notes": v["adminNotes"]
                    }));
                    let d2 = res.data["offline"]?.map(v => ({
                        "YP ID": v["acntNo"],
                        "Online Admin Status": "approved",
                        "Time": v["updateDataDate"]?.length == 8 ? moment(v["updateDataDate"], 'YYYYMMDD').format('DD-MM-YYYY HH:mm:ss') : "",
                        "Name": v["acntNm"],
                        "Account Type": cdToAccType(v["acntType"]),
                        "Email": "-",
                        "SID": v["sid"],
                        "SRE": v["kseiAcntNo"],
                        "User ID": v["userId"],
                        "Hots Status": HotsStatMap[v["acntStatSect"]],
                        "Admin Notes": ""
                    }));
                    let csv_str = await ('sep=,' + '\r\n\n' + "\"Platform\"," + Object.keys(d[0]).map(v => ("\"" + v + "\"")).join(",") + "\n");

                    for (let i=0; i<d.length; i++) {
                        csv_str += "\"online\","
                        csv_str += (Object.keys(d[i]).map((v, idx) => ((idx==0 ? "=" : "") + "\"" + (d[i][v] == null ? "-" : d[i][v]) + "\"")).join(",") + "\n");
                    }

                    if(d2) {
                        for (let i=0; i<d2.length; i++) {
                            csv_str += "\"offline\","
                            csv_str += (Object.keys(d2[i]).map((v, idx) => ((idx==0 ? "=" : "") + "\"" + (d2[i][v] == null ? "-" : d2[i][v]) + "\"")).join(",") + "\n");
                        }
                    }

                    var filename = 'export_' + "table_id" + '_' + new Date().toLocaleDateString() + '.csv';
                    var link = document.createElement('a');
                    link.style.display = 'none';
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_str));
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                } else {
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.data.message);
                }
            }
        ).catch(
            res => {
                setLoadingDownloadRec(false)
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                if (res.response.status === 400) {
                    setModalDescMessage(LANG().LOG_NOT_FOUND);
                } else if (res.response.status == 405) {
                    setModalDescMessage("Metode permintaan tidak didukung untuk URL ini.");
                } else {
                    setModalDescMessage("" + res.response.data.message);
                }
            });
    }

    const downloadCsv = () => {

        if((checkedYp?.length + checkedYpOff?.length) < 1){
            setModalIllustrationStatus(false);
            setOpenModal(true);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage("Tidak ada akun terpilih");
            return
        }

        setLoadingDownloadCbest(true)

        let cYps = [
            ...(getTableData == null ? [] : getTableData?.filter(v => (parseInt(v['acntType']) == 1 && checkedYp.includes(v['ypId']))).map(v => v['ypId'])),
            ...(getTableOffline == null ? [] : getTableOffline?.filter(v => (parseInt(v['acntType']) == 1 && checkedYpOff.includes(v['ypId']))).map(v => v['ypId']))
        ];

        Axios.post("/services/customerservice/api/v1/bio/by-ypid",
            {"yp": cYps},
            {headers:{"Content-Type": "application/json"}}).then(
            async res => {
                setLoadingDownloadCbest(false)
                if(res.status == 200) {
                    var data = await ('sep=,' + '\r\n\n' + Object.keys(CBestMap).map(v => ("\"" + CBestMap[v] + "\"")).join(",") + "\n");

                    // for (var i=0; i< res.data.length; i++) {
                    //     data += await (Object.keys(CBestMap).map(k => ("\""  + res.data[i][k] + "\"")).join(",") + "\n");
                    // }

                    res.data.forEach( async v => {

                        if (v['INVESTORFIRSTNAME'].length > 35) {
                            let names = ["", "", ""];
                            let name_arr = v['INVESTORFIRSTNAME'].split(" ")
                            let n = 0;
                            for (let i = 0; i < name_arr.length; i++) {
                                if (((names[n] + name_arr[i]).length + 1) <= 35) {
                                    names[n] += (name_arr[i] + " ")
                                } else {
                                    names[n+1] = (name_arr[i] + " ")
                                    n = n+1
                                }
                            }
                            v['INVESTORFIRSTNAME'] = names[0];
                            v['INVESTORMIDDLENAME'] = names[1];
                            v['INVESTORLASTNAME'] = names[2];
                        }

                        if (v['INVESTORADDRESS1'].length > 65) {
                            // let n_stats = [false, false, false];
                            let names = ["", "", ""];
                            let name_arr = v['INVESTORADDRESS1'].split(" ");
                            let n = 0;
                            for (let i = 0; i < name_arr.length; i++) {
                                if (((names[n] + name_arr[i]).length + 1) <= 65) {
                                    names[n] += (name_arr[i] + " ")
                                } else {
                                    // n_stats[n] = true
                                    names[n+1] = (name_arr[i] + " ")
                                    n = n+1
                                }
                            }
                            v['INVESTORADDRESS1'] = names[0];
                            v['INVESTORADDRESS2'] = names[1];
                            v['INVESTORADDRESS3'] = names[2];
                        }

                        if (v['INVESTOROTHERADDRESS1'].length > 65) {
                            let names = ["", "", ""];
                            let name_arr = v['INVESTOROTHERADDRESS1'].split(" ")
                            let n = 0;
                            for (let i = 0; i < name_arr.length; i++) {
                                if (((names[n] + name_arr[i]).length + 1) <= 65) {
                                    names[n] += (name_arr[i] + " ")
                                } else {
                                    names[n+1] = (name_arr[i] + " ")
                                    n = n+1
                                }
                            }
                            v['INVESTOROTHERADDRESS1'] = names[0];
                            v['INVESTOROTHERADDRESS2'] = names[1];
                            v['INVESTOROTHERADDRESS3'] = names[2];
                        }

                        data += (Object.keys(CBestMap).map(k => {
                            let c = ((v[k] == null) ? "" : v[k])
                            // if ((c != "") & Phones.includes(k)) {
                            //     c = "+62-" + (c.replace("+62-", "0").replace("+62", "0").substring(1, c.replace("+62-", "0").replace("+62", "0").length))
                            // } else 

                            if (ToBeCleaned.includes(k) && (v[k.substring(0, k.length-4)] != "1")) {
                                c = ""
                            }
                            return ((NumFields.includes(k) ? "=" : "") + "\""  + c + "\"")
                        }).join(",") + "\n")
                    });

                    var link = document.createElement('a');
                    link.style.display = 'none';
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
                    link.setAttribute('download', "STATIC_MODIFICATION_" + moment(new Date()).format("YYYYMMDD") +'.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.status == 400 ? res.data.message
                        : "Terjadi kesalahan pada sistem, silakan coba kembali");
                }
            }
        ).catch(
            res => {
                setLoadingDownloadCbest(false)
                setLoadingButton(false)
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                if (res.response == null || ![400, 405].includes(res.response?.status)) {
                    setModalDescMessage("Error tidak diketahui");
                } else if (res.response.status === 400) {
                    setModalDescMessage(LANG().LOG_NOT_FOUND);
                } else if (res.response.status == 405) {
                    setModalDescMessage("Metode permintaan tidak didukung untuk URL ini.");
                } else {
                    setModalDescMessage("" + res.response.data.message);
                }
            });
    }

    const StatFilterNode = (props: StatFilterProps) => (
        <>
            {React.createElement('input',{
                type: 'radio',
                name: 'stat_filter',
                checked: (props.cState == props.ownState),
                onClick: () => { if (props.cState != props.ownState) {
                    setCheckedYp([]);
                    props.onSelect();
                } }
            })}
            <div style={{width: '5px'}} />
            <span>{props.ownState == "" ? "Semua"
                : cdToStatus(props.ownState)
            }</span>
            <div style={{width: '10px'}} />
        </>
    )

    const StatFilter = () => {
        return (<div style={{
            display: 'flex', flexDirection: 'row',
            verticalAlign: 'middle', padding: '10px', paddingLeft: '0' }} >
            <span>Status: </span>
            <div style={{width: '10px'}} />

            <StatFilterNode cState={statFilter} ownState={""} onSelect={() => {setStatFilter("")}} />
            <StatFilterNode cState={statFilter} ownState={"v"} onSelect={() => {setStatFilter("v")}} />
            <StatFilterNode cState={statFilter} ownState={"p"} onSelect={() => {setStatFilter("p")}} />
            <StatFilterNode cState={statFilter} ownState={"s"} onSelect={() => {setStatFilter("s")}} />
            <StatFilterNode cState={statFilter} ownState={"r"} onSelect={() => {setStatFilter("r")}} />
        </div>);
    }

    const OrangeButton = (props: ButtonProps) => {
        return (
            <Button disabled={props.disabled} onClick={props.onClick} type="submit" className="py-2 h2 text-white font-weight-bold rounded"
                    style={
                        {
                            backgroundColor: '#F37F26', // #D0342C
                            width: (props.width != null)?props.width:'100%',
                        }
                    }>
                {(props.disabled)? <Spinner color='#fff' size="sm" style={{marginRight: "8px"}}/> : <></>}
                {props.text}
            </Button>
        )
    }

    const filteringData = (v: Object) => {
        let val = v[filterKey];
        return (
            (val ? val.toLowerCase().includes(getSearchKeyword.toLowerCase())
                : (getSearchKeyword == ""))
            && (statFilter == "" ? true
                : statFilter == v['status'])
        );
    }

    const putNote = () => {

        setOpenModalAdminNote(false);

        if(adminNoteData["yp"]?.length != 6){
            setModalIllustrationStatus(false);
            setOpenModal(true);
            setModalTitleMessage(LANG().FAILED);
            setModalDescMessage("YP ID tidak valid");
            return
        }

        setLoadingApprove(true)

        Axios.post("/services/customerservice/api/v1/bio/admin/account/note",
            adminNoteData,
            {headers:{"Content-Type": "application/json"}}).then(
            res => {
                setLoadingApprove(false)
                if(res.status == 200) {
                    setOpenModalConfToApprove({type: "", isOpen: false});
                    setAdminNoteData({});
                    showTable(isShowPendingTable);
                } else {
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.status == 400 ? res.data.message
                        : "Terjadi kesalahan pada sistem, silakan coba kembali");
                }
            }
        ).catch(
            res => {
                setLoadingApprove(false);
                setOpenModalConfToApprove({type: "", isOpen: false});
                showTable(isShowPendingTable);
                setLoadingButton(false)
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                if (res.response.status === 400) {
                    setModalDescMessage(LANG().LOG_NOT_FOUND);
                } else if (res.response.status == 405) {
                    setModalDescMessage("Metode permintaan tidak didukung untuk URL ini.");
                } else {
                    setModalDescMessage("" + res.response.data.message);
                }
            });
    }

    const mainPage = () => (
        <>
            <div className="page-wrapper col-md-12 col-lg-12">

                <div style={{marginLeft:"auto", marginRight:"auto"}} className="row text-center">

                    <Modal style={{top:"25%"}} isOpen={isOpenModal}>
                        <ModalBody>
                            <div className="text-center mb-4">{getModalIllustrationStatus? <SuccessIllustration/> : <FailedIllustration/>}</div>
                            <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{getModalTitleMessage}</p>
                            <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{getModalDescMessage}</p>
                            <div className="d-flex justify-content-center" onClick={ () => {
                                setOpenModal(false);
                                setModalTitleMessage("");
                                setModalDescMessage("");
                            }}>
                                <DarkPurpleButton text='OK' width="20%"/>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal style={{top:"25%"}} isOpen={isOpenModalConfToApprove?.["isOpen"]}>
                        <ModalBody>
                            <div className="text-center mb-4">{getModalIllustrationStatus? <SuccessIllustration/> : <FailedIllustration/>}</div>
                            <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{isOpenModalConfToApprove?.["type"] == "KYC-REJECT" ? "Rejection" : "Approval"}</p>
                            <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{"Apakah anda yakin untuk mengubah status pengkinian nasabah-nasabah berikut ke "}<b><i>{"\"" + isOpenModalConfToApprove?.["type"] + "\""}</i></b></p>
                            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}} ><div style={{display: 'inline-block'}} >
                                {getTableData?.filter(v => (checkedYp.includes(v['ypId']) && !((isOpenModalConfToApprove?.["type"]?.substring(0, 3) == "KYC") && (v['status'] != 'v')) && !((isOpenModalConfToApprove?.["type"]?.substring(0, 4) == "KSEI") && (v['status'] != 'p')) )).map(v => (<div><b>{v['ypId']}</b><b>{' - ' + v['name']}</b></div>))}
                            </div></div>

                            {(isOpenModalConfToApprove?.["type"] == "KYC-REJECT") && <>
                                <div style={{display: 'flex', marginBottom: '10px', marginLeft: '3px', justifyContent: 'center'}} >
                                    {React.createElement('input',{
                                        type: 'checkbox',
                                        checked: isKtpErr,
                                        onClick: () => { setIsKtpErr(!isKtpErr); }
                                    })} <span style={{marginLeft: '5px'}} >Minta user reupload KTP</span>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '20px'}} >
                                    <textarea rows={4} cols={28} maxLength={5000} value={rejectNotes != null ? rejectNotes : "" } onChange={e => {
                                        setRejectNotes(e.target.value);
                                    }} placeholder="Masukan poin-poin data yang ditolak, pisahkan tiap poin dengan tanda koma (,)" />
                                </div>
                            </>}

                            <div className="d-flex justify-content-center">
                                <div onClick={ () => {
                                    setOpenModalConfToApprove({type: "", isOpen: false});
                                }}>
                                    <OrangeButton text='Cancel' width={'80px'} />
                                </div>
                                <div style={{width: '10px'}} />
                                <div>
                                    <DarkPurpleButton text='OK' width={'80px'} onClick={() => approveAccount(isOpenModalConfToApprove?.["type"], (rejectNotes != null ? rejectNotes : ""))} disabled={loadingApprove}/>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal style={{top:"5%", bottom: "5%"}} size="lg" isOpen={isOpenModalDataPreview}>
                        <ModalBody>
                            {/*<p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{"Data preview"}</p>
                        <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{"Nama nasabah"}</p>*/}
                            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                {toPreview["isVerified"] && <div className="d-flex" style={{marginRight: '15px', color: 'blue'}} onClick={ () => {

                                }}>
                                    <a href={"/services/customerservice/api/v1/bio/admin/account/pdf?ypId="+ toPreview["ypId"]} target="_blank">{'Export'}</a>
                                </div>}
                                <div style={{marginRight:"20px"}} className="d-flex" onClick={ () => {
                                    setOpenModalDataPreview(false);
                                }}>
                                    <DarkPurpleButton text='X'/>
                                </div>
                                {/*<p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{"Nama nasabah"}</p>*/}
                            </div>
                            {/*TODO send parameter string json from audit trail data*/}
                            <LogAccUpdatePrev uid={toPreview["uid"]} isVerified={toPreview["isVerified"]}/>
                        </ModalBody>
                    </Modal>

                    <Modal style={{top:"5%", bottom: "5%"}} isOpen={isOpenModalAdminNote}>
                        <ModalBody>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} >

                                <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{'Admin Notes'}</p>
                                <p style={{fontSize:"14px"}} className="text-center mb-2 font-m-500">{'YP ID: ' + (adminNoteData["yp"] != null ? adminNoteData["yp"] : "")}</p>
                                <textarea rows={2} cols={28} maxLength={300} value={adminNoteData["note"] != null ? adminNoteData["note"] : "" } onChange={e => {
                                    setAdminNoteData({...adminNoteData, "note": e.target.value});
                                }} placeholder="Catatan dari admin..." />

                                <div className="d-flex justify-content-center" style={{marginTop: '15px'}} >
                                    <div onClick={ () => {
                                        setOpenModalAdminNote(false);
                                    }}>
                                        <OrangeButton text='Cancel' width={'80px'} />
                                    </div>
                                    <div style={{width: '10px'}} />
                                    <div>
                                        <DarkPurpleButton text='OK' width={'80px'} onClick={putNote} />
                                    </div>
                                </div>

                            </div>

                        </ModalBody>
                    </Modal>
                    <LogAuditTrailPreviewV2 isOpenModalAuditTrail={isOpenModalAuditTrail} data={getTableData?.filter( it => it["ypId"] == toPreview["ypId"] )} ypid={toPreview["ypId"]} setOpenState={setOpenModalAuditTrail} />

                    <div className="d-flex justify-content-left" onClick={props.onBack}>
                        <div className="nav-title media-body d-inline-flex">
                            <Link to={{
                                pathname: "/"
                            }}>
                                <div style={{marginRight:12}} className="d-flex align-items-center">
                                    <NavBackIcon/>
                                </div>
                            </Link>
                            <div className="font-m-500 text-black">Log Pengkinian Data</div>
                        </div>
                    </div>

                    {/*content parent div*/}
                    <div style={{display: 'flex', flexDirection: 'column'}} >

                        {/*filters parent div*/}
                        <div style={{ display: 'flex', flexDirection: 'row'}} >

                            {/*date-picking parent div*/}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} >
                                <label className="p-0">Pilih Rentang Tanggal</label>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <DatePicker
                                            selected={startDate} onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate} endDate={endDate}
                                            disabled={isShowPendingTable}
                                        />
                                        <DatePicker
                                            selected={endDate} onChange={(date) => setEndDate(date)}
                                            selectsEnd startDate={startDate}
                                            endDate={endDate} minDate={startDate}
                                            disabled={isShowPendingTable}
                                        />
                                    </div>

                                    <div style={{marginLeft: '10px'}} >
                                        <DarkPurpleButton width={"115px"} height={"30px"} text={"Tampilkan"} disabled={loadingButton} onClick={() => { showTable(isShowPendingTable); }}/>
                                    </div>

                                </div>

                                <div style={{display: 'flex', marginTop: '10px', marginLeft: '3px'}} >
                                    {React.createElement('input',{
                                        type: 'checkbox',
                                        checked: isShowPendingTable,
                                        onClick: () => { setShowPendingTable(!isShowPendingTable); setTableData(null); setTableOffline(null); setCheckedYp([]); setCheckedYpOff([]); }
                                    })} <span style={{marginLeft: '5px'}} >Tampilkan semua user pending</span>
                                </div>

                            </div>

                            {((getTableData && getTableData.length > 0) || (getTableOffline && getTableOffline.length > 0)) && 
                            <div style={{background: 'lightGray', display: 'flex', padding: '10px 26px', borderRadius: '5px', marginLeft: '26px'}} >
                                {/*filter by text div*/}
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '10px' }} >
                                    <label className="p-0">Filter berdasarkan</label>

                                    <div style={{display: 'flex', flexDirection: 'row'}} >
                                        <select
                                            id="filter_key_selector"
                                            onChange={e => {
                                                if (checkedYp.length > 0) { setCheckedYp([]); }
                                                // if (searchKeyword != "") { setSearchKeyword(""); }
                                                setFilterKey(e.target?.value);
                                            }}
                                        >
                                            { (getTableData != null) && (Object.keys(getTableData[0]).filter(v => !["status", "timestamp"].includes(v)).map(v =>
                                                <option style={{textAlign: 'left'}} value={v} >{keyToTitle(v)}</option>
                                            ))}
                                        </select>
                                        <div style={{width: '10px'}} />
                                        {(filterKey == "acntType")
                                            ? <select
                                                id="acnt_type_filter_selector"
                                                onChange={e => {
                                                    if (checkedYp.length > 0) { setCheckedYp([]); }
                                                    setSearchKeyword(e.target.value);
                                                }}
                                            >
                                                <option style={{textAlign: 'left'}} value={""} >{"all"}</option>
                                                { ["1", "2", "3", "4"].map(v =>
                                                    <option style={{textAlign: 'left'}} value={v} >{cdToAccType(v)}</option>
                                                )}
                                            </select>

                                            : (filterKey == "hotsStatus")
                                                ? <select
                                                    id="hots_status_filter_selector"
                                                    onChange={e => {
                                                        if (checkedYp.length > 0) { setCheckedYp([]); }
                                                        setSearchKeyword(e.target.value);
                                                    }}
                                                >
                                                    <option style={{textAlign: 'left'}} value={""} >{"all"}</option>
                                                    { Object.keys(HotsStatMap).map(v =>
                                                        <option style={{textAlign: 'left'}} value={v} >{HotsStatMap[v]}</option>
                                                    )}
                                                </select>

                                                : <input type="text" onChange={e => {
                                                    if (checkedYp.length > 0) { setCheckedYp([]); }
                                                    setSearchKeyword(e.target.value);
                                                }} />}
                                    </div>

                                    <StatFilter/>
                                    {/*<DarkPurpleButton width={"250px"} text={"Filter"} disabled={false} onClick={() => {}}/>*/}

                                    <span style={{margin: '2px 0'}} >Generate form</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{marginRight: '5px'}} >{'YP ID: '}</span>
                                        <input type="text" onChange={e => {
                                            setYpForExport(e.target.value);
                                        }} />
                                        <div style={{
                                            padding: '5px 10px', marginLeft: '10px', backgroundColor: '#0F2D3C', color: 'white', borderRadius: '3px', cursor: 'pointer'
                                        }}>
                                            <a  
                                                style={{color: 'white'}}
                                                href={"/services/customerservice/api/v1/bio/admin/account/pdf?ypId="+ ypForExport} 
                                                target="_blank"
                                            >{'Export'}</a>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/*Actions menu*/}
                                <table style={{marginLeft: '26px', fontSize: '10px', textAlign: 'left'}} >
                                    <tr>
                                        <td>
                                            {'To-approve'}
                                        </td>
                                        <td>
                                            <div style={{display: 'flex'}} >
                                                {((getTableData != null) && (getTableData?.filter(v => (checkedYp.includes(v['ypId']) && (v['status'] == 'v'))).length > 0)) && 
                                                    <div style={{
                                                        padding: '5px 10px', marginLeft: '10px', backgroundColor: loadingApprove ? 'gray' : '#0F2D3C', color: 'white', borderRadius: '3px', cursor: 'pointer'
                                                    }} onClick={() => {
                                                        setRejectNotes("");
                                                        setOpenModalConfToApprove({type: "KYC-APPROVE", isOpen: true});
                                                    }}>{'kyc (' + (getTableData == null ? "0" : getTableData?.filter(v => (checkedYp.includes(v['ypId']) && (v['status'] == 'v'))).length.toString()) + ')'}</div>
                                                }
                                                {((getTableData != null) && (getTableData?.filter(v => (checkedYp.includes(v['ypId']) && (v['status'] == 'p'))).length > 0)) && 
                                                    <div style={{
                                                        padding: '5px 10px', marginLeft: '10px', backgroundColor: loadingApprove ? 'gray' : '#0F2D3C', color: 'white', borderRadius: '3px', cursor: 'pointer'
                                                    }} onClick={() => {
                                                        setRejectNotes("");
                                                        setOpenModalConfToApprove({type: "KSEI-APPROVE", isOpen: true});
                                                    }}>{'ksei (' + (getTableData == null ? "0" : getTableData?.filter(v => (checkedYp.includes(v['ypId']) && (v['status'] == 'p'))).length.toString()) + ')'}</div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {'C-BEST (' + (checkedYp?.length + checkedYpOff?.length)?.toString() + ')'}
                                        </td>
                                        <td>
                                            {((checkedYp.length + checkedYpOff.length) > 0) && 
                                                <div style={{
                                                    padding: '5px 10px', marginLeft: '10px', backgroundColor: loadingDownloadCbest ? 'gray' : '#0F2D3C', color: 'white', borderRadius: '3px', cursor: 'pointer'
                                                }} onClick={() => {
                                                    downloadCsv();
                                                }}>{'Download'}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <span>{(isDownloadAllData ? 'All' : 'Shown') + ' logs'}</span>
                                                <div style={{display: 'flex', marginLeft: '3px'}} >
                                                    {React.createElement('input',{
                                                        type: 'checkbox',
                                                        checked: isDownloadAllData,
                                                        onClick: () => { setDownloadAllData(!isDownloadAllData); }
                                                    })} <span style={{marginLeft: '5px'}} >All data</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            {((getTableData && getTableData.length > 0) || (getTableOffline && getTableOffline.length > 0) || isDownloadAllData) &&
                                                <div style={{
                                                    padding: '5px 10px', marginLeft: '10px', backgroundColor: loadingDownloadRec ? 'gray' : '#0F2D3C', color: 'white', borderRadius: '3px', cursor: 'pointer'
                                                }} onClick={() => {
                                                    setLoadingDownloadRec(true);
                                                    if (isDownloadAllData) {
                                                        download_all_data();
                                                    } else {
                                                        download_shown_table();
                                                    }
                                                }}>{'Download'}</div>
                                            }
                                        </td>
                                    </tr>
                                </table>
                            
                            </div>}

                        </div>

                        <div style={{height: '20px'}} />

                        { (getTableData != null) && <Row>
                            <p style={{textAlign: 'left'}} ><i>{
                                isShowPendingTable ? "Data tertampil untuk user pending KSEI approval pada semua waktu" : ("Data tertampil untuk tanggal "
                                    + moment(startDate).add(-1, 'days').format("DD-MM-YYYY") + " 15.00 sampai "
                                    + moment(endDate).format("DD-MM-YYYY") + " 15.00")
                            }</i></p>
                            
                            {/*Paginator online*/}
                            <div style={{background: '#0F2D3C', color: 'white', display: 'flex', borderRadius: '5px', padding: '10px 15px', marginBottom: '10px'}} >
                                <div style={{display: 'flex'}} >
                                    <div style={{marginRight: '3px'}} >{'Row per page: '}</div>
                                    <select
                                        id="page-size-on"
                                        onChange={e => { 
                                            setCheckedYp([]);
                                            setPageNumSize( { pageNum: 1, pageSize: (parseInt(e.target.value) || 20) } ); 
                                        } }
                                        value={pageNumSize["pageSize"]}
                                    >
                                        {
                                            [1, 10, 20, 50, 100, 500, 1000, 10000, 200000].map(v => <option value={v}>{v.toString()}</option>)
                                        }
                                    </select>
                                </div>
                                <div style={{display: 'flex', marginLeft: '10px'}} >
                                    <div style={{marginRight: '3px'}} >{'Page: '}</div>
                                    <select
                                        id="page-num-on"
                                        onChange={e => { 
                                            setCheckedYp([]);
                                            setPageNumSize( { ...pageNumSize , pageNum: (parseInt(e.target.value) || 1) } ); 
                                        }}
                                        value={pageNumSize["pageNum"]}
                                    >
                                        {
                                            Array.from({length: Math.ceil(dataCount/pageNumSize["pageSize"])},(v,k)=> <option value={k+1}>{(k+1).toString()}</option> )
                                        }
                                    </select>
                                </div>
                                <div style={{display: 'flex', marginLeft: '10px'}} >
                                    {'Total data num: ' + dataCount?.toString()}
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped table-centered table-nowrap mb-0" id="shown-table" style={{overflow: "scroll"}} >
                                    <tbody>
                                    <tr>
                                        <td>
                                            {React.createElement('input',{
                                                type: 'checkbox',
                                                checked: ((checkedYp.length != 0)
                                                    && (checkedYp.length == getTableData?.filter(filteringData).length)),
                                                onClick: () => {
                                                        setCheckedYp([]);
                                                    if (checkedYp.length == getTableData?.length) {
                                                    } else {
                                                        setCheckedYp(getTableData?.filter(filteringData).map(v => v['ypId']));
                                                    }
                                                }
                                            })}
                                            &nbsp;
                                        </td>
                                        {Object.keys(getTableData[0]).map(v => {
                                            if((v != 'approvedDate' && v != 'approvedBy' && v != 'rejectedBy' && v != 'rejectedDate' && v != 'lastUpdatedDate' && v != 'lastUpdatedBy' && v != 'createdDate' && v != 'createdBy' && v != 'approvalHistories' )) {
                                                return (<th style={{textAlign: 'left'}}>{keyToTitle(v)}</th>)
                                            }
                                          })
                                        }
                                        <th style={{textAlign: 'left'}} >{"Preview"}</th>
                                        <th colSpan={2} style={{textAlign: 'left'}} >{"KYC Approval"}</th>
                                        <th style={{textAlign: 'left'}} >{"KSEI Approval"}</th>
                                    </tr>
                                    { getTableData
                                        .filter(filteringData)
                                        .map(v =>
                                            <tr>
                                                <td style={v['status'] == 'r' ? {color: 'red'} : {}}>
                                                    {React.createElement('input',{
                                                        type: 'checkbox',
                                                        checked: checkedYp.includes(v['ypId']),
                                                        onClick: () => {
                                                            if (checkedYp.includes(v['ypId'])) {
                                                                setCheckedYp(checkedYp.filter(yps =>  (yps != v['ypId'])));
                                                            } else {
                                                                setCheckedYp([...checkedYp, v['ypId']]);
                                                            }
                                                        }
                                                    })}
                                                    &nbsp;
                                                </td>
                                                { Object.keys(v).map(k => {

                                                    if((k != 'approvedDate' && k != 'approvedBy' && k != 'rejectedBy' && k != 'rejectedDate' && k != 'lastUpdatedDate' && k != 'lastUpdatedBy' && k != 'createdDate' && k != 'createdBy' && k != 'approvalHistories')) {
                                                        return <td style={{
                                                            textAlign: ((k == "rejectCount") ? 'center' : 'left'),
                                                            color: ((v['status'] == 'r') ? 'red' : 'black'),
                                                            whiteSpace: (k == "adminNotes") ? 'break-spaces' : 'nowrap',
                                                            width: (k == "adminNotes") ? '300px' : 'auto',
                                                        }}>{
                                                            (v[k] == null) ? " - "
                                                                : (k == 'status') ? cdToStatus(v[k])
                                                                    : (k == 'acntType') ? cdToAccType(v[k])
                                                                        : (k == 'timestamp') ? moment(toDateTime(v[k]["epochSecond"])).format("DD-MM-YYYY HH:mm:ss")
                                                                            /*: (k == 'approvedDate') ?  moment(toDateTime(v[k]["epochSecond"])).format("DD-MM-YYYY HH:MM:ss")
                                                                            : (k == 'lastUpdatedDate') ?  moment(toDateTime(v[k]["epochSecond"])).format("DD-MM-YYYY HH:MM:ss")*/
                                                                            : (k == 'hotsStatus') ? HotsStatMap[v[k]]
                                                                                : (k == 'adminNotes') ? <div style={{
                                                                                    display: 'flex',
                                                                                    width: '300px',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    <div style={{
                                                                                        backgroundColor: '#0F2D3C',
                                                                                        color: 'white',
                                                                                        borderRadius: '3px',
                                                                                        padding: '2px',
                                                                                        cursor: 'pointer',
                                                                                        marginRight: '5px'
                                                                                    }} onClick={() => {
                                                                                        setAdminNoteData({
                                                                                            "yp": v["ypId"],
                                                                                            "note": v["adminNotes"]
                                                                                        })
                                                                                        setOpenModalAdminNote(true);
                                                                                    }}>{'e'}</div>
                                                                                    <span>{v[k]}</span>
                                                                                </div> : (k == 'ypId') ? <div style={{
                                                                                        display: 'flex',
                                                                                        width: '300px',
                                                                                        alignItems: 'flex-start'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            cursor: 'pointer',
                                                                                        }} onClick={() => {
                                                                                            setToPreview({
                                                                                                uid: (['p', 's'].includes(v['status']) ? v['ypId'] : v['username']),
                                                                                                ypId: v['ypId'],
                                                                                                isVerified: ['p', 's'].includes(v['status'])
                                                                                            });
                                                                                            //open audit trail modal
                                                                                            setOpenModalAuditTrail(v)
                                                                                        }}><span>{v[k]}</span></div>
                                                                                    </div>
                                                                                    : v[k]
                                                        }</td>
                                                    }
                                                })}
                                                
                                                <td style={v['status'] == 'r' ? {color: 'red'} : {}}><div style={{
                                                    backgroundColor: '#F37F26',
                                                    color: 'white',
                                                    borderRadius: '3px',
                                                    padding: '2px 10px',
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    setToPreview({
                                                        uid: (['p', 's'].includes(v['status']) ? v['ypId'] : v['username']), 
                                                        ypId: v['ypId'], 
                                                        isVerified: ['p', 's'].includes(v['status'])
                                                    });
                                                    setOpenModalDataPreview(true);
                                                }}>{'show data'}</div></td>


                                                <td style={v['status'] == 'r' ? {color: 'red'} : {}}>{(v['status'] == 'r') ? 'reject' : (v['status'] != 'v') ? 'ok' : <div style={{
                                                    backgroundColor: '#0F2D3C',
                                                    color: 'white',
                                                    borderRadius: '3px',
                                                    padding: '2px 10px',
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    setCheckedYp([v['ypId']]);
                                                    setRejectNotes("");
                                                    setIsKtpErr(false);
                                                    setOpenModalConfToApprove({type: "KYC-APPROVE", isOpen: true});
                                                }}>{'approve'}</div>}</td>

                                                <td style={v['status'] == 'r' ? {color: 'red'} : {}}>{(v['status'] != 'v') ? ' ' : <div style={{
                                                    backgroundColor: '#D0342C',
                                                    color: 'white',
                                                    borderRadius: '3px',
                                                    padding: '2px 10px',
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    setCheckedYp([v['ypId']]);
                                                    setRejectNotes("");
                                                    setIsKtpErr(false);
                                                    setOpenModalConfToApprove({type: "KYC-REJECT", isOpen: true});
                                                }}>{'reject'}</div>}</td>

                                                <td style={v['status'] == 'r' ? {color: 'red'} : {}}>{(v['status'] == 's') ? 'approved' : (v['status'] != 'p') ? <span>{'wait KYC'}</span> : <div style={{
                                                    backgroundColor: '#0F2D3C',
                                                    color: 'white',
                                                    borderRadius: '3px',
                                                    padding: '2px 10px',
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    setCheckedYp([v['ypId']]);
                                                    setRejectNotes("");
                                                    setIsKtpErr(false);
                                                    setOpenModalConfToApprove({type: "KSEI-APPROVE", isOpen: true});
                                                }}>{'approve'}</div>}</td>

                                                

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Row>}

                        { (getTableOffline != null) && <Row>
                            {/*<p style={{textAlign: 'left'}} ><i>{
                                isShowPendingTable ? "Data tertampil untuk user pending KSEI approval pada semua waktu" : ("Data tertampil untuk tanggal "
                                    + moment(startDate).add(-1, 'days').format("DD-MM-YYYY") + " 15.00 sampai "
                                    + moment(endDate).format("DD-MM-YYYY") + " 15.00")
                            }</i></p>*/}
                            <p style={{textAlign: 'left', marginTop: '25px', fontSize: '14px', fontWeight: 'bold'}}>Offline</p>
                            
                            {/*Paginator offline*/}
                            <div style={{background: '#0F2D3C', color: 'white', display: 'flex', borderRadius: '5px', padding: '10px 15px', marginBottom: '10px'}} >
                                <div style={{display: 'flex'}} >
                                    <div style={{marginRight: '3px'}} >{'Row per page: '}</div>
                                    <select
                                        id="page-size-of"
                                        onChange={e => { 
                                            setCheckedYpOff([]);
                                            setPageNumSizeOf( { pageNumOf: 1, pageSizeOf: (parseInt(e.target.value) || 20) } ); 
                                        }}
                                        value={pageNumSizeOf["pageSizeOf"]}
                                    >
                                        {
                                            [1, 10, 20, 50, 100, 500, 1000, 10000, 200000].map(v => <option value={v}>{v.toString()}</option>)
                                        }
                                    </select>
                                </div>
                                <div style={{display: 'flex', marginLeft: '10px'}} >
                                    <div style={{marginRight: '3px'}} >{'Page: '}</div>
                                    <select
                                        id="page-num-of"
                                        onChange={e => { 
                                            setCheckedYpOff([]);
                                            setPageNumSizeOf( {...pageNumSizeOf, pageNumOf: (parseInt(e.target.value) || 1)} ); 
                                        }}
                                        value={pageNumSizeOf["pageNumOf"]}
                                    >
                                        {
                                            Array.from({length: Math.ceil(dataCountOf/pageNumSizeOf["pageSizeOf"])},(v,k)=> <option value={k+1}>{(k+1).toString()}</option> )
                                        }
                                    </select>
                                </div>
                                <div style={{display: 'flex', marginLeft: '10px'}} >
                                    {'Total data num: ' + dataCountOf?.toString()}
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped table-centered table-nowrap mb-0" id="shown-table-offline" style={{overflow: "scroll"}} >
                                    <tbody>
                                    <tr>
                                        <td>
                                            {(statFilter != "s") && React.createElement('input',{
                                                type: 'checkbox',
                                                checked: ((checkedYpOff.length != 0)
                                                    && (checkedYpOff.length == getTableOffline?.filter(filteringData).length)),
                                                onClick: () => {
                                                    if (checkedYpOff.length == getTableOffline?.length) {
                                                        setCheckedYpOff([]);
                                                    } else {
                                                        setCheckedYpOff(getTableOffline?.filter(filteringData).map(v => v['ypId']));
                                                    }
                                                }
                                            })}
                                            &nbsp;
                                        </td>
                                        { Object.keys(getTableOffline[0]).map(v =>
                                            <th style={{textAlign: 'left'}} >{keyToTitle(v)}</th>
                                        )}
                                        <th style={{textAlign: 'left'}} >{"Preview"}</th>
                                    </tr>
                                    { getTableOffline
                                        .filter(filteringData)
                                        .map(v =>
                                            <tr>
                                                <td>
                                                    {React.createElement('input',{
                                                        type: 'checkbox',
                                                        checked: checkedYpOff.includes(v['ypId']),
                                                        onClick: () => {
                                                            if (checkedYpOff.includes(v['ypId'])) {
                                                                setCheckedYpOff(checkedYpOff.filter(yps =>  (yps != v['ypId'])));
                                                            } else {
                                                                setCheckedYpOff([...checkedYpOff, v['ypId']]);
                                                            }
                                                        }
                                                    })}
                                                    &nbsp;
                                                </td>
                                                { Object.keys(v).map(k =>
                                                    <td style={{textAlign: 'left'}} >{
                                                        (v[k] == null) ? " - "
                                                            : (k == 'status') ? "approved"
                                                                : (k == 'timestamp') ?  moment(toDateTime(v[k]["epochSecond"])).format("DD-MM-YYYY HH:mm:ss")
                                                                    : (k == 'acntType') ? cdToAccType(v[k])
                                                                        : (k == 'hotsStatus') ? HotsStatMap[v[k]]
                                                                            : v[k]
                                                    }</td>
                                                )}

                                                <td><div style={{
                                                    backgroundColor: '#0F2D3C',
                                                    color: 'white',
                                                    borderRadius: '3px',
                                                    padding: '2px',
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    setToPreview({uid: v['ypId'], ypId: v['ypId'], isVerified: true});
                                                    setOpenModalDataPreview(true);
                                                }}>{'show data'}</div></td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Row>}
                    </div>

                </div>
            </div>
        </>
    );

    return mainPage();
}

export default LogAccUpdate;